define("ilios-common/mixins/sortable-objective-list", ["exports", "ilios-common/mixins/sortable-by-position"], function (_exports, _sortableByPosition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    alias
  } = Ember.computed;
  const {
    all
  } = Ember.RSVP;

  var _default = Ember.Mixin.create(_sortableByPosition.default, {
    subject: null,
    totalObjectivesToSave: null,
    currentObjectivesSaved: null,
    isSorting: false,
    isSaving: false,
    objectives: alias('subject.sortedObjectives'),
    hasMoreThanOneObjective: Ember.computed('objectives.[]', async function () {
      const objectives = await this.get('objectives');
      return objectives.length > 1;
    }),

    saveSomeObjectives(arr) {
      let chunk = arr.splice(0, 5);
      return all(chunk.invoke('save')).then(() => {
        if (arr.length) {
          this.set('currentObjectivesSaved', this.get('currentObjectivesSaved') + chunk.length);
          return this.saveSomeObjectives(arr);
        }
      });
    },

    actions: {
      saveSortOrder(objectives) {
        this.set('isSaving', true);

        for (let i = 0, n = objectives.length; i < n; i++) {
          let lm = objectives[i];
          lm.set('position', i + 1);
        }

        this.set('totalObjectivesToSave', objectives.length);
        this.set('currentObjectivesSaved', 0);
        this.saveSomeObjectives(objectives).then(() => {
          this.set('isSaving', false);
          this.set('isSorting', false);
        });
      },

      cancelSorting() {
        this.set('isSorting', false);
      }

    }
  });

  _exports.default = _default;
});