define("ilios-common/mixins/course-visualize-instructor-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    titleToken: 'general.coursesAndSessions',

    async model(params) {
      const store = this.get('store');
      const course = await store.find('course', params.course_id);
      const user = await store.find('user', params.user_id);
      const sessions = await course.get('sessions');
      const sessionsWithUser = await Ember.RSVP.filter(sessions.toArray(), async session => {
        const instructors = await session.get('allInstructors');
        return instructors.mapBy('id').includes(user.get('id'));
      });
      const minutes = await Ember.RSVP.map(sessionsWithUser, async session => {
        const offeringHours = await session.get('maxSingleOfferingDuration');
        const ilmSession = await session.get('ilmSession');
        const offeringMinutes = Math.round(offeringHours * 60);
        let ilmMinutes = 0;

        if (ilmSession) {
          ilmMinutes = Math.round(parseFloat(ilmSession.get('hours')) * 60);
        }

        return {
          offeringMinutes,
          ilmMinutes
        };
      });
      const offeringMinutes = minutes.mapBy('offeringMinutes').reduce((total, mins) => total + mins, 0);
      const ilmMinutes = minutes.mapBy('ilmMinutes').reduce((total, mins) => total + mins, 0);
      return {
        course,
        user,
        offeringMinutes,
        ilmMinutes
      };
    },

    async afterModel({
      course
    }) {
      const sessions = await course.get('sessions');
      return await Ember.RSVP.all([course.get('school'), Ember.RSVP.map(sessions.toArray(), s => s.get('sessionType')), Ember.RSVP.map(sessions.toArray(), s => s.get('allInstructors')), Ember.RSVP.map(sessions.toArray(), s => s.get('totalSumDuration'))]);
    }

  });

  _exports.default = _default;
});