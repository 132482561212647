define("ilios-common/components/loading-part", ["exports", "ilios-common/templates/components/loading-part"], function (_exports, _loadingPart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _loadingPart.default
  });

  _exports.default = _default;
});