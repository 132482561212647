define("ilios-common/mixins/course-visualize-vocabulary-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    titleToken: 'general.coursesAndSessions',

    async model(params) {
      const store = this.get('store');
      const course = await store.find('course', params.course_id);
      const vocabulary = await store.find('vocabulary', params.vocabulary_id);
      return {
        course,
        vocabulary
      };
    },

    async afterModel(model) {
      const {
        course,
        vocabulary
      } = model;
      const sessions = await course.get('sessions');
      return await Ember.RSVP.all([course.get('school'), vocabulary.get('terms'), Ember.RSVP.map(sessions.toArray(), s => s.get('terms')), Ember.RSVP.map(sessions.toArray(), s => s.get('totalSumDuration'))]);
    }

  });

  _exports.default = _default;
});