define("ilios-common/components/detail-cohort-manager", ["exports", "ilios-common/templates/components/detail-cohort-manager"], function (_exports, _detailCohortManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    permissionChecker: Ember.inject.service(),
    layout: _detailCohortManager.default,
    tagName: 'section',
    classNames: ['detail-cohort-manager'],
    filter: '',
    school: null,
    selectedCohorts: null,
    allCohorts: Ember.computed('school', async function () {
      const store = this.get('store');
      const permissionChecker = this.get('permissionChecker');
      const allCohorts = await store.findAll('cohort');
      const courseSchool = this.get('school');
      return Ember.RSVP.filter(allCohorts.toArray(), async cohort => {
        const cohortSchool = await cohort.get('school');

        if (cohortSchool === courseSchool) {
          return true;
        }

        if (await permissionChecker.canUpdateAllCoursesInSchool(cohortSchool)) {
          return true;
        }

        const programYear = await cohort.get('programYear');

        if (await permissionChecker.canUpdateProgramYear(programYear)) {
          return true;
        }

        return false;
      });
    }),

    /**
     * @property availableCohorts
     * @type {Ember.computed}
     * @protected
     */
    availableCohorts: Ember.computed('allCohorts.[]', 'selectedCohorts.[]', async function () {
      const cohorts = await this.get('allCohorts');
      const selectedCohorts = this.get('selectedCohorts') || [];
      return cohorts.filter(cohort => !selectedCohorts.includes(cohort));
    }),

    /**
     * All available cohorts, sorted by:
     *
     * 1. owning school's title, ascending
     * 2. owning program's title, ascending
     * 3. cohort title, descending
     *
     * @property sortedAvailableCohorts
     * @type {Ember.computed}
     * @public
     */
    sortedAvailableCohorts: Ember.computed('availableCohorts.[]', async function () {
      const availableCohorts = await this.get('availableCohorts');
      const objects = await Ember.RSVP.map(availableCohorts, async cohort => {
        const programYear = await cohort.get('programYear');
        const program = await programYear.get('program');
        const school = await program.get('school');
        const sortTitle = school.get('title') + program.get('title');
        return {
          cohort,
          sortTitle,
          cohortTitle: cohort.get('title')
        };
      });
      objects.sort((obj1, obj2) => {
        let compare = obj1.sortTitle.localeCompare(obj2.sortTitle);

        if (compare === 0) {
          //cohort titles are sorted descending
          compare = -obj1.cohortTitle.localeCompare(obj2.cohortTitle);
        }

        return compare;
      });
      return objects.mapBy('cohort');
    }),
    actions: {
      add(cohort) {
        this.add(cohort);
      },

      remove(cohort) {
        this.remove(cohort);
      }

    }
  });

  _exports.default = _default;
});