define("ilios-common/models/session-type", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    title: attr('string'),
    calendarColor: attr('string'),
    active: attr('boolean'),
    assessment: attr('boolean'),
    assessmentOption: belongsTo('assessment-option', {
      async: true
    }),
    school: belongsTo('school', {
      async: true
    }),
    aamcMethods: hasMany('aamc-method', {
      async: true
    }),
    sessions: hasMany('session', {
      async: true
    }),
    safeCalendarColor: Ember.computed('calendarColor', function () {
      const calendarColor = this.get('calendarColor');
      const pattern = new RegExp("^#[a-fA-F0-9]{6}$");

      if (pattern.test(calendarColor)) {
        return Ember.String.htmlSafe(calendarColor);
      }

      return '';
    }),
    sessionCount: Ember.computed('sessions.[]', function () {
      const sessons = this.hasMany('sessions');
      return sessons.ids().length;
    }),
    firstAamcMethod: Ember.computed('aamcMethods.[]', async function () {
      const aamcMethods = await this.get('aamcMethods');
      return aamcMethods.get('firstObject');
    })
  });

  _exports.default = _default;
});