define("ilios-common/components/visualizer-course-instructor-session-type", ["exports", "ember-concurrency", "ilios-common/templates/components/visualizer-course-instructor-session-type"], function (_exports, _emberConcurrency, _visualizerCourseInstructorSessionType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    layout: _visualizerCourseInstructorSessionType.default,
    course: null,
    user: null,
    isIcon: false,
    classNameBindings: ['isIcon::not-icon', ':visualizer-course-instructor-session-type'],
    tooltipContent: null,
    tooltipTitle: null,
    data: Ember.computed('course.sessions.[]', 'vocabulary', async function () {
      const course = this.get('course');
      const user = this.get('user');
      const sessions = await course.get('sessions');
      const sessionsWithUser = await Ember.RSVP.filter(sessions.toArray(), async session => {
        const instructors = await session.get('allInstructors');
        return instructors.mapBy('id').includes(user.get('id'));
      });
      const dataMap = await Ember.RSVP.map(sessionsWithUser, async session => {
        const sessionType = await session.get('sessionType');
        const hours = await session.get('totalSumDuration');
        const minutes = Math.round(hours * 60);
        return {
          sessionTitle: session.get('title'),
          sessionTypeTitle: sessionType.get('title'),
          minutes
        };
      });
      const sessionTypeData = dataMap.reduce((set, obj) => {
        const name = obj.sessionTypeTitle;
        let existing = set.findBy('label', name);

        if (!existing) {
          existing = {
            data: 0,
            label: name,
            meta: {
              sessions: []
            }
          };
          set.pushObject(existing);
        }

        existing.data += obj.minutes;
        existing.meta.sessions.pushObject(obj.sessionTitle);
        return set;
      }, []);
      const totalMinutes = sessionTypeData.mapBy('data').reduce((total, minutes) => total + minutes, 0);
      const mappedWithLabel = sessionTypeData.map(obj => {
        const percent = (obj.data / totalMinutes * 100).toFixed(1);
        obj.label = "".concat(obj.label, " ").concat(percent, "%");
        obj.meta.totalMinutes = totalMinutes;
        obj.meta.percent = percent;
        return obj;
      });
      return mappedWithLabel;
    }),
    donutHover: (0, _emberConcurrency.task)(function* (obj) {
      yield (0, _emberConcurrency.timeout)(100);
      const isIcon = this.get('isIcon');

      if (isIcon || Ember.isEmpty(obj) || obj.empty) {
        this.set('tooltipTitle', null);
        this.set('tooltipContent', null);
        return;
      }

      const intl = this.get('intl');
      const {
        label,
        data,
        meta
      } = obj;
      const title = Ember.String.htmlSafe("".concat(label, " ").concat(data, " ").concat(intl.t('general.minutes')));
      const sessions = meta.sessions.uniq().sort().join();
      this.set('tooltipTitle', title);
      this.set('tooltipContent', sessions);
    }).restartable()
  });

  _exports.default = _default;
});