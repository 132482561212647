define("ilios-common/components/objective-manage-descriptors", ["exports", "ilios-common/templates/components/objective-manage-descriptors"], function (_exports, _objectiveManageDescriptors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _objectiveManageDescriptors.default,
    objective: null,
    objectiveDescriptors: Ember.computed('objective', 'objective.meshDescriptors.[]', function () {
      var objective = this.get('objective');

      if (!objective) {
        return [];
      }

      return objective.get('meshDescriptors');
    }),
    actions: {
      add(descriptor) {
        var objective = this.get('objective');
        objective.get('meshDescriptors').addObject(descriptor);
      },

      remove(descriptor) {
        var objective = this.get('objective');
        objective.get('meshDescriptors').removeObject(descriptor);
      }

    }
  });

  _exports.default = _default;
});