define("ilios-common/templates/components/progress-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bHO7wmWm",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[10,\"class\",\"meter\"],[11,\"style\",[22,\"widthStyle\"]],[8],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"\\n    \"],[1,[22,\"percentage\"],false],[0,\"%\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/progress-bar.hbs"
    }
  });

  _exports.default = _default;
});