define("ilios-common/templates/components/dashboard-week", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z7NMMJkw",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"weeklylink\"],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"general.view\"],null],false],[0,\":\\n  \"],[5,\"link-to\",[],[[\"@route\",\"@query\"],[\"weeklyevents\",[28,\"hash\",null,[[\"expanded\"],[[24,[\"expanded\"]]]]]]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"t\",[\"general.allWeeks\"],null],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"],[5,\"week-glance\",[],[[\"@collapsed\",\"@collapsible\",\"@showFullTitle\",\"@week\",\"@year\"],[false,false,true,[22,\"week\"],[22,\"year\"]]]],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/dashboard-week.hbs"
    }
  });

  _exports.default = _default;
});