define("ilios-common/templates/components/toggle-yesno", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GBw/kwm4",
    "block": "{\"symbols\":[],\"statements\":[[7,\"input\",true],[11,\"checked\",[22,\"yes\"]],[10,\"class\",\"switch-input\"],[10,\"disabled\",\"disabled\"],[10,\"type\",\"checkbox\"],[8],[9],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"switch-label\"],[11,\"data-on\",[28,\"t\",[\"general.yes\"],null]],[11,\"data-off\",[28,\"t\",[\"general.no\"],null]],[8],[9],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"switch-handle\"],[8],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/toggle-yesno.hbs"
    }
  });

  _exports.default = _default;
});