define("ilios-common/templates/components/week-glance-pre-work", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cWjhMk+c",
    "block": "{\"symbols\":[\"@events\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"week-glance-pre-work\"],[8],[0,\"\\n  \"],[7,\"h4\",true],[8],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"title\"],[8],[0,\"\\n      \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"events\",[23,1,[\"firstObject\",\"slug\"]]]],{\"statements\":[[0,\"\\n        \"],[1,[23,1,[\"firstObject\",\"name\"]],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"date\"],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"general.dueBeforeLink\"],[[\"name\",\"date\",\"link\",\"htmlSafe\"],[[23,1,[\"firstObject\",\"postrequisiteName\"]],[28,\"format-date\",[[23,1,[\"firstObject\",\"startDate\"]]],null],[28,\"concat\",[\"#event\",[23,1,[\"firstObject\",\"postrequisiteSlug\"]]],null],true]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"if\",[[28,\"gt\",[[23,1,[\"length\"]],1],null]],null,{\"statements\":[[0,\"      \"],[7,\"span\",true],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"general.withXmoreOfferings\"],[[\"count\"],[[28,\"sub\",[[23,1,[\"length\"]],1],null]]]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/week-glance-pre-work.hbs"
    }
  });

  _exports.default = _default;
});