define("ilios-common/templates/components/selected-term-tree", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0T/go+U/",
    "block": "{\"symbols\":[\"term\"],\"statements\":[[4,\"each\",[[28,\"sort-by\",[\"title\",[24,[\"terms\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"li\",true],[10,\"class\",\"clickable\"],[8],[0,\"\\n    \"],[7,\"input\",false],[12,\"class\",\"checkbox\"],[12,\"checked\",[28,\"is-in\",[[24,[\"selectedTerms\"]],[23,1,[]]],null]],[12,\"type\",\"checkbox\"],[3,\"action\",[[23,0,[]],[24,[\"toggle\"]],[23,1,[]]],[[\"on\"],[\"change\"]]],[8],[9],[0,\"\\n    \"],[7,\"span\",false],[12,\"class\",\"list-indentation\"],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],[24,[\"toggle\"]],[23,1,[]]]],[8],[0,\"\\n      \"],[1,[23,1,[\"title\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"if\",[[28,\"gt\",[[28,\"get\",[[28,\"await\",[[23,1,[\"children\"]]],null],\"length\"],null],0],null]],null,{\"statements\":[[0,\"      \"],[5,\"selected-term-tree\",[],[[\"@terms\",\"@selectedTerms\",\"@toggle\"],[[28,\"await\",[[23,1,[\"children\"]]],null],[22,\"selectedTerms\"],[22,\"toggle\"]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/selected-term-tree.hbs"
    }
  });

  _exports.default = _default;
});