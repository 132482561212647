define("ilios-common/models/program", ["exports", "ember-data", "ilios-common/mixins/publishable-model"], function (_exports, _emberData, _publishableModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo,
    hasMany,
    Model
  } = _emberData.default;
  const {
    all
  } = Ember.RSVP;

  var _default = Model.extend(_publishableModel.default, {
    title: attr('string'),
    shortTitle: attr('string'),
    duration: attr('number', {
      defaultValue: 1
    }),
    school: belongsTo('school', {
      async: true
    }),
    programYears: hasMany('program-year', {
      async: true,
      inverse: 'program'
    }),
    directors: hasMany('user', {
      async: true,
      inverse: 'directedPrograms'
    }),
    curriculumInventoryReports: hasMany('curriculum-inventory-report', {
      async: true
    }),
    hasCurriculumInventoryReports: Ember.computed('curriculumInventoryReports.[]', function () {
      return this.hasMany('curriculumInventoryReports').ids().length > 0;
    }),
    hasProgramYears: Ember.computed('programYears.[]', function () {
      return this.hasMany('programYears').ids().length > 0;
    }),

    /**
     * All cohorts associated with this program via its program years.
     * @property cohorts
     * @type {Ember.computed}
     * @public
     */
    cohorts: Ember.computed('programYears.[]', async function () {
      const programYears = await this.get('programYears');
      return all(programYears.toArray().mapBy('cohort'));
    }),

    /**
     * All courses linked to this program via its program years/cohorts.
     * @property courses
     * @type {Ember.computed}
     * @public
     */
    courses: Ember.computed('cohorts.[]', async function () {
      const cohorts = await this.get('cohorts');
      const courses = await all(cohorts.mapBy('courses'));
      return courses.reduce((array, set) => {
        array.pushObjects(set.toArray());
        return array;
      }, []).uniq();
    }),
    requiredPublicationIssues: Ember.computed('title', 'shortTitle', 'duration', function () {
      return this.getRequiredPublicationIssues();
    }),
    optionalPublicationIssues: Ember.computed('programYears.length', function () {
      return this.getOptionalPublicationIssues();
    }),

    init() {
      this._super(...arguments);

      this.set('requiredPublicationSetFields', ['title', 'shortTitle', 'duration']);
      this.set('optionalPublicationLengthFields', ['programYears']);
    }

  });

  _exports.default = _default;
});