define("ilios-common/components/week-glance-event", ["exports", "ilios-common/templates/components/week-glance-event"], function (_exports, _weekGlanceEvent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _weekGlanceEvent.default,
    tagName: '',
    actions: {
      sortString(a, b) {
        return a.localeCompare(b);
      }

    }
  });

  _exports.default = _default;
});