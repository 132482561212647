define("ilios-common/models/mesh-tree", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo,
    Model
  } = _emberData.default;

  var _default = Model.extend({
    treeNumber: attr('string'),
    descriptor: belongsTo('mesh-descriptor', {
      async: true
    })
  });

  _exports.default = _default;
});