define("ilios-common/components/expand-collapse-button", ["exports", "ilios-common/templates/components/expand-collapse-button"], function (_exports, _expandCollapseButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _expandCollapseButton.default,
    classNames: ['expand-collapse-button'],
    'data-test-expand-collapse-button': true,
    value: false
  });

  _exports.default = _default;
});