define("ilios-common/mixins/categorizable-model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    hasMany
  } = _emberData.default;
  const {
    all
  } = Ember.RSVP;

  var _default = Ember.Mixin.create({
    /**
     * Associated taxonomy terms.
     * @property terms
     * @type {Ember.computed}
     * @public
     */
    terms: hasMany('term', {
      async: true
    }),

    /**
     * A list of all vocabularies that are associated via terms.
     * @property associatedVocabularies
     * @type {Ember.computed}
     * @public
     */
    associatedVocabularies: Ember.computed('terms.@each.vocabulary', async function () {
      const terms = await this.get('terms');
      const vocabularies = await all(terms.toArray().mapBy('vocabulary'));
      return vocabularies.uniq().sortBy('title');
    }),

    /**
     * A list containing all associated terms and their parent terms.
     * @property termsWithAllParents
     * @type {Ember.computed}
     * @public
     */
    termsWithAllParents: Ember.computed('terms.[]', async function () {
      const terms = await this.get('terms');
      const allTerms = await all(terms.toArray().mapBy('termWithAllParents'));
      return allTerms.reduce((array, set) => {
        array.pushObjects(set);
        return array;
      }, []).uniq();
    }),

    /**
     * The number of terms attached to this model
     * @property termCount
     * @type {Ember.computed}
     * @public
     */
    termCount: Ember.computed('terms.[]', function () {
      const termIds = this.hasMany('terms').ids();
      return termIds.length;
    })
  });

  _exports.default = _default;
});