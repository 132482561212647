define("ilios-common/components/visualizer-course-objectives", ["exports", "ember-concurrency", "ilios-common/templates/components/visualizer-course-objectives"], function (_exports, _emberConcurrency, _visualizerCourseObjectives) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    map,
    filter
  } = Ember.RSVP;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    layout: _visualizerCourseObjectives.default,
    course: null,
    isIcon: false,
    classNameBindings: ['isIcon::not-icon', ':visualizer-course-objectives'],
    tagName: 'div',
    tooltipContent: null,
    tooltipTitle: null,
    objectiveData: Ember.computed('course.sessions.[]', 'course.objectives.[]', async function () {
      const course = this.get('course');
      const sessions = await course.get('sessions');
      const sessionCourseObjectiveMap = await map(sessions.toArray(), async session => {
        const hours = await session.get('totalSumDuration');
        const minutes = Math.round(hours * 60);
        const sessionObjectives = await session.get('objectives');
        const sessionObjectivesWithParents = await filter(sessionObjectives.toArray(), async sessionObjective => {
          const parents = await sessionObjective.get('parents');
          return Ember.isPresent(parents);
        });
        const courseSessionObjectives = await map(sessionObjectivesWithParents.toArray(), async sessionObjective => {
          const parents = await sessionObjective.get('parents');
          return parents.mapBy('id');
        });
        let flatObjectives = courseSessionObjectives.reduce((flattened, obj) => {
          return flattened.pushObjects(obj.toArray());
        }, []);
        return {
          sessionTitle: session.get('title'),
          objectives: flatObjectives,
          minutes
        };
      });
      return sessionCourseObjectiveMap;
    }),
    condensedObjectiveData: Ember.computed('objectiveData.[]', async function () {
      const course = this.get('course');
      const sessionCourseObjectiveMap = await this.get('objectiveData');
      const courseObjectives = await course.get('objectives');
      let mappedObjectives = courseObjectives.map(courseObjective => {
        const minutes = sessionCourseObjectiveMap.map(obj => {
          if (obj.objectives.includes(courseObjective.get('id'))) {
            return obj.minutes;
          } else {
            return 0;
          }
        });
        const sessionObjectives = sessionCourseObjectiveMap.filter(obj => obj.objectives.includes(courseObjective.get('id')));
        const meta = {
          courseObjective,
          sessionObjectives
        };
        const data = minutes.reduce((accumulator, current) => accumulator + parseInt(current, 10), 0);
        return {
          data,
          meta
        };
      });
      const totalMinutes = mappedObjectives.mapBy('data').reduce((total, minutes) => total + minutes, 0);
      const mappedObjectivesWithLabel = mappedObjectives.map(obj => {
        const percent = (obj.data / totalMinutes * 100).toFixed(1);
        obj.label = "".concat(percent, "%");
        return obj;
      });
      return mappedObjectivesWithLabel;
    }),
    objectiveWithMinutes: Ember.computed('condensedObjectiveData.[]', async function () {
      const condensedObjectiveData = await this.get('condensedObjectiveData');
      const objectiveWithMinutes = condensedObjectiveData.filter(obj => obj.data !== 0);
      return objectiveWithMinutes;
    }),
    objectiveWithoutMinutes: Ember.computed('condensedObjectiveData.[]', async function () {
      const condensedObjectiveData = await this.get('condensedObjectiveData');
      const objectiveWithoutMinutes = condensedObjectiveData.filterBy('data', 0);
      return objectiveWithoutMinutes;
    }),

    async getTooltipData(obj) {
      const intl = this.get('intl');
      const isIcon = this.get('isIcon');

      if (isIcon || Ember.isEmpty(obj) || obj.empty) {
        return '';
      }

      const {
        data,
        meta
      } = obj;
      let objectiveTitle = meta.courseObjective.get('title');
      let competency = await meta.courseObjective.get('competency');

      if (competency) {
        objectiveTitle += "(".concat(competency, ")");
      }

      const title = Ember.String.htmlSafe("".concat(objectiveTitle, " &bull; ").concat(data, " ").concat(intl.t('general.minutes')));
      const sessionTitles = meta.sessionObjectives.mapBy('sessionTitle');
      const content = sessionTitles.join(', ');
      return {
        content,
        title
      };
    },

    donutHover: (0, _emberConcurrency.task)(function* (obj) {
      yield (0, _emberConcurrency.timeout)(100);
      const data = yield this.getTooltipData(obj);

      if (Ember.isPresent(data)) {
        this.set('tooltipTitle', data.title);
        this.set('tooltipContent', data.content);
      }
    }).restartable()
  });

  _exports.default = _default;
});