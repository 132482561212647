define("ilios-common/templates/components/toggle-buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vUtNh8/M",
    "block": "{\"symbols\":[],\"statements\":[[7,\"input\",true],[11,\"id\",[28,\"concat\",[\"first-toggle-\",[24,[\"elementId\"]]],null]],[11,\"name\",[28,\"concat\",[\"toggle-\",[24,[\"elementId\"]]],null]],[11,\"checked\",[28,\"eq\",[[24,[\"firstOptionSelected\"]],true],null]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"firstChoice\"],null]],[10,\"type\",\"radio\"],[8],[9],[0,\"\\n\"],[7,\"label\",true],[11,\"for\",[28,\"concat\",[\"first-toggle-\",[24,[\"elementId\"]]],null]],[8],[0,\"\\n  \"],[1,[22,\"firstLabel\"],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"input\",true],[11,\"id\",[28,\"concat\",[\"second-toggle-\",[24,[\"elementId\"]]],null]],[11,\"name\",[28,\"concat\",[\"toggle-\",[24,[\"elementId\"]]],null]],[11,\"checked\",[28,\"eq\",[[24,[\"firstOptionSelected\"]],false],null]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"secondChoice\"],null]],[10,\"type\",\"radio\"],[8],[9],[0,\"\\n\"],[7,\"label\",true],[11,\"for\",[28,\"concat\",[\"second-toggle-\",[24,[\"elementId\"]]],null]],[8],[0,\"\\n  \"],[1,[22,\"secondLabel\"],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/toggle-buttons.hbs"
    }
  });

  _exports.default = _default;
});