define("ilios-common/services/api-version", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    iliosConfig: Ember.inject.service(),
    version: Ember.computed(function () {
      const {
        apiVersion
      } = Ember.getOwner(this).resolveRegistration('config:environment');
      return apiVersion;
    }),
    isMismatched: Ember.computed('iliosConfig.apiVersion', 'version', async function () {
      const serverApiVersion = await this.iliosConfig.apiVersion;
      return serverApiVersion !== this.version;
    })
  });

  _exports.default = _default;
});