define("ilios-common/controllers/mymaterials", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['course', 'filter', 'sortBy'],
    course: '',
    filter: '',
    sortBy: 'firstOfferingDate:desc',
    materials: Ember.computed.reads('model.materials')
  });

  _exports.default = _default;
});