define("ilios-common/templates/components/collapsed-learnergroups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xIDI1wtw",
    "block": "{\"symbols\":[\"obj\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"title clickable\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[24,[\"expand\"]]],null]],[10,\"role\",\"button\"],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"general.learnerGroups\"],null],false],[0,\" (\"],[1,[28,\"get\",[[28,\"await\",[[24,[\"subject\",\"learnerGroups\"]]],null],\"length\"],null],false],[0,\")\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"collapsed-learnergroups-content\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"is-fulfilled\",[[24,[\"cohortSummaries\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"table\",true],[8],[0,\"\\n      \"],[7,\"thead\",true],[8],[0,\"\\n        \"],[7,\"tr\",true],[8],[0,\"\\n          \"],[7,\"th\",true],[10,\"colspan\",\"4\"],[8],[0,\"\\n            \"],[1,[28,\"t\",[\"general.cohort\"],null],false],[0,\"\\n          \"],[9],[0,\"\\n          \"],[7,\"th\",true],[8],[0,\"\\n            \"],[1,[28,\"t\",[\"general.learnerGroups\"],null],false],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[28,\"sort-by\",[\"title\",[28,\"await\",[[24,[\"cohortSummaries\"]]],null]],null]],null,{\"statements\":[[0,\"          \"],[7,\"tr\",true],[8],[0,\"\\n            \"],[7,\"td\",true],[10,\"colspan\",\"4\"],[8],[0,\"\\n              \"],[1,[23,1,[\"title\"]],false],[0,\"\\n            \"],[9],[0,\"\\n            \"],[7,\"td\",true],[8],[0,\"\\n              \"],[1,[23,1,[\"count\"]],false],[0,\"\\n            \"],[9],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"loading-spinner\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/collapsed-learnergroups.hbs"
    }
  });

  _exports.default = _default;
});