define("ilios-common/templates/events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yRTX+oFx",
    "block": "{\"symbols\":[],\"statements\":[[5,\"back-link\",[],[[],[]]],[5,\"single-event\",[],[[\"@event\"],[[22,\"model\"]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/events.hbs"
    }
  });

  _exports.default = _default;
});