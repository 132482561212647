define("ilios-common/mixins/course-visualize-instructors-controller", ["exports", "ember-concurrency", "ilios-common/utils/escape-reg-exp"], function (_exports, _emberConcurrency, _escapeRegExp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    queryParams: ['name'],
    name: '',
    setName: (0, _emberConcurrency.task)(function* (name) {
      const clean = (0, _escapeRegExp.default)(name);

      if (Ember.isPresent(clean)) {
        yield (0, _emberConcurrency.timeout)(250);
      }

      this.set('name', clean);
    }).restartable()
  });

  _exports.default = _default;
});