define("ilios-common/helpers/filesize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.filesize = filesize;
  _exports.default = void 0;

  function filesize(params
  /*, hash*/
  ) {
    let value = params[0];

    if (typeof value === 'undefined') {
      return null;
    }

    let i,
        rhett,
        units = ['b', 'kb', 'mb', 'gb', 'tb'];

    for (i = 0; i < units.length; i++) {
      if (value < 1024) {
        rhett = Math.floor(value) + units[i];
        break;
      }

      value = value / 1024;
    }

    return rhett;
  }

  var _default = Ember.Helper.helper(filesize);

  _exports.default = _default;
});