define("ilios-common/services/school-events", ["exports", "ilios-common/mixins/events", "moment"], function (_exports, _events, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_events.default, {
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    commonAjax: Ember.inject.service(),
    iliosConfig: Ember.inject.service(),
    namespace: Ember.computed.reads('iliosConfig.apiNameSpace'),

    /**
     * Retrieves a list of school-events for a given school that occur in a given date range,
     * sorted by start dates.
     * @method getEvents
     * @param {int} schoolId
     * @param {int} from
     * @param {int} to
     * @return {Promise.<Array>}
     */
    async getEvents(schoolId, from, to) {
      let url = '';
      const namespace = this.get('namespace');

      if (namespace) {
        url += '/' + namespace;
      }

      url += '/schoolevents/' + schoolId + '?from=' + from + '&to=' + to;
      const commonAjax = this.get('commonAjax');
      const data = await commonAjax.request(url);
      return data.events.map(obj => this.createEventFromData(obj, false)).sortBy('startDate', 'name');
    },

    /**
     * Retrieves and event by it's given slug.
     * @method getEventForSlug
     * @param {String} slug
     * @return {Promise.<Object>}
     */
    async getEventForSlug(slug) {
      const schoolId = parseInt(slug.substring(1, 3), 10);
      const from = (0, _moment.default)(slug.substring(3, 11), 'YYYYMMDD').hour(0);
      const to = from.clone().hour(24);
      const type = slug.substring(11, 12);
      const id = parseInt(slug.substring(12), 10);
      const events = await this.getEvents(schoolId, from.unix(), to.unix());
      return events.find(event => {
        if (type === 'O') {
          return parseInt(event.offering, 10) === id;
        }

        if (type === 'I') {
          return parseInt(event.ilmSession, 10) === id;
        }
      });
    }

  });

  _exports.default = _default;
});