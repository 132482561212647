define("ilios-common/utils/scroll-to", ["exports", "scroll-into-view"], function (_exports, _scrollIntoView) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = scrollTo;

  function scrollTo(elementQuery) {
    if (elementQuery instanceof HTMLElement) {
      throw new Error("scrollTo takes a string, not an element");
    }

    var promise = new Ember.RSVP.Promise(function (resolve) {
      Ember.run.next(() => {
        const element = document.querySelector(elementQuery);
        (0, _scrollIntoView.default)(element);
        resolve();
      });
    });
    return promise;
  }
});