define("ilios-common/components/week-glance-pre-work", ["exports", "ilios-common/templates/components/week-glance-pre-work"], function (_exports, _weekGlancePreWork) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _weekGlancePreWork.default,
    tagName: ''
  });

  _exports.default = _default;
});