define("ilios-common/components/week-glance", ["exports", "moment", "ilios-common/templates/components/week-glance"], function (_exports, _moment, _weekGlance) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    userEvents: Ember.inject.service(),
    intl: Ember.inject.service(),
    layout: _weekGlance.default,
    classNames: ['week-glance'],
    startOfWeek: 0,
    endOfWeek: 6,
    year: null,
    week: null,
    collapsible: true,
    collapsed: true,
    showFullTitle: false,
    'data-test-week-glance': true,
    midnightAtTheStartOfThisWeek: Ember.computed('intl.locale', 'year', 'week', 'startOfWeek', function () {
      this.get('intl'); //we need to use the service so the CP will re-fire

      const year = this.get('year');
      const week = this.get('week');
      const startOfWeek = this.get('startOfWeek');
      const targetDate = (0, _moment.default)();
      targetDate.year(year);
      targetDate.isoWeek(week);
      targetDate.day(startOfWeek);
      return targetDate.hour(0).minute(0);
    }),
    midnightAtTheEndOfThisWeek: Ember.computed('intl.locale', 'year', 'week', 'endOfWeek', function () {
      this.get('intl'); //we need to use the service so the CP will re-fire

      const year = this.get('year');
      const week = this.get('week');
      const endOfWeek = this.get('endOfWeek');
      const targetDate = (0, _moment.default)();
      targetDate.year(year);
      targetDate.isoWeek(week);
      targetDate.day(endOfWeek);
      return targetDate.hour(23).minute(59).second(59);
    }),
    title: Ember.computed('midnightAtTheStartOfThisWeek', 'midnightAtTheEndOfThisWeek', function () {
      const midnightAtTheStartOfThisWeek = this.get('midnightAtTheStartOfThisWeek');
      const midnightAtTheEndOfThisWeek = this.get('midnightAtTheEndOfThisWeek');
      const from = midnightAtTheStartOfThisWeek.format('MMMM D');
      let to;

      if (midnightAtTheStartOfThisWeek.month() != midnightAtTheEndOfThisWeek.month()) {
        to = midnightAtTheEndOfThisWeek.format('MMMM D');
        return "".concat(from, " - ").concat(to);
      } else {
        to = midnightAtTheEndOfThisWeek.format('D');
        return "".concat(from, "-").concat(to);
      }
    }),
    weekEvents: Ember.computed('midnightAtTheStartOfThisWeek', 'midnightAtTheEndOfThisWeek', async function () {
      const midnightAtTheStartOfThisWeek = this.get('midnightAtTheStartOfThisWeek');
      const midnightAtTheEndOfThisWeek = this.get('midnightAtTheEndOfThisWeek');
      const from = midnightAtTheStartOfThisWeek.unix();
      const to = midnightAtTheEndOfThisWeek.unix();
      return await this.get('userEvents').getEvents(from, to);
    }),
    publishedWeekEvents: Ember.computed('weekEvents.[]', async function () {
      const weekEvents = await this.get('weekEvents');
      return weekEvents.filter(ev => {
        return !ev.isBlanked && ev.isPublished && !ev.isScheduled;
      });
    }),
    ilmPreWork: Ember.computed('publishedWeekEvents.[]', async function () {
      const publishedWeekEvents = await this.get('publishedWeekEvents');
      const preWork = publishedWeekEvents.reduce((arr, eventObject) => {
        return arr.pushObjects(eventObject.prerequisites);
      }, []); // grab ILMs only, and group them by session.

      const groups = {};
      preWork.filter(ev => ev.ilmSession).forEach(ilm => {
        if (!Object.prototype.hasOwnProperty.call(groups, ilm.session)) {
          groups[ilm.session] = [];
        }

        groups[ilm.session].pushObject(ilm);
      }); // return an array of arrays of ILMs.

      const sessions = Object.getOwnPropertyNames(groups);
      const rhett = [];
      sessions.forEach(session => {
        rhett.push(groups[session]);
      });
      return rhett.sort((ilmGroupA, ilmGroupB) => {
        const eventA = ilmGroupA.firstObject;
        const eventB = ilmGroupB.firstObject;

        if (eventA.startDate > eventB.startDate) {
          return 1;
        } else if (eventA.startDate < eventB.startDate) {
          return -1;
        }

        if (eventA.postrequisiteName > eventB.postrequisiteName) {
          return 1;
        } else if (eventA.postrequisiteName < eventB.postrequisiteName) {
          return -1;
        }

        if (eventA.session > eventB.session) {
          return 1;
        } else {
          return -1;
        }
      });
    }),
    nonIlmPreWorkEvents: Ember.computed('publishedWeekEvents.[]', async function () {
      const publishedWeekEvents = await this.get('publishedWeekEvents');
      return publishedWeekEvents.filter(ev => {
        return ev.postrequisites.length === 0 || !ev.ilmSession;
      });
    })
  });

  _exports.default = _default;
});