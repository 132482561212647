define("ilios-common/components/selectable-terms-list", ["exports", "ilios-common/templates/components/selectable-terms-list"], function (_exports, _selectableTermsList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _selectableTermsList.default,
    classNames: ['selectable-terms-list'],
    tagName: 'ul',
    selectedTerms: null,
    terms: null,
    actions: {
      add(term) {
        this.add(term);
      },

      remove(term) {
        this.remove(term);
      }

    }
  });

  _exports.default = _default;
});