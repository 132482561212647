define("ilios-common/components/boolean-check", ["exports", "ilios-common/templates/components/boolean-check"], function (_exports, _booleanCheck) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _booleanCheck.default,
    classNames: ['checkbox'],
    tagName: 'span',
    value: false,

    click() {
      return false;
    }

  });

  _exports.default = _default;
});