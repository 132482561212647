define("ilios-common/templates/components/detail-instructors-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dbPaxmzn",
    "block": "{\"symbols\":[\"user\",\"instructorGroup\"],\"statements\":[[4,\"if\",[[24,[\"sortedInstructorGroups\",\"length\"]]],null,{\"statements\":[[0,\"  \"],[7,\"h4\",true],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"general.instructorGroups\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"ul\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"sortedInstructorGroups\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\",true],[8],[0,\"\\n        \"],[1,[23,2,[\"title\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"sortedInstructors\",\"length\"]]],null,{\"statements\":[[0,\"  \"],[7,\"h4\",true],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"general.instructors\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"ul\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"sortedInstructors\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\",true],[8],[0,\"\\n        \"],[1,[23,1,[\"fullName\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/detail-instructors-list.hbs"
    }
  });

  _exports.default = _default;
});