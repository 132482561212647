define("ilios-common/utils/query-utils", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cleanQuery = cleanQuery;
  const {
    trim
  } = _jquery.default;

  function cleanQuery(query) {
    return trim(query).replace(/[-,?~!@#$%&*+'="]/g, ' ');
  }
});