define("ilios-common/templates/components/objective-sort-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PLUx1Tlh",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[4,\"unless\",[[24,[\"loadAttr\",\"isRunning\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"bigadd\"],[3,\"action\",[[23,0,[]],\"save\"]],[8],[0,\"\\n      \"],[5,\"fa-icon\",[],[[\"@icon\"],[\"check\"]]],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"bigcancel\"],[3,\"action\",[[23,0,[]],\"cancel\"]],[8],[0,\"\\n      \"],[5,\"fa-icon\",[],[[\"@icon\"],[\"undo\"]]],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n    \"],[5,\"sortable-objects\",[],[[\"@sortableObjectList\",\"@enableSort\",\"@useSwap\",\"@sortingScope\"],[[22,\"sortableObjectList\"],true,true,\"sortingGroup\"]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[24,[\"sortableObjectList\"]]],null,{\"statements\":[[0,\"        \"],[5,\"draggable-object\",[],[[\"@content\",\"@isSortable\",\"@sortingScope\"],[[23,1,[]],true,\"sortingGroup\"]],{\"statements\":[[0,\"\\n          \"],[5,\"fa-icon\",[],[[\"@icon\"],[\"arrows-alt\"]]],[0,\"\\n          \"],[7,\"span\",true],[10,\"class\",\"draggable-object-content\"],[8],[0,\"\\n            \"],[7,\"span\",true],[8],[0,\"\\n              \"],[5,\"big-text\",[],[[\"@text\"],[[23,1,[\"title\"]]]]],[0,\"\\n            \"],[9],[0,\"\\n          \"],[9],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/objective-sort-manager.hbs"
    }
  });

  _exports.default = _default;
});