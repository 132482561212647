define("ilios-common/components/progress-bar", ["exports", "ilios-common/templates/components/progress-bar"], function (_exports, _progressBar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _progressBar.default,
    classNames: ['progress-bar'],
    percentage: 0,
    widthStyle: Ember.computed('percentage', function () {
      const percentage = this.get('percentage');
      const str = "width: ".concat(percentage, "%");
      return Ember.String.htmlSafe(str);
    })
  });

  _exports.default = _default;
});