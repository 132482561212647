define("ilios-common/templates/components/search-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GAKNdBUc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"input\",true],[10,\"class\",\"search-input\"],[11,\"value\",[22,\"value\"]],[11,\"oninput\",[28,\"action\",[[23,0,[]],\"update\"],[[\"value\"],[\"target.value\"]]]],[11,\"placeholder\",[22,\"placeholder\"]],[10,\"type\",\"search\"],[8],[9],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"search-icon\"],[11,\"onclick\",[28,\"pipe-action\",[[28,\"action\",[[23,0,[]],\"focus\"],null],[28,\"perform\",[[24,[\"searchTask\"]]],null]],null]],[10,\"role\",\"button\"],[8],[0,\"\\n  \"],[5,\"fa-icon\",[],[[\"@icon\"],[\"search\"]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/search-box.hbs"
    }
  });

  _exports.default = _default;
});