define("ilios-common/components/new-offering", ["exports", "ilios-common/templates/components/new-offering"], function (_exports, _newOffering) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    layout: _newOffering.default,
    classNames: ['new-offering'],
    session: null,
    courseStartDate: null,
    courseEndDate: null,
    smallGroupMode: true,
    actions: {
      save(startDate, endDate, room, learnerGroups, instructorGroups, instructors) {
        const store = this.get('store');
        const session = this.get('session');
        let offering = store.createRecord('offering');
        offering.setProperties({
          startDate,
          endDate,
          room,
          learnerGroups,
          instructorGroups,
          instructors,
          session
        });
        return offering.save();
      }

    }
  });

  _exports.default = _default;
});