define("ilios-common/components/course-publicationcheck", ["exports", "ilios-common/templates/components/course-publicationcheck"], function (_exports, _coursePublicationcheck) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    layout: _coursePublicationcheck.default,
    classNames: ['course-publicationcheck'],
    course: null,
    showUnlinkIcon: Ember.computed('course.objectives.[]', function () {
      const objectives = this.course.objectives;
      return objectives.any(objective => Ember.isEmpty(objective.parents));
    }),
    actions: {
      transitionToCourse() {
        const queryParams = {
          courseObjectiveDetails: true,
          details: true
        };
        this.router.transitionTo('course', this.course, {
          queryParams
        });
      }

    }
  });

  _exports.default = _default;
});