define("ilios-common/templates/components/timed-release-schedule", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NB7Sq8ys",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"and\",[[24,[\"startDate\"]],[24,[\"endDate\"]],[24,[\"endDateInTheFuture\"]]],null]],null,{\"statements\":[[0,\"  (\"],[1,[28,\"t\",[\"general.timedReleaseStartAndEndDate\"],[[\"startDate\",\"endDate\"],[[28,\"moment-format\",[[24,[\"startDate\"]],\"L LT\"],null],[28,\"moment-format\",[[24,[\"endDate\"]],\"L LT\"],null]]]],false],[0,\")\\n\"]],\"parameters\":[]},null],[4,\"if\",[[28,\"and\",[[24,[\"startDate\"]],[28,\"not\",[[24,[\"endDate\"]]],null],[24,[\"startDateInTheFuture\"]]],null]],null,{\"statements\":[[0,\"  (\"],[1,[28,\"t\",[\"general.timedReleaseOnlyStartDate\"],[[\"startDate\"],[[28,\"moment-format\",[[24,[\"startDate\"]],\"L LT\"],null]]]],false],[0,\")\\n\"]],\"parameters\":[]},null],[4,\"if\",[[28,\"and\",[[24,[\"endDate\"]],[28,\"not\",[[24,[\"startDate\"]]],null],[24,[\"endDateInTheFuture\"]]],null]],null,{\"statements\":[[0,\"  (\"],[1,[28,\"t\",[\"general.timedReleaseOnlyEndDate\"],[[\"endDate\"],[[28,\"moment-format\",[[24,[\"endDate\"]],\"L LT\"],null]]]],false],[0,\")\\n\"]],\"parameters\":[]},null],[4,\"if\",[[28,\"and\",[[28,\"not\",[[24,[\"startDate\"]]],null],[28,\"not\",[[24,[\"endDate\"]]],null],[24,[\"showNoSchedule\"]]],null]],null,{\"statements\":[[0,\"  \"],[1,[28,\"t\",[\"general.timedReleaseNoSchedule\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/timed-release-schedule.hbs"
    }
  });

  _exports.default = _default;
});