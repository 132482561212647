define("ilios-common/components/ilios-calendar-month", ["exports", "ilios-common/templates/components/ilios-calendar-month"], function (_exports, _iliosCalendarMonth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _iliosCalendarMonth.default,
    classNames: ['ilios-calendar-month'],
    date: null,
    calendarEvents: null,
    showMore: null,
    areEventsSelectable: true,
    ilmPreWorkEvents: Ember.computed('calendarEvents.[]', function () {
      const calendarEvents = this.calendarEvents || [];
      const preWork = calendarEvents.reduce((arr, eventObject) => {
        return arr.pushObjects(eventObject.prerequisites);
      }, []);
      return preWork.filter(ev => ev.ilmSession);
    }),
    nonIlmPreWorkEvents: Ember.computed('calendarEvents.[]', function () {
      const calendarEvents = this.calendarEvents || [];
      return calendarEvents.filter(ev => {
        return ev.postrequisites.length === 0 || !ev.ilmSession;
      });
    }),
    actions: {
      changeToDayView(date) {
        this.get('changeDate')(date);
        this.get('changeView')('day');
      }

    }
  });

  _exports.default = _default;
});