define("ilios-common/components/sessions-grid", ["exports", "ilios-common/utils/escape-reg-exp", "ember-concurrency", "ilios-common/templates/components/sessions-grid", "ember-lifeline"], function (_exports, _escapeRegExp, _emberConcurrency, _sessionsGrid, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    preserveScroll: Ember.inject.service(),
    router: Ember.inject.service(),
    layout: _sessionsGrid.default,
    classNames: ['sessions-grid'],
    'data-test-sessions-grid': true,
    confirmDeleteSessionIds: null,
    filteredSessions: Ember.computed('sessions.[]', 'filterBy', function () {
      const sessions = this.get('sessions');
      const filterBy = this.get('filterBy');

      if (Ember.isEmpty(sessions)) {
        return [];
      }

      if (Ember.isEmpty(filterBy)) {
        return sessions;
      }

      let filterExpressions = filterBy.split(' ').map(function (string) {
        const clean = (0, _escapeRegExp.default)(string);
        return new RegExp(clean, 'gi');
      });
      return sessions.filter(session => {
        let matchedSearchTerms = 0;

        for (let i = 0; i < filterExpressions.length; i++) {
          if (session.searchString.match(filterExpressions[i])) {
            matchedSearchTerms++;
          }
        } //if the number of matching search terms is equal to the number searched, return true


        return matchedSearchTerms === filterExpressions.length;
      });
    }),
    sortedSessions: Ember.computed('filteredSessions.[]', 'sortInfo', function () {
      const sessions = this.get('filteredSessions');
      const sortInfo = this.get('sortInfo');

      if (sortInfo.descending) {
        return sessions.sortBy(sortInfo.column).reverse();
      }

      return sessions.sortBy(sortInfo.column);
    }),
    sortInfo: Ember.computed('sortBy', function () {
      const sortBy = this.get('sortBy');
      const parts = sortBy.split(':');
      const column = parts[0];
      const descending = parts.length > 1 && parts[1] === 'desc';
      return {
        column,
        descending,
        sortBy
      };
    }),

    init() {
      this._super(...arguments);

      this.set('confirmDeleteSessionIds', []);
    },

    didInsertElement() {
      this._super(...arguments);

      this.scrollDown();
    },

    didRender() {
      this._super(...arguments);

      (0, _emberLifeline.addEventListener)(this, window, 'scroll', () => {
        const isCourseRoute = this.router.currentRouteName === 'course.index';
        const preserveScroll = this.preserveScroll;

        if (isCourseRoute) {
          const yPos = window.scrollY;
          preserveScroll.set('yPos', yPos === 0 ? null : yPos);
        }
      });
    },

    actions: {
      confirmDelete(sessionId) {
        this.confirmDeleteSessionIds.pushObject(sessionId);
      },

      cancelDelete(sessionId) {
        this.confirmDeleteSessionIds.removeObject(sessionId);
      },

      expandSession(sessionObject) {
        if (sessionObject.offeringCount) {
          this.expandSession(sessionObject.session);
        }
      }

    },

    destroy() {
      (0, _emberLifeline.runDisposables)(this);

      this._super(...arguments);
    },

    scrollDown() {
      const preserveScroll = this.preserveScroll;
      const {
        shouldScrollDown,
        yPos
      } = preserveScroll;
      Ember.run.next(() => {
        if (shouldScrollDown && yPos) {
          window.scroll(0, yPos);
        }
      });
    },

    removeSession: (0, _emberConcurrency.task)(function* (session) {
      session.deleteRecord();
      yield session.save();
    }).drop()
  });

  _exports.default = _default;
});