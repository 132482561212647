define("ilios-common/components/back-link", ["exports", "ilios-common/templates/components/back-link"], function (_exports, _backLink) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    layout: _backLink.default,
    tagName: 'a',
    classNames: ['back-link'],
    attributeBindings: ['title'],
    title: Ember.computed('intl.service', function () {
      const intl = this.get('intl');
      return intl.t('general.returnToPreviousPage');
    }),

    click() {
      window.history.back();
    }

  });

  _exports.default = _default;
});