define("ilios-common/templates/components/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2dFtEc7l",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"t\",[\"general.notFoundMessage\"],null],false],[0,\"\\n\"],[7,\"br\",true],[8],[9],[0,\"\\n\"],[5,\"link-to\",[],[[\"@route\"],[\"index\"]],{\"statements\":[[0,\"\\n  \"],[1,[28,\"t\",[\"general.backToDashboard\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/not-found.hbs"
    }
  });

  _exports.default = _default;
});