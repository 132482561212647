define("ilios-common/helpers/lm-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.lmType = lmType;
  _exports.default = void 0;

  function lmType(params
  /*, hash*/
  ) {
    const obj = params[0];

    if (Object.prototype.hasOwnProperty.call(obj, 'filename')) {
      return 'file';
    }

    if (Object.prototype.hasOwnProperty.call(obj, 'citation')) {
      return 'citation';
    }

    if (Object.prototype.hasOwnProperty.call(obj, 'link')) {
      return 'link';
    }
  }

  var _default = Ember.Helper.helper(lmType);

  _exports.default = _default;
});