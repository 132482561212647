define("ilios-common/components/learning-materials-sort-manager", ["exports", "ilios-common/mixins/sortable-by-position", "ember-concurrency", "ilios-common/templates/components/learning-materials-sort-manager"], function (_exports, _sortableByPosition, _emberConcurrency, _learningMaterialsSortManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_sortableByPosition.default, {
    layout: _learningMaterialsSortManager.default,
    classNames: ['learning-materials-sort-manager'],
    sortableObjectList: null,
    subject: null,

    didReceiveAttrs() {
      this._super(...arguments);

      let subject = this.get('subject');
      this.get('loadAttr').perform(subject);
    },

    actions: {
      cancel() {
        this.cancel();
      },

      save() {
        this.save(this.get('sortableObjectList'));
      }

    },
    loadAttr: (0, _emberConcurrency.task)(function* (subject) {
      let learningMaterials = yield subject.get('learningMaterials');
      this.set('sortableObjectList', learningMaterials.toArray().sort(this.get('positionSortingCallback')));
    })
  });

  _exports.default = _default;
});