define("ilios-common/components/detail-learnergroups", ["exports", "ember-concurrency", "ilios-common/templates/components/detail-learnergroups"], function (_exports, _emberConcurrency, _detailLearnergroups) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _detailLearnergroups.default,
    classNames: ['detail-learnergroups'],
    tagName: 'div',
    subject: null,
    isIlmSession: false,
    editable: true,
    isManaging: false,
    learnerGroups: null,
    'data-test-detail-learner-groups': true,
    collapsible: Ember.computed('isManaging', 'learnerGroups.length', function () {
      const isManaging = this.get('isManaging');
      const learnerGroups = this.get('learnerGroups');
      return learnerGroups.get('length') && !isManaging;
    }),

    init() {
      this._super(...arguments);

      this.set('learnerGroups', []);
      this.get('loadLearnerGroups').perform();
    },

    didUpdateAttrs() {
      this._super(...arguments);

      this.get('loadLearnerGroups').perform();
    },

    actions: {
      cancel() {
        this.get('loadLearnerGroups').perform();
        this.get('setIsManaging')(false);
      },

      addLearnerGroup(learnerGroup) {
        let learnerGroups = this.get('learnerGroups').toArray();
        learnerGroups.addObject(learnerGroup);
        learnerGroup.get('allDescendants').then(function (descendants) {
          learnerGroups.addObjects(descendants);
        }); //re-create the object so we trigger downstream didReceiveAttrs

        this.set('learnerGroups', learnerGroups);
      },

      removeLearnerGroup(learnerGroup) {
        let learnerGroups = this.get('learnerGroups').toArray();
        learnerGroups.removeObject(learnerGroup);
        learnerGroup.get('allDescendants').then(function (descendants) {
          learnerGroups.removeObjects(descendants);
        }); //re-create the object so we trigger downstream didReceiveAttrs

        this.set('learnerGroups', learnerGroups);
      }

    },
    loadLearnerGroups: (0, _emberConcurrency.task)(function* () {
      const subject = this.get('subject');

      if (subject) {
        let learnerGroups = yield subject.get('learnerGroups');
        this.set('learnerGroups', learnerGroups.toArray());
      } else {
        yield (0, _emberConcurrency.timeout)(1000);
      }
    }).restartable(),
    save: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(10);
      let subject = this.get('subject');
      let learnerGroups = this.get('learnerGroups');
      subject.set('learnerGroups', learnerGroups);

      try {
        yield subject.save();
      } finally {
        this.get('setIsManaging')(false);
        this.get('expand')();
      }
    })
  });

  _exports.default = _default;
});