define("ilios-common/templates/components/selectable-terms-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yXcLmGVD",
    "block": "{\"symbols\":[\"term\"],\"statements\":[[4,\"each\",[[28,\"sort-by\",[\"title\",[24,[\"terms\"]]],null]],null,{\"statements\":[[4,\"if\",[[28,\"await\",[[23,1,[\"isActiveInTree\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"li\",true],[11,\"class\",[28,\"if\",[[23,1,[\"isTopLevel\"]],\"top-level\",\"\"],null]],[8],[0,\"\\n      \"],[5,\"selectable-terms-list-item\",[],[[\"@selectedTerms\",\"@term\",\"@add\",\"@remove\"],[[22,\"selectedTerms\"],[23,1,[]],[28,\"action\",[[23,0,[]],\"add\"],null],[28,\"action\",[[23,0,[]],\"remove\"],null]]]],[0,\"\\n\"],[4,\"if\",[[23,1,[\"hasChildren\"]]],null,{\"statements\":[[0,\"        \"],[5,\"selectable-terms-list\",[],[[\"@selectedTerms\",\"@terms\",\"@add\",\"@remove\"],[[22,\"selectedTerms\"],[28,\"await\",[[23,1,[\"children\"]]],null],[28,\"action\",[[23,0,[]],\"add\"],null],[28,\"action\",[[23,0,[]],\"remove\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/selectable-terms-list.hbs"
    }
  });

  _exports.default = _default;
});