define("ilios-common/models/aamc-pcrs", ["exports", "ember-data", "ember-inflector"], function (_exports, _emberData, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany
  } = _emberData.default;

  _emberInflector.default.inflector.uncountable('aamc-pcrs');

  var _default = Model.extend({
    description: attr('string'),
    competencies: hasMany('competency', {
      async: true
    })
  });

  _exports.default = _default;
});