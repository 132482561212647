define("ilios-common/models/mesh-term", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    hasMany,
    Model
  } = _emberData.default;

  var _default = Model.extend({
    meshTermUid: attr('string'),
    name: attr('string'),
    lexicalTag: attr('string'),
    conceptPreferred: attr('string'),
    recordPreferred: attr('string'),
    permuted: attr('string'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    concepts: hasMany('mesh-concept', {
      async: true
    })
  });

  _exports.default = _default;
});