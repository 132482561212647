define("ilios-common/components/selectable-terms-list-item", ["exports", "ilios-common/templates/components/selectable-terms-list-item"], function (_exports, _selectableTermsListItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _selectableTermsListItem.default,
    selectedTerms: null,
    term: null,
    tagName: 'div',
    classNameBindings: [':selectable-terms-list-item', 'isSelected:selected'],
    isSelected: Ember.computed('term', 'selectedTerms.[]', function () {
      let term = this.get('term');
      let selectedTerms = this.get('selectedTerms');
      return selectedTerms.includes(term);
    }),

    click() {
      let term = this.get('term');

      if (this.get('isSelected')) {
        this.remove(term);
      } else {
        this.add(term);
      }
    }

  });

  _exports.default = _default;
});