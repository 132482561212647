define("elemental-calendar/models/day", ["exports", "moment", "elemental-calendar/models/hour"], function (_exports, _moment, _hour) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let Day = Ember.Object.extend({
    events: null,
    date: null,
    eventList: null,
    dateKey: Ember.computed.alias('formattedDate'),
    _momentDate: Ember.computed('date', function () {
      return (0, _moment.default)(this.date);
    }),
    formattedDate: Ember.computed('_momentDate', function () {
      return this.get('_momentDate').format('YYYY-MM-DD');
    }),
    dayName: Ember.computed('_momentDate', function () {
      let weekday = this.get('_momentDate').day();
      return (0, _moment.default)().weekday(weekday).format('dddd');
    }),
    hours: Ember.computed('_momentDate', function () {
      let hours = Ember.A();
      let momentDate = this.get('_momentDate').clone();

      for (let i = 0; i < 24; i++) {
        momentDate.hour(i);
        let datetime = momentDate.toDate();

        let hour = _hour.default.create({
          datetime: datetime,
          eventList: this.eventList
        });

        hours.pushObject(hour);
      }

      return hours;
    }),

    init() {
      this._super(...arguments);

      this.events = this.eventList.clusteredEventsForDay(this);
    }

  });
  var _default = Day;
  _exports.default = _default;
});