define("ilios-common/components/dashboard-agenda", ["exports", "ilios-common/templates/components/dashboard-agenda", "moment"], function (_exports, _dashboardAgenda, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    userEvents: Ember.inject.service(),
    layout: _dashboardAgenda.default,
    areEventsSelectable: true,

    /**
     * Days in advance of the current date.
     * @property daysInAdvance
     * @type int
     * @public
     */
    daysInAdvance: 60,
    classNames: ['dashboard-agenda'],
    sixDaysAgo: (0, _moment.default)().hour(0).minute(0).subtract(6, 'days'),
    weeksEvents: Ember.computed('daysInAdvance', async function () {
      const daysInAdvance = this.get('daysInAdvance');
      const from = (0, _moment.default)().hour(0).minute(0).unix();
      const to = (0, _moment.default)().hour(23).minute(59).add(daysInAdvance, 'days').unix();
      return await this.get('userEvents').getEvents(from, to);
    }),
    ilmPreWorkEvents: Ember.computed('weeksEvents.[]', async function () {
      const events = await this.get('weeksEvents');
      let preWork = events.reduce((arr, eventObject) => {
        return arr.pushObjects(eventObject.prerequisites);
      }, []);
      preWork.filter(ev => ev.ilmSession);
      const hashes = [];
      const uniques = [];
      preWork.forEach(event => {
        let hash = (0, _moment.default)(event.startDate).format() + (0, _moment.default)(event.endDate).format() + event.name;

        if (!hashes.includes(hash)) {
          hashes.push(hash);
          uniques.pushObject(event);
        }
      });
      return uniques;
    }),
    nonIlmPreWorkEvents: Ember.computed('weeksEvents.[]', async function () {
      let events = await this.get('weeksEvents');
      return events.filter(ev => {
        return ev.postrequisites.length === 0 || !ev.ilmSession;
      });
    })
  });

  _exports.default = _default;
});