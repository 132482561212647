define("ilios-common/components/toggle-yesno", ["exports", "ilios-common/templates/components/toggle-yesno"], function (_exports, _toggleYesno) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _toggleYesno.default,
    label: null,
    yes: false,
    tagName: 'span',
    classNames: ['toggle-yesno'],
    'data-test-toggle-yesno': true,

    click() {
      const yes = this.get('yes');
      this.get('toggle')(!yes);
    }

  });

  _exports.default = _default;
});