define("ilios-common/helpers/browser-timezone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.browserTimezone = browserTimezone;
  _exports.default = void 0;

  function browserTimezone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  var _default = Ember.Helper.helper(browserTimezone);

  _exports.default = _default;
});