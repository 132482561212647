define("ilios-common/helpers/count-related", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.countRelated = countRelated;
  _exports.default = void 0;

  function countRelated(params) {
    if (Ember.typeOf(params) !== 'array' || params.length < 2) {
      return false;
    }

    let object = params[0];
    let what = params[1];

    if (Ember.isEmpty(what) || Ember.isEmpty(object)) {
      return false;
    }

    if (Ember.typeOf(object.hasMany) != 'function') {
      return false;
    }

    return object.hasMany(what).ids().length;
  }

  var _default = Ember.Helper.helper(countRelated);

  _exports.default = _default;
});