define("ilios-common/helpers/remove-html-tags", ["exports", "striptags"], function (_exports, _striptags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.removeHtmlTags = removeHtmlTags;
  _exports.default = void 0;

  function removeHtmlTags(params) {
    if (!(params[0] === undefined)) {
      return (0, _striptags.default)(params[0]);
    }
  }

  var _default = Ember.Helper.helper(removeHtmlTags);

  _exports.default = _default;
});