define("ilios-common/components/action-menu", ["exports", "ember-rl-dropdown/mixins/rl-dropdown-component", "ilios-common/templates/components/action-menu"], function (_exports, _rlDropdownComponent, _actionMenu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_rlDropdownComponent.default, {
    layout: _actionMenu.default,
    title: '',
    icon: 'gear',
    classNames: ['action-menu']
  });

  _exports.default = _default;
});