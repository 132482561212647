define("ilios-common/templates/components/sessions-grid-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4lqRz6uw",
    "block": "{\"symbols\":[\"empty\",\"@count\"],\"statements\":[[4,\"each\",[[28,\"repeat\",[[23,2,[]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[8],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"expand-collapse-control\"],[8],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"session-grid-title\"],[8],[0,\"\\n      \"],[1,[28,\"truncate\",[[28,\"repeat\",[[28,\"random\",[3,10],null],\"ilios rocks\"],null],100],null],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"session-grid-type\"],[8],[0,\"\\n      \"],[1,[28,\"repeat\",[[28,\"random\",[1,3],null],\"loading \"],null],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"session-grid-groups\"],[8],[0,\"\\n      \"],[1,[28,\"random\",[1,99],null],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"session-grid-objectives\"],[8],[0,\"\\n      \"],[1,[28,\"random\",[1,99],null],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"session-grid-terms\"],[8],[0,\"\\n      \"],[1,[28,\"random\",[1,99],null],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"session-grid-first-offering\"],[8],[0,\"\\n      \"],[1,[28,\"moment\",[\"2005-06-24\",\"L LT\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"session-grid-offerings\"],[8],[0,\"\\n      \"],[1,[28,\"random\",[1,99],null],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"session-grid-status\"],[8],[0,\"\\n      \"],[5,\"fa-icon\",[],[[\"@icon\"],[\"star-half-alt\"]]],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"session-grid-actions\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/sessions-grid-loading.hbs"
    }
  });

  _exports.default = _default;
});