define("ember-simple-charts/mixins/chart-properties", ["exports", "ember-resize-aware/mixins/resize-aware"], function (_exports, _resizeAware) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_resizeAware.default, {
    tagName: 'svg',
    attributeBindings: ['_width:width', '_height:height', '_xmlns:xmlns'],
    _width: '100%',
    _height: '100%',
    _xmlns: 'http://www.w3.org/2000/svg',
    currentClientHeight: null,
    currentClientWidth: null,
    data: null,
    isIcon: false,
    resizeListener: null,
    isClickable: false,

    didReceiveAttrs() {
      // Anytime we get an update schedule a draw
      Ember.run.scheduleOnce('afterRender', this, this.doDraw);
    },

    didResize(newClientWidth, newClientHeight) {
      const currentClientHeight = Ember.get(this, 'currentClientHeight');
      const currentClientWidth = Ember.get(this, 'currentClientWidth');

      if (currentClientHeight != newClientHeight || currentClientWidth != newClientWidth) {
        this.doDraw();
      }
    },

    doDraw() {
      let rect = {
        height: 0,
        width: 0
      };

      if (this.element) {
        rect = this.element.getBoundingClientRect();
      }

      const {
        height,
        width
      } = rect;
      this.draw(height, width);

      if (!(Ember.get(this, 'isDestroyed') || Ember.get(this, 'isDestroying'))) {
        Ember.set(this, 'currentClientHeight', height);
        Ember.set(this, 'currentClientWidth', width);
      }
    }

  });

  _exports.default = _default;
});