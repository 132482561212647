define("ilios-common/components/course-header", ["exports", "ilios-common/templates/components/course-header", "ilios-common/mixins/publishable", "ember-cp-validations", "ilios-common/mixins/validation-error-display"], function (_exports, _courseHeader, _publishable, _emberCpValidations, _validationErrorDisplay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    alias
  } = Ember.computed;
  const Validations = (0, _emberCpValidations.buildValidations)({
    courseTitle: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 3,
      max: 200
    })]
  });

  var _default = Ember.Component.extend(Validations, _publishable.default, _validationErrorDisplay.default, {
    layout: _courseHeader.default,
    classNames: ['course-header'],
    course: null,
    courseTitle: null,
    editable: false,
    'data-test-course-header': true,
    publishTarget: alias('course'),

    didReceiveAttrs() {
      this._super(...arguments);

      this.set('courseTitle', this.get('course.title'));
    },

    actions: {
      async changeTitle() {
        const course = this.course;
        const newTitle = this.courseTitle;
        this.send('addErrorDisplayFor', 'courseTitle');
        const {
          validations
        } = await this.validate();

        if (validations.isValid) {
          this.send('removeErrorDisplayFor', 'courseTitle');
          course.set('title', newTitle);
          const newCourse = await course.save();
          this.set('courseTitle', newCourse.title);
          this.set('course', newCourse);
        } else {
          await Ember.RSVP.reject();
        }
      },

      revertTitleChanges() {
        const course = this.get('course');
        this.set('courseTitle', course.get('title'));
      }

    }
  });

  _exports.default = _default;
});