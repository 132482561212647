define("ilios-common/templates/components/lm-type-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aYyIGr+U",
    "block": "{\"symbols\":[],\"statements\":[[5,\"fa-icon\",[],[[\"@icon\",\"@listItem\",\"@title\"],[[22,\"icon\"],[22,\"listItem\"],[28,\"t\",[[24,[\"title\"]]],null]]]],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/lm-type-icon.hbs"
    }
  });

  _exports.default = _default;
});