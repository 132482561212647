define("ilios-common/components/collapsed-objectives", ["exports", "ilios-common/templates/components/collapsed-objectives"], function (_exports, _collapsedObjectives) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _collapsedObjectives.default,
    tagName: 'section',
    classNames: ['collapsed-objectives'],
    subject: null,
    objectives: Ember.computed('subject.objectives.[]', async function () {
      const subject = this.get('subject');
      const objectives = await subject.get('objectives');
      return objectives;
    }),
    objectivesWithParents: Ember.computed('objectives.[]', async function () {
      const objectives = await this.get('objectives');
      const objectivesWithParents = objectives.filter(objective => {
        const parentIds = objective.hasMany('parents').ids();
        return parentIds.length > 0;
      });
      return objectivesWithParents;
    }),
    objectivesWithMesh: Ember.computed('objectives.[]', async function () {
      const objectives = await this.get('objectives');
      const objectivesWithMesh = objectives.filter(objective => {
        const meshDescriptorIds = objective.hasMany('meshDescriptors').ids();
        return meshDescriptorIds.length > 0;
      });
      return objectivesWithMesh;
    })
  });

  _exports.default = _default;
});