define("ilios-common/serializers/offering", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend({
    isNewSerializerAPI: true,

    serialize(snapshot, options) {
      var json = this._super(snapshot, options); //don't persist this, it is handled by the server


      delete json.updatedAt;
      return json;
    }

  });

  _exports.default = _default;
});