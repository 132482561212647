define("ilios-common/adapters/ilios", ["exports", "ember-data", "ember-simple-auth-token/mixins/token-authorizer", "ember-inflector"], function (_exports, _emberData, _tokenAuthorizer, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    RESTAdapter
  } = _emberData.default;

  var _default = RESTAdapter.extend(_tokenAuthorizer.default, {
    iliosConfig: Ember.inject.service(),
    host: Ember.computed.reads('iliosConfig.apiHost'),
    namespace: Ember.computed.reads('iliosConfig.apiNameSpace'),
    coalesceFindRequests: true,

    shouldReloadAll() {
      return true;
    },

    findMany(store, type, ids, snapshots) {
      let url = this.urlForFindMany(ids, type.modelName, snapshots);
      return this.ajax(url, 'GET', {
        data: {
          filters: {
            id: ids
          }
        }
      });
    },

    pathForType(type) {
      return (0, _emberInflector.pluralize)(type.camelize().toLowerCase());
    },

    sortQueryParams: false
  });

  _exports.default = _default;
});