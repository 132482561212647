define("ilios-common/serializers/curriculum-inventory-sequence-block", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend({
    isNewSerializerAPI: true,

    serialize(snapshot, options) {
      var json = this._super(snapshot, options);

      if (json.startDate) {
        json.startDate = _moment.default.utc(json.startDate).local().format('YYYY-MM-DD');
      }

      if (json.endDate) {
        json.endDate = _moment.default.utc(json.endDate).local().format('YYYY-MM-DD');
      }

      return json;
    },

    normalize(modelClass, resourceHash, prop) {
      if (resourceHash.startDate) {
        let startDate = _moment.default.utc(resourceHash.startDate).format('YYYY-MM-DD');

        let localStartDate = (0, _moment.default)(startDate, 'YYYY-MM-DD');
        resourceHash.startDate = localStartDate.format();
      }

      if (resourceHash.endDate) {
        let endDate = _moment.default.utc(resourceHash.endDate).format('YYYY-MM-DD');

        let localEndDate = (0, _moment.default)(endDate, 'YYYY-MM-DD');
        resourceHash.endDate = localEndDate.format();
      }

      return this._super(modelClass, resourceHash, prop);
    }

  });

  _exports.default = _default;
});