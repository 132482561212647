define("ilios-common/helpers/has-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute([value]) {
      // @link https://stackoverflow.com/a/52655828/307333
      return typeof Ember.getOwner(this).lookup("route:".concat(value)) !== 'undefined';
    }

  });

  _exports.default = _default;
});