define('@fortawesome/free-brands-svg-icons', ['exports'], function (exports) { 'use strict';

  var faBlackTie = {
    prefix: 'fab',
    iconName: 'black-tie',
    icon: [448, 512, [], "f27e", "M0 32v448h448V32H0zm316.5 325.2L224 445.9l-92.5-88.7 64.5-184-64.5-86.6h184.9L252 173.2l64.5 184z"]
  };

  exports.faBlackTie = faBlackTie;

  Object.defineProperty(exports, '__esModule', { value: true });

});
