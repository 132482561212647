define('ember-moment/helpers/moment-from', ['exports', 'ember-moment/utils/helper-compute', 'ember-moment/helpers/-base'], function (exports, _helperCompute, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  exports.default = _base.default.extend({
    compute: (0, _helperCompute.default)(function (_ref, _ref2) {
      var _ref3 = _toArray(_ref);

      let datetime = _ref3[0],
          params = _ref3.slice(1);

      let hideAffix = _ref2.hideAffix,
          locale = _ref2.locale,
          timeZone = _ref2.timeZone;

      this._super(...arguments);

      const moment = Ember.get(this, 'moment');

      return this.morphMoment(moment.moment(datetime), { locale, timeZone }).from(...params, hideAffix);
    })
  });
});