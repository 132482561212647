define("ember-intl/utils/links", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const GUIDE_URL = 'https://ember-intl.github.io/ember-intl/docs/guide/';
  var _default = {
    unsetLocale: "".concat(GUIDE_URL, "ember-service-api#locale"),
    asyncTranslations: "".concat(GUIDE_URL, "asynchronously-loading-translations"),
    polyfill: "".concat(GUIDE_URL, "intljs-polyfill")
  };
  _exports.default = _default;
});