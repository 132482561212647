define("ilios-common/mixins/objective-list-item", ["exports", "ember-cp-validations", "ilios-common/mixins/validation-error-display"], function (_exports, _emberCpValidations, _validationErrorDisplay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    title: [(0, _emberCpValidations.validator)('html-presence', true), (0, _emberCpValidations.validator)('length', {
      min: 3,
      max: 65000
    })]
  });

  var _default = Ember.Mixin.create(_validationErrorDisplay.default, Validations, {
    didReceiveAttrs() {
      this._super(...arguments);

      this.set('title', this.get('objective').get('title'));
    },

    tagName: 'tr',
    classNameBindings: ['showRemoveConfirmation:confirm-removal'],
    objective: null,
    title: null,
    isSaving: false,
    showRemoveConfirmation: false,
    editable: true,
    actions: {
      async saveTitleChanges() {
        this.send('addErrorDisplayFor', 'title');
        const {
          objective,
          title
        } = this.getProperties('objective', 'title');
        const {
          validations
        } = await this.validate();

        if (validations.isValid) {
          objective.set('title', title);
          this.send('removeErrorDisplayFor', 'title');
          await objective.save();
        } else {
          await Ember.RSVP.reject();
        }
      },

      revertTitleChanges() {
        const objective = this.get('objective');
        this.set('title', objective.get('title'));
      },

      changeTitle(contents) {
        this.send('addErrorDisplayFor', 'title');
        this.set('title', contents);
      }

    }
  });

  _exports.default = _default;
});