define("ilios-common/components/big-text", ["exports", "ilios-common/templates/components/big-text"], function (_exports, _bigText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    collect,
    sum
  } = Ember.computed;

  var _default = Ember.Component.extend({
    layout: _bigText.default,
    expanded: false,
    classNames: ['big-text'],
    tagName: 'span',
    length: 200,
    slippage: 25,
    expandIcon: 'info-circle',
    text: '',
    ellipsis: 'ellipsis-h',
    renderHtml: true,

    onEdit() {},

    lengths: collect('length', 'slippage'),
    totalLength: sum('lengths'),
    showIcons: Ember.computed('displayText', 'text', 'renderHtml', function () {
      if (this.get('renderHtml')) {
        return this.get('displayText').toString() !== this.get('text');
      } else {
        return this.get('displayText').toString() !== this.get('cleanText');
      }
    }),
    cleanText: Ember.computed('text', function () {
      let text = this.get('text') || ''; //accounts for SafeString as well a numbers

      if (Ember.typeOf(text) !== 'string') {
        text = text.toString();
      } //strip any possible HTML out of the text


      return text.replace(/(<([^>]+)>)/ig, "");
    }),
    displayText: Ember.computed('cleanText', 'totalLength', 'length', 'expanded', function () {
      let cleanText = this.get('cleanText');
      let text;

      if (this.get('expanded') || cleanText.length < this.get('totalLength')) {
        if (this.get('renderHtml')) {
          text = this.get('text');
        } else {
          text = cleanText;
        }
      } else {
        text = cleanText.substring(0, this.get('length'));
      }

      return new Ember.String.htmlSafe(text);
    })
  });

  _exports.default = _default;
});