define("ilios-common/serializers/learning-material", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend({
    isNewSerializerAPI: true,

    serialize(snapshot, options) {
      var json = this._super(snapshot, options); //When POSTing new file learningMaterials we need to include the file hash


      const fileHash = Ember.get(snapshot.record, 'fileHash');

      if (fileHash) {
        json.fileHash = fileHash;
      } //don't persist this, it is handled by the server


      delete json.absoluteFileUri;
      delete json.uploadDate;
      return json;
    }

  });

  _exports.default = _default;
});