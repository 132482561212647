define("ilios-common/components/weekly-events", ["exports", "ilios-common/templates/components/weekly-events", "moment"], function (_exports, _weeklyEvents, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _weeklyEvents.default,
    classNames: ['weekly-events'],
    year: null,
    expandedWeeks: null,
    weeksInYear: Ember.computed('year', function () {
      const year = this.get('year');
      const weeksInTheYear = (0, _moment.default)().year(year).isoWeeksInYear();
      let weeks = [];

      for (let i = 1; i <= weeksInTheYear; i++) {
        weeks.push("".concat(i));
      }

      return weeks;
    }),
    actions: {
      incrementYear() {
        const year = this.get('year');
        this.get('setYear')(parseInt(year, 10) + 1);
      },

      decrementYear() {
        const year = this.get('year');
        this.get('setYear')(parseInt(year, 10) - 1);
      }

    }
  });

  _exports.default = _default;
});