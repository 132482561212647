define("ember-intl/macros/intl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = intl;
  _exports.__intlInjectionName = void 0;

  /**
   * @private
   * @hide
   */
  const __intlInjectionName = "intl-".concat(Date.now().toString(36));

  _exports.__intlInjectionName = __intlInjectionName;

  function intl(...dependentKeysAndGetterFn) {
    const getterFn = dependentKeysAndGetterFn.pop();
    const dependentKeys = dependentKeysAndGetterFn;
    return Ember.computed("".concat(__intlInjectionName, ".locale"), ...dependentKeys, function (propertyKey) {
      if (!Ember.get(this, __intlInjectionName)) {
        Ember.defineProperty(this, __intlInjectionName, {
          value: Ember.getOwner(this).lookup('service:intl'),
          enumerable: false
        });
      }

      const intl = Ember.get(this, __intlInjectionName);
      return getterFn.call(this, intl, propertyKey, this);
    }).readOnly();
  }
});