define("ilios-common/mixins/session/copy-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    permissionChecker: Ember.inject.service(),
    canUpdate: false,

    /**
     * Preload the school configurations
     * to avoid a pop in later
     */
    async afterModel(session) {
      const permissionChecker = this.get('permissionChecker');
      const course = await session.get('course');
      const school = await course.get('school');
      await school.get('configurations');
      const canUpdate = await permissionChecker.canUpdateSession(session);
      this.set('canUpdate', canUpdate);
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('canUpdate', this.get('canUpdate'));
    }

  });

  _exports.default = _default;
});