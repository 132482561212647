define("ilios-common/models/program-year", ["exports", "ember-data", "ilios-common/mixins/publishable-model", "ilios-common/mixins/categorizable-model", "ilios-common/mixins/sortable-by-position"], function (_exports, _emberData, _publishableModel, _categorizableModel, _sortableByPosition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo,
    hasMany,
    Model
  } = _emberData.default;
  const {
    alias
  } = Ember.computed;

  var _default = Model.extend(_publishableModel.default, _categorizableModel.default, _sortableByPosition.default, {
    startYear: attr('string'),
    locked: attr('boolean'),
    archived: attr('boolean'),
    program: belongsTo('program', {
      async: true
    }),
    cohort: belongsTo('cohort', {
      async: true
    }),
    directors: hasMany('user', {
      async: true
    }),
    competencies: hasMany('competency', {
      async: true
    }),
    objectives: hasMany('objective', {
      async: true
    }),
    stewards: hasMany('program-year-steward', {
      async: true
    }),
    assignableVocabularies: alias('program.school.vocabularies'),
    academicYear: Ember.computed('startYear', function () {
      return this.get('startYear') + ' - ' + (parseInt(this.get('startYear'), 10) + 1);
    }),
    classOfYear: Ember.computed('startYear', 'program.duration', async function () {
      const program = await this.get('program');
      return parseInt(this.startYear, 10) + parseInt(program.duration, 10);
    }),
    requiredPublicationIssues: Ember.computed('startYear', 'cohort', 'program', function () {
      return this.getRequiredPublicationIssues();
    }),
    optionalPublicationIssues: Ember.computed('directors.length', 'competencies.length', 'terms.length', 'objectives.length', function () {
      return this.getOptionalPublicationIssues();
    }),

    /**
     * A list of program-year objectives, sorted by position and title.
     * @property sortedObjectives
     * @type {Ember.computed}
     * @public
     */
    sortedObjectives: Ember.computed('objectives.@each.position', 'objectives.@each.title', async function () {
      const objectives = await this.get('objectives');
      return objectives.toArray().sort(this.positionSortingCallback);
    }),

    init() {
      this._super(...arguments);

      this.set('requiredPublicationSetFields', ['startYear', 'cohort', 'program']);
      this.set('optionalPublicationLengthFields', ['directors', 'competencies', 'terms', 'objectives']);
    }

  });

  _exports.default = _default;
});