define("ilios-common/serializers/ilm-session", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    RESTSerializer
  } = _emberData.default;

  var _default = RESTSerializer.extend({
    isNewSerializerAPI: true,

    serialize(snapshot, options) {
      let json = this._super(snapshot, options); // set time to 5pm, always.


      let dueDate = (0, _moment.default)(json.dueDate);
      dueDate.hour('17');
      dueDate.minute('00');
      json.dueDate = dueDate.format(); //don't persist this, it is handled by the server

      delete json.updatedAt;
      return json;
    }

  });

  _exports.default = _default;
});