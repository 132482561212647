define("ember-simple-charts/components/simple-chart-cluster", ["exports", "d3-transition", "ember-simple-charts/mixins/chart-properties", "d3-selection", "d3-hierarchy", "d3-scale-chromatic", "d3-scale"], function (_exports, _d3Transition, _chartProperties, _d3Selection, _d3Hierarchy, _d3ScaleChromatic, _d3Scale) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_chartProperties.default, {
    classNames: ['simple-chart-cluster'],

    draw(passedHeight, passedWidth) {
      const height = Math.min(passedHeight, passedWidth);
      const width = Math.min(passedHeight, passedWidth);
      const data = Ember.get(this, 'data');
      const dataOrEmptyObject = data ? data : {};
      const svg = (0, _d3Selection.select)(this.element);
      const radius = Math.min(5, height / 50);
      const isIcon = Ember.get(this, 'isIcon');
      const hover = Ember.get(this, 'hover');
      const leave = Ember.get(this, 'leave');
      const click = Ember.get(this, 'click');
      const isClickable = Ember.get(this, 'isClickable');
      const clusterLayout = (0, _d3Hierarchy.cluster)().size([height - 15, width - 15]);
      const root = (0, _d3Hierarchy.hierarchy)(dataOrEmptyObject);
      clusterLayout(root);
      const color = (0, _d3Scale.scaleSequential)(_d3ScaleChromatic.interpolateSinebow).domain([0, Math.max(root.height)]);
      svg.selectAll('.chart').remove();
      const chart = svg.append('g').attr('class', 'chart');
      chart.attr('transform', "translate(".concat(radius, ", ").concat(radius, ")"));
      chart.append('g').classed('links', true);
      chart.append('g').classed('nodes', true); // Links

      chart.select('.links').selectAll('line.link').data(root.links()).enter().append('line').classed('link', true).attr('stroke', d => color(d.source.depth)).attr('stroke-width', '1px').attr('x1', d => d.source.x).attr('y1', d => d.source.y).attr('x2', d => d.target.x).attr('y2', d => d.target.y); // Nodes

      const nodes = chart.select('.nodes').selectAll('circle.node').data(root.descendants()).enter().append('circle').classed('node', true).attr('fill', d => color(d.depth)).attr('cx', d => d.x).attr('cy', d => d.y).attr('r', radius);

      if (!isIcon) {
        nodes.on('mouseenter', ({
          data
        }) => {
          const elements = svg.selectAll('circle.node');
          const selectedElement = elements.filter(({
            data: nodeData
          }) => {
            return nodeData.name === data.name;
          });
          hover(data, selectedElement.node());
        });
        nodes.on('mouseleave', leave);

        if (isClickable) {
          nodes.on('click', ({
            data
          }) => {
            click(data);
          });
          nodes.style("cursor", "pointer");
        }
      }
    }

  });

  _exports.default = _default;
});