define("ilios-common/components/click-choice-buttons", ["exports", "ilios-common/templates/components/click-choice-buttons"], function (_exports, _clickChoiceButtons) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _clickChoiceButtons.default,
    classNames: ['click-choice-buttons'],
    firstChoicePicked: true,
    buttonContent1: null,
    buttonContent2: null,
    actions: {
      clickFirstButton() {
        const firstChoicePicked = this.get('firstChoicePicked');
        const toggle = this.get('toggle');

        if (!firstChoicePicked) {
          toggle(true);
        }
      },

      clickSecondButton() {
        const firstChoicePicked = this.get('firstChoicePicked');
        const toggle = this.get('toggle');

        if (firstChoicePicked) {
          toggle(false);
        }
      }

    }
  });

  _exports.default = _default;
});