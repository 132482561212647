define("ilios-common/templates/components/weekly-events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tJduJS3i",
    "block": "{\"symbols\":[\"week\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"year\"],[8],[0,\"\\n  \"],[7,\"h2\",true],[8],[0,\"\\n    \"],[5,\"fa-icon\",[],[[\"@icon\",\"@click\"],[\"backward\",[28,\"action\",[[23,0,[]],\"decrementYear\"],null]]]],[0,\"\\n    \"],[1,[22,\"year\"],false],[0,\"\\n    \"],[5,\"fa-icon\",[],[[\"@icon\",\"@click\"],[\"forward\",[28,\"action\",[[23,0,[]],\"incrementYear\"],null]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"weeksInYear\"]]],null,{\"statements\":[[0,\"  \"],[5,\"week-glance\",[],[[\"@collapsible\",\"@collapsed\",\"@year\",\"@week\",\"@toggleCollapsed\"],[true,[28,\"not\",[[28,\"contains\",[[23,1,[]],[24,[\"expandedWeeks\"]]],null]],null],[22,\"year\"],[23,1,[]],[28,\"action\",[[23,0,[]],[24,[\"toggleOpenWeek\"]],[23,1,[]]],null]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[7,\"div\",true],[10,\"class\",\"year\"],[8],[0,\"\\n  \"],[7,\"h2\",true],[8],[0,\"\\n    \"],[5,\"fa-icon\",[],[[\"@icon\",\"@click\"],[\"backward\",[28,\"action\",[[23,0,[]],\"decrementYear\"],null]]]],[0,\"\\n    \"],[1,[22,\"year\"],false],[0,\"\\n    \"],[5,\"fa-icon\",[],[[\"@icon\",\"@click\"],[\"forward\",[28,\"action\",[[23,0,[]],\"incrementYear\"],null]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/weekly-events.hbs"
    }
  });

  _exports.default = _default;
});