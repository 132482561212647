define("ilios-common/mixins/course/index-controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    queryParams: {
      sortSessionsBy: 'sortBy',
      filterSessionsBy: 'filterBy'
    },
    sortSessionsBy: 'title',
    filterSessionsBy: '',
    canCreateSession: false,
    canUpdateCourse: false
  });

  _exports.default = _default;
});