define("ilios-common/components/wait-saving", ["exports", "ilios-common/templates/components/wait-saving"], function (_exports, _waitSaving) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _waitSaving.default,
    showProgress: false,
    totalProgress: null,
    currentProgress: null,
    'data-test-wait-saving': true,
    progress: Ember.computed('totalProgress', 'currentProgress', function () {
      const total = this.get('totalProgress') || 1;
      const current = this.get('currentProgress') || 0;
      return Math.floor(current / total * 100);
    })
  });

  _exports.default = _default;
});