define("ilios-common/components/single-event-objective-list", ["exports", "ilios-common/templates/components/single-event-objective-list"], function (_exports, _singleEventObjectiveList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _singleEventObjectiveList.default,
    classNames: ['single-event-objective-list'],
    objectives: null,
    groupByCompetencies: true,

    /**
     * TRUE if the at least one of the given objectives has its sort priority set, otherwise FALSE.
     * @property showDisplayModeToggle
     * @type {Ember.computed}
     */
    showDisplayModeToggle: Ember.computed('objectives.[]', function () {
      let objectives = this.get('objectives');

      if (Ember.isEmpty(objectives)) {
        return false;
      }

      return !!objectives.reduce((prevValue, objective) => {
        return Math.max(prevValue, objective.position);
      }, 0);
    }),
    domains: Ember.computed('objectives.[]', function () {
      const objectives = this.get('objectives');

      if (Ember.isEmpty(objectives)) {
        return Ember.A();
      }

      let domainTitles = objectives.map(obj => {
        return obj.domain.toString();
      });
      domainTitles = Ember.A(domainTitles).uniq();
      let domains = domainTitles.map(title => {
        let domain = {
          title,
          objectives: []
        };
        let filteredObjectives = objectives.filter(obj => {
          return obj.domain.toString() === title;
        }).map(obj => {
          return obj.title;
        });
        domain.objectives = Ember.A(filteredObjectives).sortBy('title');
        return domain;
      });
      return Ember.A(domains).sortBy('title');
    })
  });

  _exports.default = _default;
});