define("ilios-common/components/selected-term-tree", ["exports", "ilios-common/templates/components/selected-term-tree"], function (_exports, _selectedTermTree) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _selectedTermTree.default,
    classNames: ['selected-term-tree'],
    tagName: 'ul'
  });

  _exports.default = _default;
});