define("ilios-common/components/objective-sort-manager", ["exports", "ilios-common/mixins/sortable-by-position", "ember-concurrency", "ilios-common/templates/components/objective-sort-manager"], function (_exports, _sortableByPosition, _emberConcurrency, _objectiveSortManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_sortableByPosition.default, {
    layout: _objectiveSortManager.default,
    classNames: ['objective-sort-manager'],
    sortableObjectList: null,
    subject: null,

    didReceiveAttrs() {
      this._super(...arguments);

      let subject = this.get('subject');
      this.get('loadAttr').perform(subject);
    },

    actions: {
      cancel() {
        this.cancel();
      },

      save() {
        this.save(this.get('sortableObjectList'));
      }

    },
    loadAttr: (0, _emberConcurrency.task)(function* (subject) {
      let objectives = yield subject.get('objectives');
      this.set('sortableObjectList', objectives.toArray().sort(this.get('positionSortingCallback')));
    })
  });

  _exports.default = _default;
});