define("ilios-common/components/course-materials", ["exports", "ember-concurrency", "ilios-common/templates/components/course-materials", "ilios-common/utils/query-utils"], function (_exports, _emberConcurrency, _courseMaterials, _queryUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEBOUNCE_DELAY = 250;

  var _default = Ember.Component.extend({
    layout: _courseMaterials.default,
    classNames: ['course-materials'],
    course: null,
    courseQuery: '',
    courseSort: null,
    sessionQuery: '',
    sessionSort: null,

    onCourseSort() {},

    onSessionSort() {},

    typesWithUrl: Object.freeze(['file', 'link']),
    courseLearningMaterialObjects: Ember.computed('course.learningMaterials.[]', async function () {
      const clms = await this.course.get('learningMaterials');
      const promises = clms.map(clm => this.buildClmObject(clm));
      return await (0, _emberConcurrency.all)(promises);
    }),
    sessionLearningMaterialObjects: Ember.computed('course.sessions.[]', async function () {
      const sessions = await this.course.sessions;
      const lms = await (0, _emberConcurrency.all)(sessions.mapBy('learningMaterials'));
      const slms = lms.reduce((flattened, obj) => {
        return flattened.pushObjects(obj.toArray());
      }, []);
      const promises = slms.map(slm => this.buildSlmObject(slm));
      return await (0, _emberConcurrency.all)(promises);
    }),
    filteredCourseLearningMaterialObjects: Ember.computed('courseLearningMaterialObjects.[]', 'courseQuery', async function () {
      const q = (0, _queryUtils.cleanQuery)(this.courseQuery);
      const clmo = await this.courseLearningMaterialObjects;
      return Ember.isEmpty(q) ? clmo : this.filterClmo(clmo);
    }),
    filteredSessionLearningMaterialObjects: Ember.computed('sessionLearningMaterialObjects.[]', 'sessionQuery', async function () {
      const q = (0, _queryUtils.cleanQuery)(this.sessionQuery);
      const slmo = await this.sessionLearningMaterialObjects;
      return Ember.isEmpty(q) ? slmo : this.filterSlmo(slmo);
    }),
    clmSortedAscending: Ember.computed('courseSort', function () {
      return this.courseSort.search(/desc/) === -1;
    }),
    slmSortedAscending: Ember.computed('sessionSort', function () {
      return this.sessionSort.search(/desc/) === -1;
    }),
    actions: {
      courseSortBy(prop) {
        if (this.courseSort === prop) {
          prop += ':desc';
        }

        this.onCourseSort(prop);
      },

      sessionSortBy(prop) {
        if (this.sessionSort === prop) {
          prop += ':desc';
        }

        this.onSessionSort(prop);
      }

    },
    setCourseQuery: (0, _emberConcurrency.task)(function* (q) {
      yield (0, _emberConcurrency.timeout)(DEBOUNCE_DELAY);
      this.set('courseQuery', q);
    }).restartable(),
    setSessionQuery: (0, _emberConcurrency.task)(function* (q) {
      yield (0, _emberConcurrency.timeout)(DEBOUNCE_DELAY);
      this.set('sessionQuery', q);
    }).restartable(),

    async buildClmObject(clm) {
      const lm = await clm.get('learningMaterial');
      return Ember.Object.create({
        author: lm.originalAuthor,
        citation: lm.citation,
        description: lm.description,
        title: lm.title,
        type: lm.type,
        url: lm.url
      });
    },

    async buildSlmObject(slm) {
      const lm = await slm.get('learningMaterial');
      const session = await slm.session;
      const firstOfferingDate = await session.firstOfferingDate;
      return Ember.Object.create({
        author: lm.originalAuthor,
        citation: lm.citation,
        description: lm.description,
        firstOfferingDate,
        sessionTitle: session.title,
        title: lm.title,
        type: lm.type,
        url: lm.url
      });
    },

    filterClmo(clmo) {
      const exp = new RegExp(this.courseQuery, 'gi');
      return clmo.filter(obj => {
        return Ember.isPresent(obj.title) && obj.title.match(exp) || Ember.isPresent(obj.description) && obj.description.match(exp) || Ember.isPresent(obj.author) && obj.author.match(exp) || Ember.isPresent(obj.type) && obj.type.match(exp) || Ember.isPresent(obj.citation) && obj.citation.match(exp);
      });
    },

    filterSlmo(slmo) {
      const exp = new RegExp(this.sessionQuery, 'gi');
      return slmo.filter(obj => {
        return Ember.isPresent(obj.title) && obj.title.match(exp) || Ember.isPresent(obj.description) && obj.description.match(exp) || Ember.isPresent(obj.author) && obj.author.match(exp) || Ember.isPresent(obj.type) && obj.type.match(exp) || Ember.isPresent(obj.citation) && obj.citation.match(exp) || Ember.isPresent(obj.sessionTitle) && obj.sessionTitle.match(exp);
      });
    }

  });

  _exports.default = _default;
});