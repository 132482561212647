define("ilios-common/templates/components/action-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QO+FkDmm",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"button\",false],[12,\"class\",\"button rl-dropdown-toggle\"],[3,\"action\",[[23,0,[]],\"toggleDropdown\"]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"icon\"]]],null,{\"statements\":[[0,\"    \"],[5,\"fa-icon\",[],[[\"@icon\"],[[22,\"icon\"]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"span\",true],[8],[0,\"\\n    \"],[1,[22,\"title\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"ul\",false],[12,\"class\",\"rl-dropdown dropdown-menu\"],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],\"toggleDropdown\"]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"dropdownExpanded\"]]],null,{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/action-menu.hbs"
    }
  });

  _exports.default = _default;
});