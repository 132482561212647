define("ilios-common/components/detail-instructors", ["exports", "ilios-common/templates/components/detail-instructors"], function (_exports, _detailInstructors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    layout: _detailInstructors.default,
    tagName: 'section',
    classNames: ['detail-instructors'],
    ilmSession: null,
    isManaging: false,
    instructorGroupBuffer: null,
    instructorBuffer: null,
    'data-test-detail-instructors': true,
    titleCount: Ember.computed('ilmSession.instructorGroups.length', 'ilmSession.instructors.length', function () {
      return this.get('ilmSession.instructorGroups.length') + this.get('ilmSession.instructors.length');
    }),
    availableInstructorGroups: Ember.computed('currentUser.model', async function () {
      const model = await this.currentUser.model;
      const school = await model.get('school');
      return await school.instructorGroups;
    }),

    init() {
      this._super(...arguments);

      this.set('instructorGroupBuffer', []);
      this.set('instructorBuffer', []);
    },

    actions: {
      manage() {
        let promises = [];
        promises.pushObject(this.get('ilmSession.instructorGroups').then(instructorGroups => {
          this.set('instructorGroupBuffer', instructorGroups.toArray());
        }));
        promises.pushObject(this.get('ilmSession.instructors').then(instructors => {
          this.set('instructorBuffer', instructors.toArray());
        }));
        Ember.RSVP.all(promises).then(() => {
          this.set('isManaging', true);
        });
      },

      save() {
        var ilmSession = this.get('ilmSession');
        let instructorGroups = ilmSession.get('instructorGroups');
        let removableInstructorGroups = instructorGroups.filter(group => !this.get('instructorGroupBuffer').includes(group));
        instructorGroups.clear();
        removableInstructorGroups.forEach(group => {
          group.get('ilmSessions').then(ilmSessions => {
            ilmSessions.removeObject(ilmSession);
          });
        });
        this.get('instructorGroupBuffer').forEach(function (group) {
          instructorGroups.pushObject(group);
          group.get('ilmSessions').then(ilmSessions => {
            ilmSessions.pushObject(ilmSession);
          });
        });
        let instructors = ilmSession.get('instructors');
        let removableInstructors = instructors.filter(user => !this.get('instructorBuffer').includes(user));
        instructors.clear();
        removableInstructors.forEach(user => {
          user.get('instructorIlmSessions').then(ilmSessions => {
            ilmSessions.removeObject(ilmSession);
          });
        });
        this.get('instructorBuffer').forEach(function (user) {
          instructors.pushObject(user);
          user.get('instructorIlmSessions').then(ilmSessions => {
            ilmSessions.pushObject(ilmSession);
          });
        });
        ilmSession.save().then(() => {
          this.set('isManaging', false);
        });
      },

      cancel() {
        this.set('instructorGroupBuffer', []);
        this.set('instructorBuffer', []);
        this.set('isManaging', false);
      },

      addInstructorGroupToBuffer(instructorGroup) {
        this.get('instructorGroupBuffer').pushObject(instructorGroup);
      },

      addInstructorToBuffer(instructor) {
        this.get('instructorBuffer').pushObject(instructor);
      },

      removeInstructorGroupFromBuffer(instructorGroup) {
        this.get('instructorGroupBuffer').removeObject(instructorGroup);
      },

      removeInstructorFromBuffer(instructor) {
        this.get('instructorBuffer').removeObject(instructor);
      }

    }
  });

  _exports.default = _default;
});