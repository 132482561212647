define("ilios-common/templates/components/visualizer-course-term", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dgMEqQS1",
    "block": "{\"symbols\":[\"chart\"],\"statements\":[[4,\"if\",[[28,\"or\",[true,[24,[\"isIcon\"]],[28,\"gt\",[[28,\"get\",[[28,\"await\",[[24,[\"data\"]]],null],\"length\"],null],0],null]],null]],null,{\"statements\":[[0,\"  \"],[5,\"simple-chart\",[],[[\"@name\",\"@isIcon\",\"@data\",\"@hover\",\"@leave\"],[\"horz-bar\",[22,\"isIcon\"],[28,\"await\",[[24,[\"data\"]]],null],[28,\"perform\",[[24,[\"barHover\"]]],null],[28,\"perform\",[[24,[\"barHover\"]]],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"tooltipContent\"]]],null,{\"statements\":[[0,\"      \"],[6,[23,1,[\"tooltip\"]],[],[[\"@title\"],[[22,\"tooltipTitle\"]]],{\"statements\":[[0,\"\\n        \"],[1,[22,\"tooltipContent\"],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/visualizer-course-term.hbs"
    }
  });

  _exports.default = _default;
});