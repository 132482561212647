define("ilios-common/models/ilm-session", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo,
    hasMany,
    Model
  } = _emberData.default;

  var _default = Model.extend({
    hours: attr('number'),
    dueDate: attr('date'),
    session: belongsTo('session', {
      async: true
    }),
    learnerGroups: hasMany('learner-group', {
      async: true
    }),
    instructorGroups: hasMany('instructor-group', {
      async: true
    }),
    instructors: hasMany('user', {
      async: true,
      inverse: 'instructorIlmSessions'
    }),
    learners: hasMany('user', {
      async: true,
      inverse: 'learnerIlmSessions'
    })
  });

  _exports.default = _default;
});