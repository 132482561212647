define("ilios-common/services/ilios-config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    commonAjax: Ember.inject.service(),
    serverVariables: Ember.inject.service(),
    config: Ember.computed('apiHost', function () {
      const apiHost = this.get('apiHost');
      const url = apiHost + '/application/config';
      const commonAjax = this.get('commonAjax');
      return commonAjax.request(url);
    }),

    itemFromConfig(key) {
      return this.get('config').then(config => {
        const obj = config.config;
        return key in obj ? obj[key] : null;
      });
    },

    userSearchType: Ember.computed('config.userSearchType', function () {
      return this.itemFromConfig('userSearchType');
    }),
    authenticationType: Ember.computed('config.type', function () {
      return this.itemFromConfig('type');
    }),
    maxUploadSize: Ember.computed('config.maxUploadSize', function () {
      return this.itemFromConfig('maxUploadSize');
    }),
    apiVersion: Ember.computed('config.apiVersion', function () {
      return this.itemFromConfig('apiVersion');
    }),
    trackingEnabled: Ember.computed('config.trackingEnabled', function () {
      return this.itemFromConfig('trackingEnabled');
    }),
    trackingCode: Ember.computed('config.trackingCode', function () {
      return this.itemFromConfig('trackingCode');
    }),
    loginUrl: Ember.computed('config.loginUrl', function () {
      return this.itemFromConfig('loginUrl');
    }),
    casLoginUrl: Ember.computed('config.casLoginUrl', function () {
      return this.itemFromConfig('casLoginUrl');
    }),
    apiNameSpace: Ember.computed('serverVariables.apiNameSpace', function () {
      const serverVariables = this.get('serverVariables');
      const apiNameSpace = serverVariables.get('apiNameSpace');

      if (Ember.isPresent(apiNameSpace)) {
        //remove trailing slashes
        return apiNameSpace.replace(/\/+$/, "");
      }

      return '';
    }),
    apiHost: Ember.computed('serverVariables.apiHost', function () {
      const serverVariables = this.get('serverVariables');
      const apiHost = serverVariables.get('apiHost');

      if (Ember.isPresent(apiHost)) {
        //remove trailing slashes
        return apiHost.replace(/\/+$/, "");
      }

      return '';
    }),
    errorCaptureEnabled: Ember.computed('serverVariables.errorCaptureEnabled', function () {
      const serverVariables = this.get('serverVariables');
      const errorCaptureEnabled = serverVariables.get('errorCaptureEnabled');

      if (typeof errorCaptureEnabled === 'boolean') {
        return errorCaptureEnabled;
      }

      return errorCaptureEnabled === 'true';
    }),
    searchEnabled: Ember.computed('config.searchEnabled', async function () {
      const searchEnabled = await this.itemFromConfig('searchEnabled');

      if (searchEnabled === null) {
        return false;
      }

      if (typeof searchEnabled === 'boolean') {
        return searchEnabled;
      }

      return searchEnabled === 'true';
    })
  });

  _exports.default = _default;
});