define("ilios-common/templates/components/learnergroup-tree", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vlMMMcRQ",
    "block": "{\"symbols\":[\"child\"],\"statements\":[[7,\"span\",true],[11,\"class\",[28,\"if\",[[24,[\"selectable\"]],\"clickable\"],null]],[11,\"onclick\",[28,\"if\",[[24,[\"selectable\"]],[28,\"action\",[[23,0,[]],[24,[\"add\"]],[24,[\"learnerGroup\"]]],null]],null]],[11,\"role\",[28,\"if\",[[24,[\"selectable\"]],\"button\"],null]],[8],[0,\"\\n  \"],[1,[24,[\"learnerGroup\",\"title\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"learnerGroup\",\"children\",\"length\"]]],null,{\"statements\":[[0,\"  \"],[7,\"ul\",true],[8],[0,\"\\n\"],[4,\"if\",[[28,\"is-fulfilled\",[[24,[\"learnerGroup\",\"children\"]]],null]],null,{\"statements\":[[4,\"each\",[[28,\"sort-by\",[\"title\",[28,\"await\",[[24,[\"learnerGroup\",\"children\"]]],null]],null]],null,{\"statements\":[[0,\"        \"],[5,\"learnergroup-tree\",[],[[\"@learnerGroup\",\"@selectedGroups\",\"@filter\",\"@add\"],[[23,1,[]],[22,\"selectedGroups\"],[22,\"filter\"],[28,\"action\",[[23,0,[]],[24,[\"add\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"li\",true],[8],[0,\"\\n        \"],[5,\"loading-spinner\",[],[[],[]]],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/learnergroup-tree.hbs"
    }
  });

  _exports.default = _default;
});