define("ilios-common/mixins/course-visualize-term-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    titleToken: 'general.coursesAndSessions',

    async model(params) {
      const store = this.get('store');
      const course = await store.find('course', params.course_id);
      const term = await store.find('term', params.term_id);
      return {
        course,
        term
      };
    },

    async afterModel(model) {
      const {
        course,
        term
      } = model;
      const sessions = await course.get('sessions');
      return await Ember.RSVP.all([term.get('vocabulary'), Ember.RSVP.map(sessions.toArray(), s => s.get('sessionType')), Ember.RSVP.map(sessions.toArray(), s => s.get('totalSumDuration'))]);
    }

  });

  _exports.default = _default;
});