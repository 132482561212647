define("ilios-common/components/sortable-heading", ["exports", "ilios-common/templates/components/sortable-heading"], function (_exports, _sortableHeading) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _sortableHeading.default,
    sortedBy: false,
    sortedAscending: true,
    align: 'left',
    sortType: 'alpha',
    classNameBindings: ['textDirection', ':sortable', ':clickable', ':sortable-heading', 'hideFromSmallScreen'],
    tagName: 'span',
    hideFromSmallScreen: false,
    attributeBindings: ['title'],
    title: '',
    sortIcon: Ember.computed('sortedBy', 'sortedAscending', 'sortType', function () {
      const sortedBy = this.get('sortedBy');
      const sortedAscending = this.get('sortedAscending');
      const sortType = this.get('sortType');

      if (sortedBy) {
        if (sortedAscending) {
          return sortType === 'numeric' ? 'sort-numeric-up' : 'sort-alpha-up';
        } else {
          return sortType === 'numeric' ? 'sort-numeric-down' : 'sort-alpha-down';
        }
      } else {
        return 'sort';
      }
    }),
    textDirection: Ember.computed('align', function () {
      return 'text-' + this.get('align');
    })
  });

  _exports.default = _default;
});