define("ember-cli-string-helpers/utils/lowercase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = lowercase;

  function lowercase(string = '') {
    if (typeof string !== 'string') {
      throw new TypeError("Expected a string, got a ".concat(typeof string));
    }

    return string.toLowerCase();
  }
});