define("ilios-common/templates/weeklyevents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eo+0CF2O",
    "block": "{\"symbols\":[],\"statements\":[[5,\"back-link\",[],[[],[]]],[0,\"\\n\"],[5,\"weekly-events\",[],[[\"@year\",\"@expandedWeeks\",\"@setYear\",\"@toggleOpenWeek\"],[[22,\"year\"],[22,\"expandedWeeks\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"year\"]]],null]],null],[28,\"action\",[[23,0,[]],\"toggleOpenWeek\"],null]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/weeklyevents.hbs"
    }
  });

  _exports.default = _default;
});