define("ilios-common/models/course-learning-material", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo,
    hasMany,
    Model
  } = _emberData.default;

  var _default = Model.extend({
    notes: attr('string'),
    required: attr('boolean', {
      defaultValue: true
    }),
    publicNotes: attr('boolean', {
      defaultValue: true
    }),
    position: attr('number', {
      defaultValue: 0
    }),
    startDate: attr('date'),
    endDate: attr('date'),
    course: belongsTo('course', {
      async: true
    }),
    learningMaterial: belongsTo('learning-material', {
      async: true
    }),
    meshDescriptors: hasMany('mesh-descriptors', {
      async: true
    })
  });

  _exports.default = _default;
});