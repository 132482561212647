define("ilios-common/components/ilios-calendar-event-month", ["exports", "elemental-calendar/components/calendar-event", "ilios-common/templates/components/ilios-calendar-event-month", "moment", "ilios-common/mixins/tooltip-content", "ilios-common/utils/color-change"], function (_exports, _calendarEvent, _iliosCalendarEventMonth, _moment, _tooltipContent, _colorChange) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Handlebars
  } = Ember;
  const {
    notEmpty,
    or
  } = Ember.computed;
  const {
    Utils
  } = Handlebars;
  const {
    escapeExpression
  } = Utils;

  var _default = _calendarEvent.default.extend(_tooltipContent.default, {
    layout: _iliosCalendarEventMonth.default,
    event: null,
    timeFormat: 'h:mma',
    classNameBindings: [':event', ':event-pos', ':ilios-calendar-event', ':month-event', 'clickable:clickable'],
    daysToShowAlert: null,
    isIlm: notEmpty('event.ilmSession'),
    isOffering: notEmpty('event.offering'),
    clickable: or('isIlm', 'isOffering'),
    style: Ember.computed(function () {
      const event = this.get('event');

      if (event == null) {
        return new Ember.String.htmlSafe('');
      }

      const darkcolor = (0, _colorChange.default)(event.color, -0.15);
      return new Ember.String.htmlSafe("background-color: ".concat(escapeExpression(event.color), ";\n       border-left: 4px solid ").concat(escapeExpression(darkcolor), ";"));
    }),
    recentlyUpdated: Ember.computed('event.lastModified', {
      get() {
        const lastModifiedDate = (0, _moment.default)(this.get('event.lastModified'));
        const today = (0, _moment.default)();
        const daysSinceLastUpdate = today.diff(lastModifiedDate, 'days');
        return daysSinceLastUpdate < 6 ? true : false;
      }

    }).readOnly(),

    click() {
      if (this.get('clickable')) {
        this.get('selectEvent')();
      }
    }

  });

  _exports.default = _default;
});