define("ilios-common/components/detail-cohort-list", ["exports", "ilios-common/templates/components/detail-cohort-list"], function (_exports, _detailCohortList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    map
  } = Ember.RSVP;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    layout: _detailCohortList.default,
    classNames: ['detail-cohort-list'],
    cohorts: null,

    /**
     * A list of cohorts, sorted by school and display title.
     * @property sortedCohorts
     * @type {Ember.computed}
     * @public
     */
    sortedCohorts: Ember.computed('cohorts.[]', async function () {
      const cohorts = this.get('cohorts');

      if (Ember.isEmpty(cohorts)) {
        return [];
      }

      const sortProxies = await map(cohorts.toArray(), async cohort => {
        const school = await cohort.get('school');
        const schoolTitle = school.get('title');
        let displayTitle = cohort.get('title');

        if (Ember.isEmpty(displayTitle)) {
          const intl = this.get('intl');
          const classOfYear = await cohort.get('classOfYear');
          displayTitle = intl.t('general.classOf', {
            year: classOfYear
          });
        }

        return Ember.Object.create({
          cohort,
          schoolTitle,
          displayTitle
        });
      });
      return sortProxies.sortBy('schoolTitle', 'displayTitle').mapBy('cohort');
    })
  });

  _exports.default = _default;
});