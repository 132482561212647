define("ember-simple-charts/components/simple-chart-tooltip", ["exports", "ember-simple-charts/templates/components/simple-chart-tooltip"], function (_exports, _simpleChartTooltip) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _simpleChartTooltip.default
  });

  _exports.default = _default;
});