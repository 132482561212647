define("ilios-common/components/detail-instructors-list", ["exports", "ilios-common/templates/components/detail-instructors-list"], function (_exports, _detailInstructorsList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    sort
  } = Ember.computed;

  var _default = Ember.Component.extend({
    layout: _detailInstructorsList.default,
    classNames: ['detail-instructors-list'],
    instructors: null,
    sortInstructorsBy: null,
    instructorGroups: null,
    sortGroupsBy: null,
    sortedInstructors: sort('instructors', 'sortInstructorsBy'),
    sortedInstructorGroups: sort('instructorGroups', 'sortGroupsBy'),

    init() {
      this._super(...arguments);

      this.set('sortInstructorsBy', ['title']);
      this.set('sortGroupsBy', ['title']);
    }

  });

  _exports.default = _default;
});