define("ilios-common/components/loading-spinner", ["exports", "ilios-common/templates/components/loading-spinner"], function (_exports, _loadingSpinner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _loadingSpinner.default,
    tagName: 'span',
    classNames: ['loading-spinner']
  });

  _exports.default = _default;
});