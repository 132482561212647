define("ilios-common/components/common-dashboard", ["exports", "ilios-common/templates/components/common-dashboard"], function (_exports, _commonDashboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _commonDashboard.default,
    classNames: ['common-dashboard'],
    tagName: 'section',
    show: 'week',
    selectedDate: null,
    selectedView: 'week',
    mySchedule: true,
    showFilters: false,
    selectedAcademicYear: null,
    selectedSchool: null,
    courseFilters: null,
    selectedCohorts: null,
    selectedCourseLevels: null,
    selectedCourses: null,
    selectedSessionTypes: null,
    selectedTerms: null,

    onClearFilters() {},

    onUpdateCohorts() {},

    onUpdateCourseLevels() {},

    onUpdateCourses() {},

    onUpdateSessionTypes() {},

    onUpdateTerms() {}

  });

  _exports.default = _default;
});