define("ilios-common/components/visualizer-course-instructors", ["exports", "ember-concurrency", "ilios-common/templates/components/visualizer-course-instructors"], function (_exports, _emberConcurrency, _visualizerCourseInstructors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    layout: _visualizerCourseInstructors.default,
    course: null,
    isIcon: false,
    chartType: 'horz-bar',
    classNameBindings: ['isIcon::not-icon', ':visualizer-course-instructors'],
    tooltipContent: null,
    tooltipTitle: null,
    filter: '',
    data: Ember.computed('course.sessions.@each.{offerings,instructors,instructorGroups,ilmSessions}', async function () {
      const course = this.get('course');
      const sessions = await course.get('sessions');
      const dataMap = await Ember.RSVP.map(sessions.toArray(), async session => {
        const instructors = await session.get('allInstructors');
        const hours = await session.get('maxSingleOfferingDuration');
        const minutes = Math.round(hours * 60);
        return {
          sessionTitle: session.get('title'),
          instructors,
          minutes
        };
      });
      const instructorData = dataMap.reduce((set, obj) => {
        obj.instructors.forEach(instructor => {
          const name = instructor.get('fullName');
          const id = instructor.get('id');
          let existing = set.findBy('label', name);

          if (!existing) {
            existing = {
              data: 0,
              label: name,
              meta: {
                userId: id,
                sessions: []
              }
            };
            set.pushObject(existing);
          }

          existing.data += obj.minutes;
          existing.meta.sessions.pushObject(obj.sessionTitle);
        });
        return set;
      }, []);
      const totalMinutes = instructorData.mapBy('data').reduce((total, minutes) => total + minutes, 0);
      const mappedInstructorsWithLabel = instructorData.map(obj => {
        const percent = (obj.data / totalMinutes * 100).toFixed(1);
        obj.label = "".concat(obj.label, " ").concat(percent, "%");
        obj.meta.totalMinutes = totalMinutes;
        obj.meta.percent = percent;
        return obj;
      });
      return mappedInstructorsWithLabel;
    }),
    filteredData: Ember.computed('data.[]', 'filter', async function () {
      const data = await this.get('data');
      const filter = this.get('filter');

      if (!filter) {
        return data;
      }

      let exp = new RegExp(filter, 'gi');
      return data.filter(({
        label
      }) => label.match(exp));
    }),
    sortedData: Ember.computed('filteredData.[]', async function () {
      const data = await this.get('filteredData');
      data.sort((first, second) => {
        return first.data - second.data;
      });
      return data;
    }),
    actions: {
      barClick(obj) {
        const course = this.get('course');
        const isIcon = this.get('isIcon');
        const router = this.get('router');

        if (isIcon || Ember.isEmpty(obj) || obj.empty || Ember.isEmpty(obj.meta)) {
          return;
        }

        router.transitionTo('course-visualize-instructor', course.get('id'), obj.meta.userId);
      }

    },
    barHover: (0, _emberConcurrency.task)(function* (obj) {
      yield (0, _emberConcurrency.timeout)(100);
      const intl = this.get('intl');
      const isIcon = this.get('isIcon');

      if (isIcon || Ember.isEmpty(obj) || obj.empty) {
        this.set('tooltipTitle', null);
        this.set('tooltipContent', null);
        return;
      }

      const {
        label,
        data,
        meta
      } = obj;
      const title = Ember.String.htmlSafe("".concat(label, " ").concat(data, " ").concat(intl.t('general.minutes')));
      const sessions = meta.sessions.uniq().sort().join();
      this.set('tooltipTitle', title);
      this.set('tooltipContent', Ember.String.htmlSafe(sessions + '<br /><br />' + intl.t('general.clickForMore')));
    }).restartable()
  });

  _exports.default = _default;
});