define("ilios-common/components/session-overview", ["exports", "moment", "ilios-common/mixins/publishable", "ember-cp-validations", "ilios-common/mixins/validation-error-display", "ember-concurrency", "ilios-common/templates/components/session-overview"], function (_exports, _moment, _publishable, _emberCpValidations, _validationErrorDisplay, _emberConcurrency, _sessionOverview) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    oneWay,
    sort
  } = Ember.computed;
  const Validations = (0, _emberCpValidations.buildValidations)({
    title: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 3,
      max: 200
    })],
    hours: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      positive: true
    })],
    dueDate: [(0, _emberCpValidations.validator)('presence', true)],
    description: [(0, _emberCpValidations.validator)('length', {
      min: 3,
      max: 65000
    })],
    instructionalNotes: [(0, _emberCpValidations.validator)('length', {
      min: 3,
      max: 65000
    })]
  });

  var _default = Ember.Component.extend(_publishable.default, Validations, _validationErrorDisplay.default, {
    currentUser: Ember.inject.service(),
    features: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    permissionChecker: Ember.inject.service(),
    intl: Ember.inject.service(),
    layout: _sessionOverview.default,
    session: null,
    title: null,
    instructionalNotes: null,
    hours: null,
    dueDate: null,
    description: null,
    editable: true,
    sortTypes: null,
    sessionTypes: null,
    sessionType: null,
    postRequisite: null,
    showCheckLink: true,
    isSaving: false,
    'data-test-session-overview': true,
    updatedAt: null,
    publishTarget: oneWay('session'),
    sortedSessionTypes: sort('filteredSessionTypes', 'sortTypes'),
    filteredSessionTypes: Ember.computed('sessionTypes.[]', function () {
      const selectedSessionType = this.get('sessionType');
      const selectedSessionTypeId = Ember.isEmpty(selectedSessionType) ? -1 : selectedSessionType.get('id');
      return this.get('sessionTypes').filter(sessionType => {
        return sessionType.get('active') || sessionType.get('id') === selectedSessionTypeId;
      });
    }),

    /**
     * A list of sessions in this session's course that can be linked to this session,
     * excluding this session itself.
     * @propery linkablePostrequisites
     * @type {Ember.computed}
     */
    linkablePostrequisites: Ember.computed('session.course.sessions.[]', async function () {
      const session = this.get('session');
      const course = await session.get('course');
      const sessions = await course.get('sessions');
      return sessions.sortBy("title").toArray().filter(sessionInCourse => {
        return sessionInCourse.get('id') !== session.get('id');
      });
    }),

    /**
     * This session's post-requisite title. If there is none then the an i18n text string indicating as such is returned.
     * @property postRequisiteTitle
     * @type {Ember.computed}
     */
    postRequisiteTitle: Ember.computed('session.postrequisite', async function () {
      const intl = this.get('intl');
      const session = this.get('session');
      const postRequisite = await session.get('postrequisite');

      if (postRequisite) {
        return postRequisite.get('title');
      } else {
        return intl.t('general.none');
      }
    }),

    /**
     * Check if a user is allowed to create a session anywhere
     * Try and do this by loading as little data as possible, but in the
     * end we do need to check every course in the school.
     */
    showCopy: Ember.computed('currentUser', 'routing.currentRouteName', async function () {
      const currentUser = this.get('currentUser');
      const permissionChecker = this.get('permissionChecker');
      const routing = this.get('routing');

      if (routing.get('currentRouteName') === 'session.copy') {
        return false;
      }

      const session = this.get('session');
      const course = await session.get('course');

      if (await permissionChecker.canCreateSession(course)) {
        return true;
      }

      const user = await currentUser.get('model');
      const allRelatedCourses = await user.get('allRelatedCourses');
      let relatedCourse;

      for (relatedCourse of allRelatedCourses) {
        if (await permissionChecker.canCreateSession(relatedCourse)) {
          return true;
        }
      }

      const school = await course.get('school');
      const schoolCourses = school.get('courses');
      let schoolCourse;

      for (schoolCourse of schoolCourses) {
        if (await permissionChecker.canCreateSession(schoolCourse)) {
          return true;
        }
      }

      return false;
    }),
    school: Ember.computed('session.course.school', async function () {
      const session = this.get('session');
      const course = await session.get('course');
      return await course.get('school');
    }),
    showAttendanceRequired: Ember.computed('school.configurations.[]', async function () {
      const school = await this.get('school');
      return await school.getConfigValue('showSessionAttendanceRequired');
    }),
    showSupplemental: Ember.computed('school.configurations.[]', async function () {
      const school = await this.get('school');
      return await school.getConfigValue('showSessionSupplemental');
    }),
    showSpecialAttireRequired: Ember.computed('school.configurations.[]', async function () {
      const school = await this.get('school');
      return await school.getConfigValue('showSessionSpecialAttireRequired');
    }),
    showSpecialEquipmentRequired: Ember.computed('school.configurations.[]', async function () {
      const school = await this.get('school');
      return await school.getConfigValue('showSessionSpecialEquipmentRequired');
    }),

    init() {
      this._super(...arguments);

      this.set('sortTypes', ['title']);
    },

    didReceiveAttrs() {
      this._super(...arguments);

      this.set('title', this.get('session.title'));
      this.set('instructionalNotes', this.get('session.instructionalNotes'));
      this.get('session.ilmSession').then(ilmSession => {
        if (ilmSession) {
          this.set('hours', ilmSession.get('hours'));
          this.set('dueDate', ilmSession.get('dueDate'));
        }
      });
      this.set('updatedAt', (0, _moment.default)(this.get('session.updatedAt')).format("L LT"));
      this.get('session.sessionType').then(sessionType => {
        this.set('sessionType', sessionType);
      });
      this.get('session.postrequisite').then(postRequisite => {
        this.set('postRequisite', postRequisite);
      });
      this.get('session.sessionDescription').then(sessionDescription => {
        if (sessionDescription) {
          this.set('description', sessionDescription.get('description'));
        }
      });
    },

    actions: {
      saveIndependentLearning(value) {
        var session = this.get('session');

        if (!value) {
          session.get('ilmSession').then(function (ilmSession) {
            session.set('ilmSession', null);
            ilmSession.deleteRecord();
            session.save();
            ilmSession.save();
          });
        } else {
          const hours = 1;
          const dueDate = (0, _moment.default)().add(6, 'weeks').toDate();
          this.set('hours', hours);
          var ilmSession = this.get('store').createRecord('ilm-session', {
            session,
            hours,
            dueDate
          });
          ilmSession.save().then(function (savedIlmSession) {
            session.set('ilmSession', savedIlmSession);
            session.save();
          });
        }
      },

      async setPostrequisite(id) {
        if (!id) {
          this.set('postRequisite', null);
          return;
        }

        const session = this.get('session');
        const course = await session.get('course');
        const sessions = await course.get('sessions');
        const postRequisite = sessions.findBy('id', id);
        this.set('postRequisite', postRequisite);
      },

      async changePostrequisite() {
        const session = this.get('session');
        const postRequisite = this.get('postRequisite');
        session.set('postrequisite', postRequisite);
        await session.save();
      },

      async revertPostrequisiteChanges() {
        const session = this.get('session');
        const postRequisite = await session.get('postRequisite');
        this.set('postRequisite', postRequisite);
      },

      async changeTitle() {
        const {
          session,
          title
        } = this.getProperties('session', 'title');
        this.send('addErrorDisplayFor', 'title');
        const {
          validations
        } = await this.validate({
          on: ['title']
        });

        if (validations.isValid) {
          this.send('removeErrorDisplayFor', 'title');
          session.set('title', title);
          const newSession = await session.save();
          this.set('title', newSession.title);
          this.set('session', newSession);
        } else {
          await Ember.RSVP.reject();
        }
      },

      revertTitleChanges() {
        const session = this.get('session');
        this.set('title', session.get('title'));
      },

      revertInstructionalNotesChanges() {
        this.set('instructionalNotes', this.session.instructionalNotes);
      },

      setSessionType(id) {
        let type = this.get('sessionTypes').findBy('id', id);
        this.set('sessionType', type);
      },

      changeSessionType() {
        let session = this.get('session');
        let type = this.get('sessionType');
        session.set('sessionType', type);
        session.save();
      },

      revertSessionTypeChanges() {
        this.get('session').get('sessionType').then(sessionType => {
          this.set('sessionType', sessionType);
        });
      },

      changeSupplemental(value) {
        this.get('session').set('supplemental', value);
        this.get('session').save();
      },

      changeSpecialEquipment(value) {
        this.get('session').set('equipmentRequired', value);
        this.get('session').save();
      },

      changeSpecialAttire(value) {
        this.get('session').set('attireRequired', value);
        this.get('session').save();
      },

      changeAttendanceRequired(value) {
        this.get('session').set('attendanceRequired', value);
        this.get('session').save();
      },

      async changeIlmHours() {
        const {
          hours,
          session
        } = this.getProperties('hours', 'session');
        this.send('addErrorDisplayFor', 'hours');
        const {
          validations
        } = await this.validate({
          on: ['hours']
        });

        if (validations.isValid) {
          this.send('removeErrorDisplayFor', 'hours');
          const ilmSession = await session.get('ilmSession');

          if (ilmSession) {
            ilmSession.set('hours', hours);
            await ilmSession.save();
          } else {
            await Ember.RSVP.reject();
          }
        } else {
          await Ember.RSVP.reject();
        }
      },

      revertIlmHoursChanges() {
        this.get('session').get('ilmSession').then(ilmSession => {
          if (ilmSession) {
            this.set('hours', ilmSession.get('hours'));
          }
        });
      },

      async changeIlmDueDate() {
        const {
          dueDate,
          session
        } = this.getProperties('dueDate', 'session');
        this.send('addErrorDisplayFor', 'dueDate');
        const {
          validations
        } = await this.validate({
          on: ['dueDate']
        });

        if (validations.isValid) {
          this.send('removeErrorDisplayFor', 'dueDate');
          const ilmSession = await session.get('ilmSession');

          if (ilmSession) {
            ilmSession.set('dueDate', dueDate);
            await ilmSession.save();
          } else {
            await Ember.RSVP.reject();
          }
        } else {
          await Ember.RSVP.reject();
        }
      },

      revertIlmDueDateChanges() {
        this.get('session').get('ilmSession').then(ilmSession => {
          if (ilmSession) {
            this.set('dueDate', ilmSession.get('dueDate'));
          }
        });
      },

      async saveDescription() {
        const {
          session,
          store
        } = this.getProperties('session', 'store');
        const newDescription = this.description;
        this.send('addErrorDisplayFor', 'description');
        const {
          validations
        } = await this.validate({
          on: ['description']
        });

        if (validations.isValid) {
          this.send('removeErrorDisplayFor', 'description');
          let sessionDescription = await session.get('sessionDescription');

          if (Ember.isEmpty(newDescription) && sessionDescription) {
            await sessionDescription.deleteRecord();
          } else {
            if (!sessionDescription) {
              sessionDescription = store.createRecord('session-description');
              sessionDescription.set('session', session);
            }

            sessionDescription.set('description', newDescription);
          }

          this.set('sessionDescription', newDescription);

          if (sessionDescription) {
            await sessionDescription.save();
          }
        } else {
          await Ember.RSVP.reject();
        }
      },

      changeDescription(html) {
        this.send('addErrorDisplayFor', 'description');
        let noTagsText = html.replace(/(<([^>]+)>)/ig, "");
        let strippedText = noTagsText.replace(/&nbsp;/ig, "").replace(/\s/g, ""); //if all we have is empty html then save null

        if (strippedText.length === 0) {
          html = null;
        }

        this.set('description', html);
      },

      changeInstructionalNotes(html) {
        this.send('addErrorDisplayFor', 'instructionalNotes');
        let noTagsText = html.replace(/(<([^>]+)>)/ig, "");
        let strippedText = noTagsText.replace(/&nbsp;/ig, "").replace(/\s/g, ""); //if all we have is empty html then save null

        if (strippedText.length === 0) {
          html = null;
        }

        this.set('instructionalNotes', html);
      }

    },
    revertDescriptionChanges: (0, _emberConcurrency.task)(function* () {
      const session = this.get('session');
      const sessionDescription = yield session.get('sessionDescription');

      if (sessionDescription) {
        this.set('description', sessionDescription.get('description'));
      } else {
        this.set('description', null);
      }
    }),
    saveInstructionalNotes: (0, _emberConcurrency.task)(function* () {
      this.send('addErrorDisplayFor', 'instructionalNotes');
      let {
        validations
      } = yield this.validate({
        on: ['instructionalNotes']
      });

      if (validations.get('isInvalid')) {
        return false;
      }

      this.send('removeErrorDisplayFor', 'instructionalNotes');
      this.session.set('instructionalNotes', this.instructionalNotes);
      yield this.session.save();
      this.set('instructionalNotes', this.session.instructionalNotes);
    })
  });

  _exports.default = _default;
});