define("ilios-common/models/pending-user-update", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo,
    Model
  } = _emberData.default;

  var _default = Model.extend({
    type: attr('string'),
    property: attr('string'),
    value: attr('string'),
    user: belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});