define("ilios-common/templates/components/offering-calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IzHxDK5P",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\",true],[10,\"class\",\"offering-calendar-title\"],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"general.calendar\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"p\",true],[10,\"class\",\"offering-calendar-filter-options\"],[8],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"filter\"],[8],[0,\"\\n    \"],[5,\"toggle-yesno\",[],[[\"@yes\",\"@toggle\"],[[22,\"showLearnerGroupEvents\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"showLearnerGroupEvents\"]]],null]],null]]]],[0,\"\\n    \"],[7,\"label\",true],[11,\"onclick\",[28,\"toggle\",[\"showLearnerGroupEvents\",[23,0,[]]],null]],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"general.showLearnerGroupEvents\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"filter\"],[8],[0,\"\\n    \"],[5,\"toggle-yesno\",[],[[\"@yes\",\"@toggle\"],[[22,\"showSessionEvents\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"showSessionEvents\"]]],null]],null]]]],[0,\"\\n    \"],[7,\"label\",true],[11,\"onclick\",[28,\"toggle\",[\"showSessionEvents\",[23,0,[]]],null]],[8],[0,\"\\n\"],[0,\"      \"],[1,[28,\"t\",[\"general.showSessionEvents\"],[[\"sessionTitle\"],[[24,[\"session\",\"title\"]]]]],true],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"ilios-calendar\"],[8],[0,\"\\n  \"],[5,\"ilios-calendar-week\",[],[[\"@calendarEvents\",\"@date\",\"@areEventsSelectable\",\"@areDaysSelectable\"],[[28,\"await\",[[24,[\"calendarEvents\"]]],null],[22,\"startDate\"],false,false]]],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"span\",true],[11,\"class\",[29,[\"loding-indicator \",[28,\"if\",[[28,\"is-fulfilled\",[[24,[\"calendarEvents\"]]],null],\"loaded\"],null]]]],[8],[0,\"\\n  \"],[5,\"loading-spinner\",[],[[],[]]],[0,\"\\n  \"],[1,[28,\"t\",[\"general.loadingEvents\"],null],false],[0,\"\\n  ...\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/offering-calendar.hbs"
    }
  });

  _exports.default = _default;
});