define("ilios-common/templates/components/ics-feed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MgJY/6CQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\",true],[8],[0,\"\\n  \"],[1,[22,\"instructions\"],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"p\",true],[8],[0,\"\\n  \"],[7,\"span\",true],[8],[0,\"\\n    \"],[5,\"copy-button\",[],[[\"@clipboardText\",\"@success\"],[[22,\"url\"],[28,\"perform\",[[24,[\"textCopied\"]]],null]]],{\"statements\":[[0,\"\\n      \"],[5,\"fa-icon\",[],[[\"@icon\"],[\"copy\"]]],[0,\"\\n      \"],[1,[28,\"t\",[\"general.copyIcsFeedUrl\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[24,[\"showCopySuccessMessage\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\",true],[10,\"class\",\"yes\"],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"general.copiedSuccessfully\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/ics-feed.hbs"
    }
  });

  _exports.default = _default;
});