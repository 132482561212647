define("ilios-common/components/detail-terms-list-item", ["exports", "ilios-common/templates/components/detail-terms-list-item"], function (_exports, _detailTermsListItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _detailTermsListItem.default,
    tagName: 'li',
    canEdit: false,
    term: null,
    classNames: ['detail-terms-list-item'],

    click() {
      if (this.get('canEdit')) {
        let term = this.get('term');
        this.remove(term);
      }
    }

  });

  _exports.default = _default;
});