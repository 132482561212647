define("ilios-common/components/course-summary-header", ["exports", "ilios-common/templates/components/course-summary-header"], function (_exports, _courseSummaryHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    permissionChecker: Ember.inject.service(),
    router: Ember.inject.service(),
    layout: _courseSummaryHeader.default,
    classNames: ['course-summary-header'],
    course: null,
    showRollover: Ember.computed('course', 'currentUser', 'router.currentRouteName', async function () {
      if (this.router.currentRouteName === 'course.rollover') {
        return false;
      }

      const permissionChecker = this.get('permissionChecker');
      const course = this.get('course');
      const school = await course.get('school');
      return permissionChecker.canCreateCourse(school);
    }),
    showMaterials: Ember.computed('router.currentRouteName', function () {
      return this.router.currentRouteName !== 'course-materials';
    })
  });

  _exports.default = _default;
});