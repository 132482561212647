define("ilios-common/models/vocabulary", ["exports", "ember-data", "ember-inflector", "ilios-common/mixins/categorizable-model"], function (_exports, _emberData, _emberInflector, _categorizableModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo,
    Model
  } = _emberData.default;

  _emberInflector.default.inflector.irregular('vocabulary', 'vocabularies');

  var _default = Model.extend(_categorizableModel.default, {
    title: attr('string'),
    school: belongsTo('school', {
      async: true
    }),
    active: attr('boolean'),
    topLevelTerms: Ember.computed('terms.[]', async function () {
      const terms = await this.get('terms');
      return terms.toArray().filterBy('isTopLevel');
    })
  });

  _exports.default = _default;
});