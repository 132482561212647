define("ilios-common/mixins/session/index-controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    queryParams: {
      sessionObjectiveDetails: 'sessionObjectiveDetails',
      sessionTaxonomyDetails: 'sessionTaxonomyDetails',
      isManagingLearnerGroups: 'isManagingLearnerGroups',
      sessionLearnergroupDetails: 'sessionLearnergroupDetails',
      sessionLeadershipDetails: 'sessionLeadershipDetails',
      sessionManageLeadership: 'sessionManageLeadership',
      showNewOfferingForm: 'addOffering'
    },
    sessionObjectiveDetails: false,
    sessionTaxonomyDetails: false,
    isManagingLearnerGroups: false,
    sessionLearnergroupDetails: false,
    sessionLeadershipDetails: false,
    sessionManageLeadership: false,
    showNewOfferingForm: false
  });

  _exports.default = _default;
});