define("ilios-common/components/session-publicationcheck", ["exports", "ilios-common/templates/components/session-publicationcheck"], function (_exports, _sessionPublicationcheck) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    layout: _sessionPublicationcheck.default,
    classNames: ['session-publicationcheck'],
    session: null,
    showUnlinkIcon: Ember.computed('session.objectives.[]', function () {
      const objectives = this.session.objectives;
      return objectives.any(objective => Ember.isEmpty(objective.parents));
    }),
    actions: {
      transitionToSession() {
        const queryParams = {
          sessionObjectiveDetails: true
        };
        this.router.transitionTo('session', this.session, {
          queryParams
        });
      }

    }
  });

  _exports.default = _default;
});