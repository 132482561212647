define("elemental-calendar/components/calendar-event", ["exports", "elemental-calendar/templates/components/calendar-event", "moment"], function (_exports, _calendarEvent, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _calendarEvent.default,
    event: null,
    classNames: ['event', 'event-pos'],
    attributeBindings: ['style'],
    style: Ember.computed(function () {
      let escape = Ember.Handlebars.Utils.escapeExpression;
      return Ember.String.htmlSafe("top: ".concat(escape(this.calculateTop()), "%;\n       height: ").concat(escape(this.calculateHeight()), "%;\n       left: ").concat(escape(this.calculateLeft()), "%;\n       width: ").concat(escape(this.calculateWidth()), "%;"));
    }),

    calculateLeft() {
      let percentageSlice = 100 / this.event.cluster.totalLevels;
      let leftPercentage = this.event.level * percentageSlice;
      return Math.round(leftPercentage * 10000) / 10000;
    },

    calculateWidth() {
      let percentageSlice = 100 / this.event.cluster.totalLevels;
      return Math.round(percentageSlice * 10000) / 10000;
    },

    calculateTop() {
      return this._calculatePercentageFromTop(this.event.startDate);
    },

    calculateHeight() {
      let percentageDistanceFromTop = this._calculatePercentageFromTop(this.event.endDate);

      let height = percentageDistanceFromTop - this.calculateTop();
      return Math.round(height * 10000) / 10000;
    },

    _calculatePercentageFromTop(date) {
      let momentDate = (0, _moment.default)(date);
      let hours = momentDate.hours();
      let minutes = momentDate.minutes();
      let minutesConverted = hours * 60;
      let totalMinutes = minutes + minutesConverted;
      let ratio = totalMinutes / 1440;
      let percentage = ratio * 100;
      return Math.round(percentage * 10000) / 10000;
    }

  });

  _exports.default = _default;
});