define("ilios-common/templates/components/back-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6KSu0Ti1",
    "block": "{\"symbols\":[],\"statements\":[[5,\"fa-icon\",[],[[\"@icon\"],[\"angle-double-left\"]]],[1,[28,\"t\",[\"general.back\"],null],false],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/back-link.hbs"
    }
  });

  _exports.default = _default;
});