define("ember-simple-charts/templates/components/simple-chart-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iYQPOCaq",
    "block": "{\"symbols\":[],\"statements\":[],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-simple-charts/templates/components/simple-chart-bar.hbs"
    }
  });

  _exports.default = _default;
});