define("ilios-common/templates/components/detail-terms-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mRRmvr5/",
    "block": "{\"symbols\":[\"term\"],\"statements\":[[4,\"if\",[[28,\"is-fulfilled\",[[24,[\"sortedTerms\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[8],[0,\"\\n    \"],[7,\"strong\",true],[8],[0,\"\\n      \"],[1,[24,[\"vocabulary\",\"title\"]],false],[0,\"\\n    \"],[9],[0,\" (\"],[1,[24,[\"vocabulary\",\"school\",\"title\"]],false],[0,\")\\n\"],[4,\"if\",[[28,\"and\",[[24,[\"canEdit\"]],[28,\"not\",[[24,[\"vocabulary\",\"active\"]]],null]],null]],null,{\"statements\":[[0,\"      \"],[7,\"span\",true],[10,\"class\",\"inactive\"],[8],[0,\"\\n        (\"],[1,[28,\"t\",[\"general.inactive\"],null],false],[0,\")\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"ul\",true],[11,\"class\",[29,[[28,\"if\",[[24,[\"canEdit\"]],\"removable-list\",null],null],\" selected-taxonomy-terms\"]]],[8],[0,\"\\n\"],[4,\"each\",[[28,\"await\",[[24,[\"sortedTerms\"]]],null]],null,{\"statements\":[[4,\"if\",[[24,[\"canEdit\"]]],null,{\"statements\":[[0,\"        \"],[5,\"detail-terms-list-item\",[],[[\"@canEdit\",\"@remove\",\"@term\"],[true,[22,\"remove\"],[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[5,\"detail-terms-list-item\",[],[[\"@canEdit\",\"@term\"],[false,[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/detail-terms-list.hbs"
    }
  });

  _exports.default = _default;
});