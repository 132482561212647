define("ilios-common/components/html-editor", ["exports", "jquery", "@fortawesome/fontawesome-svg-core", "ilios-common/templates/components/html-editor"], function (_exports, _jquery, _fontawesomeSvgCore, _htmlEditor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const defaultButtons = ['bold', 'italic', 'subscript', 'superscript', 'formatOL', 'formatUL', 'insertLink', 'html'];

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    layout: _htmlEditor.default,
    content: '',
    options: Ember.computed('intl.locale', function () {
      const intl = this.get('intl');
      const language = intl.get('locale');
      return {
        key: '3A9A5C4A3gC3E3C3E3B7A4A2F4B2D2zHMDUGENKACTMXQL==',
        theme: 'gray',
        language,
        toolbarInline: false,
        placeholderText: '',
        allowHTML: true,
        saveInterval: false,
        pastePlain: true,
        spellcheck: true,
        toolbarButtons: defaultButtons,
        toolbarButtonsMD: defaultButtons,
        toolbarButtonsSM: defaultButtons,
        toolbarButtonsXS: defaultButtons,
        quickInsertButtons: false,
        pluginsEnabled: ['lists', 'code_view', 'link'],
        iconsTemplate: 'font_awesome_5',
        listAdvancedTypes: false,
        shortcutsEnabled: ['bold', 'italic', 'strikeThrough', 'undo', 'redo', 'createLink']
      };
    }),

    /**
     * Disable Froala's built in beacon tracking
     * Has to be done on the global jQuery plugin object
     */
    init() {
      this._super(...arguments);

      _jquery.default.FE.DT = true;
    },

    /**
     * Convert `<i>` tags from froala into SVG icons
     * Uses: https://fontawesome.com/how-to-use/with-the-api/methods/dom-i2svg
     */
    didRender() {
      Ember.run.next(() => {
        if (this.element) {
          _fontawesomeSvgCore.dom.i2svg({
            node: this.element
          });
        }
      });
    }

  });

  _exports.default = _default;
});