define("ilios-common/templates/components/ilios-calendar-multiday-events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kAhkKUf3",
    "block": "{\"symbols\":[\"event\"],\"statements\":[[4,\"if\",[[24,[\"events\",\"length\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"ilios-calendar-multiday-events\"],[8],[0,\"\\n    \"],[7,\"h4\",true],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"general.multidayEvents\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"ul\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"events\"]]],null,{\"statements\":[[0,\"        \"],[5,\"ilios-calendar-multiday-event\",[],[[\"@isEventSelectable\",\"@selectEvent\",\"@event\"],[[22,\"areEventsSelectable\"],[22,\"selectEvent\"],[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/ilios-calendar-multiday-events.hbs"
    }
  });

  _exports.default = _default;
});