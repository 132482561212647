define("ilios-common/mixins/sortable-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    sortBy: '',
    sortedAscending: Ember.computed('sortBy', function () {
      const sortBy = this.get('sortBy');
      return sortBy.search(/desc/) === -1;
    }),
    actions: {
      sortBy(what) {
        const sortBy = this.get('sortBy');

        if (sortBy === what) {
          what += ':desc';
        }

        this.get('setSortBy')(what);
      }

    }
  });

  _exports.default = _default;
});