define("ilios-common/components/detail-cohorts", ["exports", "ilios-common/templates/components/detail-cohorts"], function (_exports, _detailCohorts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _detailCohorts.default,
    tagName: 'section',
    classNames: ['detail-cohorts'],
    subject: null,
    isManaging: false,
    isSaving: false,
    editable: true,
    bufferedCohorts: null,
    'data-test-detail-cohorts': true,

    init() {
      this._super(...arguments);

      this.set('bufferedCohorts', []);
    },

    actions: {
      manage() {
        this.get('course.cohorts').then(cohorts => {
          this.set('bufferedCohorts', cohorts.toArray());
          this.set('isManaging', true);
        });
      },

      save() {
        this.set('isSaving', true);
        const course = this.get('course');
        course.get('cohorts').then(cohortList => {
          let bufferedCohorts = this.get('bufferedCohorts');
          let removedCohorts = cohortList.filter(cohort => {
            return !bufferedCohorts.includes(cohort);
          });
          cohortList.clear();
          bufferedCohorts.forEach(cohort => {
            cohortList.pushObject(cohort);
          });
          Ember.RSVP.all(removedCohorts.mapBy('programYear')).then(programYearsToRemove => {
            course.get('objectives').then(objectives => {
              Ember.RSVP.all(objectives.invoke('removeParentWithProgramYears', programYearsToRemove)).then(() => {
                course.save().then(() => {
                  this.set('isManaging', false);
                  this.set('bufferedCohorts', []);
                  this.set('isSaving', false);
                });
              });
            });
          });
        });
      },

      cancel() {
        this.set('bufferedCohorts', []);
        this.set('isManaging', false);
      },

      addCohortToBuffer(cohort) {
        this.get('bufferedCohorts').pushObject(cohort);
      },

      removeCohortFromBuffer(cohort) {
        this.get('bufferedCohorts').removeObject(cohort);
      }

    }
  });

  _exports.default = _default;
});