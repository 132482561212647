define("ilios-common/components/detail-terms-list", ["exports", "ilios-common/templates/components/detail-terms-list"], function (_exports, _detailTermsList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Displays all given terms that belong to a given vocabulary as a list of tags.
   */
  var _default = Ember.Component.extend({
    layout: _detailTermsList.default,
    classNames: ['detail-terms-list'],

    /**
     * Flag indicating whether terms can be removed from the list or not.
     *
     * @property canEdit
     * @type {boolean}
     * @public
     */
    canEdit: false,

    /**
     * A vocabulary model.
     *
     * @property vocabulary
     * @type {DS.Model}
     */
    vocabulary: null,

    /**
     * A list of term models.
     *
     * @property terms
     * @type {Array}
     * @public
     */
    terms: null,

    /**
     * A sorted list of the filtered terms.
     * Terms are sorted by title including parent titles.
     *
     * @property sortedTerms
     * @type {Ember.computed}
     * @public
     */
    sortedTerms: Ember.computed('filteredTerms.[]', async function () {
      const terms = this.filteredTerms;
      const proxies = await Ember.RSVP.all(terms.map(async term => {
        const title = await term.titleWithParentTitles;
        return {
          term,
          title
        };
      }));
      const sortedProxies = proxies.sort((a, b) => {
        const titleA = a.title.toLowerCase();
        const titleB = b.title.toLowerCase();
        return titleA > titleB ? 1 : titleA < titleB ? -1 : 0;
      });
      return sortedProxies.mapBy('term');
    }),

    /**
     * A filtered list of the given terms.
     * Terms are filtered by the given vocabulary.
     *
     * @property filteredTerms
     * @type {Ember.computed}
     * @protected
     */
    filteredTerms: Ember.computed('terms.[]', 'vocabulary', function () {
      let terms = this.get('terms');

      if (Ember.isEmpty(terms)) {
        return [];
      }

      let vocab = this.get('vocabulary');
      let filteredTerms = [];
      terms.forEach(term => {
        if (term.get('vocabulary.id') === vocab.get('id')) {
          filteredTerms.push(term);
        }
      });
      return filteredTerms;
    }),
    actions: {
      remove(term) {
        if (this.get('canEdit')) {
          this.remove(term);
        }
      }

    }
  });

  _exports.default = _default;
});