define("ilios-common/components/ilios-calendar-pre-work-event", ["exports", "ilios-common/templates/components/ilios-calendar-pre-work-event"], function (_exports, _iliosCalendarPreWorkEvent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    layout: _iliosCalendarPreWorkEvent.default,
    tagName: '',
    postrequisiteLink: Ember.computed('event.postrequisiteSlug', function () {
      return this.router.urlFor('events', this.event.postrequisiteSlug);
    })
  });

  _exports.default = _default;
});