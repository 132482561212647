define("ilios-common/templates/components/dashboard-view-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nz+zlBrt",
    "block": "{\"symbols\":[],\"statements\":[[7,\"ul\",true],[8],[0,\"\\n  \"],[7,\"li\",true],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",[28,\"if\",[[28,\"eq\",[[24,[\"show\"]],\"week\"],null],\"active\"],null]],[3,\"action\",[[23,0,[]],[24,[\"change\"]],\"week\"]],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"general.weekAtAGlance\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"li\",true],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",[28,\"if\",[[28,\"eq\",[[24,[\"show\"]],\"agenda\"],null],\"active\"],null]],[3,\"action\",[[23,0,[]],[24,[\"change\"]],\"agenda\"]],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"general.activities\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"li\",true],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",[28,\"if\",[[28,\"eq\",[[24,[\"show\"]],\"materials\"],null],\"active\"],null]],[3,\"action\",[[23,0,[]],[24,[\"change\"]],\"materials\"]],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"general.materials\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"li\",true],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",[28,\"if\",[[28,\"eq\",[[24,[\"show\"]],\"calendar\"],null],\"active\"],null]],[3,\"action\",[[23,0,[]],[24,[\"change\"]],\"calendar\"]],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"general.calendar\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/dashboard-view-picker.hbs"
    }
  });

  _exports.default = _default;
});