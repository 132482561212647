define("ilios-common/templates/components/sessions-grid-last-updated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rxhJpNv5",
    "block": "{\"symbols\":[],\"statements\":[[5,\"fa-icon\",[],[[\"@icon\",\"@title\"],[\"history\",[28,\"t\",[\"general.lastUpdate\"],null]]]],[0,\"\\n\"],[1,[28,\"t\",[\"general.lastUpdate\"],null],false],[0,\":\\n\"],[1,[23,0,[\"updatedAt\"]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/sessions-grid-last-updated.hbs"
    }
  });

  _exports.default = _default;
});