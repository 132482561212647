define("ilios-common/components/leadership-collapsed", ["exports", "ilios-common/templates/components/leadership-collapsed"], function (_exports, _leadershipCollapsed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _leadershipCollapsed.default,
    administratorsCount: null,
    directorsCount: null,
    showDirectors: true,
    showAdministrators: true,
    tagName: 'section',
    classNames: ['leadership-collapsed'],
    'data-test-leadership-collapsed': true
  });

  _exports.default = _default;
});