define("ilios-common/components/learnergroup-selection-manager", ["exports", "ilios-common/templates/components/learnergroup-selection-manager"], function (_exports, _learnergroupSelectionManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    layout: _learnergroupSelectionManager.default,
    classNames: ['learnergroup-selection-manager'],
    filter: '',
    cohorts: null,
    learnerGroups: null,
    'data-test-learnergroup-selection-manager': true
  });

  _exports.default = _default;
});