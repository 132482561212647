define("ember-simple-charts/components/simple-chart-horz-bar", ["exports", "ember-simple-charts/mixins/chart-properties", "d3-selection", "d3-scale", "d3-scale-chromatic"], function (_exports, _chartProperties, _d3Selection, _d3Scale, _d3ScaleChromatic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_chartProperties.default, {
    classNames: ['simple-chart-horz-bar'],

    draw() {
      const data = Ember.get(this, 'data');
      const isIcon = Ember.get(this, 'isIcon');
      const hover = Ember.get(this, 'hover');
      const leave = Ember.get(this, 'leave');
      const click = Ember.get(this, 'click');
      const isClickable = Ember.get(this, 'isClickable');
      const dataOrArray = data ? data : [{
        data: 1,
        label: '',
        empty: true
      }];
      const svg = (0, _d3Selection.select)(this.element);
      const values = Ember.A(dataOrArray).mapBy('data');
      const color = (0, _d3Scale.scaleSequential)(_d3ScaleChromatic.interpolateSinebow).domain([0, Math.max(...values)]);
      const xScale = (0, _d3Scale.scaleLinear)().domain([0, Math.max(...dataOrArray.map(d => d.data))]).range([0, isIcon ? 100 : 95]);
      const yScale = (0, _d3Scale.scaleBand)().domain(dataOrArray.map(d => d.label)).range([0, isIcon ? 100 : 95]).paddingInner(0.12);
      svg.selectAll('.bars').remove();
      const bars = svg.append('g').attr('class', 'bars');
      const rect = bars.selectAll('rect').data(dataOrArray).enter().append('rect').attr('height', "".concat(yScale.bandwidth(), "%")).attr('width', d => "".concat(xScale(d.data), "%")).attr('y', d => "".concat(yScale(d.label), "%")).attr('x', 0).attr('fill', d => color(d.data));

      if (!isIcon) {
        const text = bars.selectAll('text').data(dataOrArray).enter().append("text").style("color", d => {
          const rgb = color(d.data); //cut up rgb(1, 99, 245) into parts

          const parts = rgb.substr(4).split(')')[0].split(',');
          const r = parseInt(parts[0], 16);
          const g = parseInt(parts[1], 16);
          const b = parseInt(parts[2], 16); //Thanks to https://24ways.org/2010/calculating-color-contrast for this formula

          const yiq = (r * 299 + g * 587 + b * 114) / 1000;
          return yiq >= 256 ? 'rgb(0, 0, 0)' : 'rgb(255, 255, 255)';
        }).style("font-size", ".8rem").attr("text-anchor", "end").attr("text-align", "right").attr("alignment-baseline", "central").attr('y', d => "".concat(yScale(d.label) + yScale.bandwidth() / 2, "%")).attr('x', d => "".concat(xScale(d.data) - 3, "%")).text(d => d.label);

        const handleHover = data => {
          const rects = svg.selectAll('rect');
          const selected = rects.filter(rectData => rectData.label === data.label);
          hover(data, selected.node());
        };

        rect.on('mouseenter', handleHover);
        text.on('mouseenter', handleHover);
        rect.on('mouseleave', leave);
        text.on('mouseleave', leave);

        if (isClickable) {
          rect.on('click', data => {
            click(data);
          });
          rect.style("cursor", "pointer");
          text.on('click', data => {
            click(data);
          });
          text.style("cursor", "pointer");
        }
      }
    }

  });

  _exports.default = _default;
});