define("ilios-common/components/session-objective-manager", ["exports", "ilios-common/templates/components/session-objective-manager"], function (_exports, _sessionObjectiveManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const objectiveProxy = Ember.ObjectProxy.extend({
    sessionObjective: null,
    selected: Ember.computed('content', 'sessionObjective.parents.[]', function () {
      return this.get('sessionObjective.parents').includes(this.get('content'));
    })
  });

  var _default = Ember.Component.extend({
    layout: _sessionObjectiveManager.default,
    classNames: ['objective-manager'],
    sessionObjective: null,
    'data-test-session-objective-manager': true,
    course: Ember.computed('sessionObjective.courses.[]', async function () {
      const sessionObjective = this.get('sessionObjective');

      if (!sessionObjective) {
        return null;
      }

      const sessions = await sessionObjective.get('sessions');
      const session = sessions.get('firstObject');
      return await session.get('course');
    }),
    proxiedObjectives: Ember.computed('course', 'course.sortedObjectives.[]', async function () {
      const sessionObjective = this.get('sessionObjective');

      if (!sessionObjective) {
        return [];
      }

      const course = await this.get('course');

      if (!course) {
        return [];
      }

      const objectives = await course.get('sortedObjectives');
      return objectives.map(objective => {
        return objectiveProxy.create({
          content: objective,
          sessionObjective: sessionObjective
        });
      });
    }),
    showObjectiveList: Ember.computed('proxiedObjectives.[]', async function () {
      const objectives = await this.get('proxiedObjectives');
      return objectives.length > 0;
    }),
    actions: {
      addParent(parentProxy) {
        let newParent = parentProxy.get('content');
        let sessionObjective = this.get('sessionObjective');
        sessionObjective.get('parents').addObject(newParent);
        newParent.get('children').addObject(sessionObjective);
      },

      removeParent(parentProxy) {
        let removingParent = parentProxy.get('content');
        let sessionObjective = this.get('sessionObjective');
        sessionObjective.get('parents').removeObject(removingParent);
        removingParent.get('children').removeObject(sessionObjective);
      }

    }
  });

  _exports.default = _default;
});