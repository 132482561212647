define("ilios-common/mixins/course-materials-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    titleToken: 'general.coursesAndSessions',

    afterModel(course) {
      return Ember.RSVP.all([this.loadCourseLearningMaterials(course), this.loadSessionLearningMaterials(course)]);
    },

    async loadCourseLearningMaterials(course) {
      const courseLearningMaterials = await course.get('learningMaterials');
      return Ember.RSVP.all(courseLearningMaterials.mapBy('learningMaterial'));
    },

    async loadSessionLearningMaterials(course) {
      const sessions = await course.get('sessions');
      return Ember.RSVP.all([sessions.mapBy('learningMaterials'), sessions.mapBy('firstOfferingDate')]);
    }

  });

  _exports.default = _default;
});