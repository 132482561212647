define("ilios-common/components/toggle-buttons", ["exports", "ilios-common/templates/components/toggle-buttons"], function (_exports, _toggleButtons) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _toggleButtons.default,
    classNames: ['toggle-buttons'],
    tagName: 'span',
    firstOptionSelected: true,
    firstLabel: null,
    secondLabel: null,
    actions: {
      firstChoice() {
        const firstOptionSelected = this.get('firstOptionSelected');
        const toggle = this.get('toggle');

        if (!firstOptionSelected) {
          toggle(true);
        }
      },

      secondChoice() {
        const firstOptionSelected = this.get('firstOptionSelected');
        const toggle = this.get('toggle');

        if (firstOptionSelected) {
          toggle(false);
        }
      }

    }
  });

  _exports.default = _default;
});