define("ilios-common/components/session-objective-list-item", ["exports", "ilios-common/mixins/objective-list-item", "ilios-common/templates/components/session-objective-list-item"], function (_exports, _objectiveListItem, _sessionObjectiveListItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_objectiveListItem.default, {
    layout: _sessionObjectiveListItem.default,
    session: null,
    classNames: ['session-objective-list-item', 'objective-list-item']
  });

  _exports.default = _default;
});