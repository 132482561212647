define("ilios-common/components/visualizer-course-vocabulary", ["exports", "ember-concurrency", "ilios-common/templates/components/visualizer-course-vocabulary"], function (_exports, _emberConcurrency, _visualizerCourseVocabulary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    layout: _visualizerCourseVocabulary.default,
    course: null,
    vocabulary: null,
    isIcon: false,
    classNameBindings: ['isIcon::not-icon', ':visualizer-course-vocabulary'],
    tooltipContent: null,
    tooltipTitle: null,
    data: Ember.computed('course.sessions.[]', 'vocabulary', async function () {
      const course = this.get('course');
      const vocabulary = this.get('vocabulary');
      const sessions = await course.get('sessions');
      const terms = await Ember.RSVP.map(sessions.toArray(), async session => {
        const sessionTerms = await session.get('terms');
        const hours = await session.get('totalSumDuration');
        const minutes = Math.round(hours * 60);
        const sessionTermsInThisVocabulary = await Ember.RSVP.filter(sessionTerms.toArray(), async term => {
          const termVocab = await term.get('vocabulary');
          return termVocab.get('id') === vocabulary.get('id');
        });
        return sessionTermsInThisVocabulary.map(term => {
          return {
            term,
            session: {
              title: session.get('title'),
              minutes
            }
          };
        });
      });
      let flat = terms.reduce((flattened, obj) => {
        return flattened.pushObjects(obj.toArray());
      }, []);
      const termData = flat.reduce((set, {
        term,
        session
      }) => {
        const termTitle = term.get('title');
        let existing = set.findBy('label', termTitle);

        if (!existing) {
          existing = {
            data: 0,
            label: termTitle,
            meta: {
              termTitle,
              termId: term.get('id'),
              sessions: []
            }
          };
          set.pushObject(existing);
        }

        existing.data += session.minutes;
        existing.meta.sessions.pushObject(session.title);
        return set;
      }, []);
      const totalMinutes = termData.mapBy('data').reduce((total, minutes) => total + minutes, 0);
      const mappedTermsWithLabel = termData.map(obj => {
        const percent = (obj.data / totalMinutes * 100).toFixed(1);
        obj.label = "".concat(obj.meta.termTitle, " ").concat(percent, "%");
        obj.meta.totalMinutes = totalMinutes;
        obj.meta.percent = percent;
        return obj;
      });
      return mappedTermsWithLabel;
    }),
    sortedData: Ember.computed('data.[]', async function () {
      const data = await this.get('data');
      data.sort((first, second) => {
        return first.data - second.data;
      });
      return data;
    }),
    actions: {
      barClick(obj) {
        const course = this.get('course');
        const isIcon = this.get('isIcon');
        const router = this.get('router');

        if (isIcon || Ember.isEmpty(obj) || obj.empty || Ember.isEmpty(obj.meta)) {
          return;
        }

        router.transitionTo('course-visualize-term', course.get('id'), obj.meta.termId);
      }

    },
    barHover: (0, _emberConcurrency.task)(function* (obj) {
      yield (0, _emberConcurrency.timeout)(100);
      const isIcon = this.get('isIcon');

      if (isIcon || Ember.isEmpty(obj) || obj.empty) {
        this.set('tooltipTitle', null);
        this.set('tooltipContent', null);
        return;
      }

      const intl = this.get('intl');
      const {
        label,
        data,
        meta
      } = obj;
      const title = Ember.String.htmlSafe("".concat(label, " ").concat(data, " ").concat(intl.t('general.minutes')));
      const sessions = meta.sessions.uniq().sort().join();
      this.set('tooltipTitle', title);
      this.set('tooltipContent', sessions);
    }).restartable()
  });

  _exports.default = _default;
});