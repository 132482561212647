define("ilios-common/components/new-objective", ["exports", "ember-cp-validations", "ilios-common/mixins/validation-error-display", "ilios-common/templates/components/new-objective"], function (_exports, _emberCpValidations, _validationErrorDisplay, _newObjective) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    title: [(0, _emberCpValidations.validator)('html-presence', true), (0, _emberCpValidations.validator)('length', {
      min: 3,
      max: 65000
    })]
  });

  var _default = Ember.Component.extend(Validations, _validationErrorDisplay.default, {
    layout: _newObjective.default,
    tagName: 'section',
    classNames: ['new-objective'],
    title: null,
    isSaving: false,
    actions: {
      async save() {
        this.set('isSaving', true);
        this.send('addErrorDisplayFor', 'title');
        const {
          validations
        } = await this.validate();

        if (validations.isValid) {
          this.send('removeErrorDisplayFor', 'title');
          await this.save(this.title);
          this.set('title', null);
        }

        this.set('isSaving', false);
      },

      changeTitle(contents) {
        this.send('addErrorDisplayFor', 'title');
        this.set('title', contents);
      }

    }
  });

  _exports.default = _default;
});