define("ilios-common/templates/components/wait-saving", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pGh9Nyz1",
    "block": "{\"symbols\":[],\"statements\":[[5,\"modal-dialog\",[],[[\"@translucentOverlay\",\"@animatable\"],[true,false]],{\"statements\":[[0,\"\\n  \"],[5,\"pulse-loader\",[],[[],[]]],[0,\"\\n  \"],[7,\"h2\",true],[10,\"class\",\"page-title\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"general.waitSaving\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"showProgress\"]]],null,{\"statements\":[[0,\"    \"],[5,\"progress-bar\",[],[[\"@percentage\"],[[22,\"progress\"]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/wait-saving.hbs"
    }
  });

  _exports.default = _default;
});