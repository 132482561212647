define("ilios-common/helpers/is-in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isIn = isIn;
  _exports.default = void 0;

  function isIn([values, item]) {
    if (!values) {
      return false;
    }

    if (!item) {
      return false;
    }

    return values.includes(item);
  }

  var _default = Ember.Helper.extend({
    values: null,

    compute([values, item]) {
      this.set('values', values);
      return isIn([values, item]);
    },

    recomputeOnArrayChange: Ember.observer('values.[]', function () {
      this.recompute();
    })
  });

  _exports.default = _default;
});