define("ilios-common/templates/components/ilios-calendar-event-month", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MPWUj5is",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"event\"]]],null,{\"statements\":[[4,\"attach-tooltip\",null,[[\"lazyRender\",\"placement\",\"interactive\",\"animation\",\"class\"],[true,\"left\",true,\"shift\",\"ilios-calendar-event-tooltip\"]],{\"statements\":[[0,\"    \"],[1,[22,\"tooltipContent\"],true],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"recentlyUpdated\"]]],null,{\"statements\":[[0,\"    \"],[5,\"fa-icon\",[[12,\"class\",\"recently-updated-icon\"]],[[\"@icon\",\"@title\"],[\"exclamation\",[28,\"t\",[\"general.newUpdates\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"span\",true],[10,\"class\",\"ilios-calendar-event-time\"],[8],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"ilios-calendar-event-start\"],[8],[0,\"\\n      \"],[1,[28,\"moment-format\",[[24,[\"event\",\"startDate\"]],\"h:mma\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"ilios-calendar-event-end\"],[8],[0,\"\\n      -\\n      \"],[1,[28,\"moment-format\",[[24,[\"event\",\"endDate\"]],\"h:mma\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"unless\",[[24,[\"event\",\"isMulti\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"ilios-calendar-event-location\"],[8],[0,\"\\n      \"],[1,[24,[\"event\",\"location\"]],false],[0,\":\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"span\",true],[10,\"class\",\"ilios-calendar-event-name\"],[8],[0,\"\\n    \"],[1,[24,[\"event\",\"name\"]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"event\",\"isMulti\"]]],null,{\"statements\":[[0,\"      ,\\n      \"],[7,\"em\",true],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"general.multiple\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/ilios-calendar-event-month.hbs"
    }
  });

  _exports.default = _default;
});