define("ilios-common/mixins/course-visualize-session-types-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    titleToken: 'general.coursesAndSessions',

    async afterModel(course) {
      const sessions = await course.get('sessions');
      return await Ember.RSVP.all([Ember.RSVP.map(sessions.toArray(), s => s.get('sessionType')), Ember.RSVP.map(sessions.toArray(), s => s.get('totalSumDuration'))]);
    }

  });

  _exports.default = _default;
});