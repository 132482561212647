define("ilios-common/components/session-copy", ["exports", "moment", "ember-concurrency", "ember-cp-validations", "ilios-common/mixins/validation-error-display", "ilios-common/templates/components/session-copy"], function (_exports, _moment, _emberConcurrency, _emberCpValidations, _validationErrorDisplay, _sessionCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    bestSelectedCourse: [(0, _emberCpValidations.validator)('presence', true)],
    bestSelectedYear: [(0, _emberCpValidations.validator)('presence', true)]
  });

  var _default = Ember.Component.extend(_validationErrorDisplay.default, Validations, {
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    permissionChecker: Ember.inject.service(),
    layout: _sessionCopy.default,
    classNames: ['session-copy'],
    selectedYear: null,
    session: null,
    selectedCourseId: null,
    years: Ember.computed(async function () {
      const now = (0, _moment.default)();
      const thisYear = now.year();
      const store = this.get('store');
      let years = await store.findAll('academicYear');
      let academicYears = years.map(year => parseInt(year.get('id'), 10)).filter(year => year >= thisYear - 1).sort();
      return academicYears;
    }),
    bestSelectedYear: Ember.computed('years.[]', 'selectedYear', async function () {
      const selectedYear = this.get('selectedYear');

      if (selectedYear) {
        return selectedYear;
      }

      const years = await this.get('years');
      return years.get('firstObject');
    }),
    courses: Ember.computed('selectedYear', 'session.course.school', async function () {
      const store = this.get('store');
      const permissionChecker = this.get('permissionChecker');
      const session = this.get('session');

      if (!session) {
        return [];
      }

      const selectedYear = await this.get('bestSelectedYear');
      const course = await session.get('course');
      const school = await course.get('school');
      const courses = await store.query('course', {
        filters: {
          year: selectedYear,
          school: school.get('id')
        }
      });
      const filteredCourses = await Ember.RSVP.filter(courses.toArray(), async co => {
        return permissionChecker.canCreateSession(co);
      });
      return filteredCourses.sortBy('title');
    }),
    bestSelectedCourse: Ember.computed('courses.[]', 'selectedCourseId', async function () {
      const courses = await this.get('courses');
      const selectedCourseId = this.get('selectedCourseId');

      if (selectedCourseId) {
        const course = courses.findBy('id', selectedCourseId);

        if (course) {
          return course;
        }
      }

      return courses.get('firstObject');
    }),
    actions: {
      changeSelectedYear(newYear) {
        this.set('selectedCourseId', null);
        this.set('selectedYear', parseInt(newYear, 10));
      }

    },
    save: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(10);
      this.send('addErrorDisplaysFor', ['selectedCourse', 'selectedYear']);
      let {
        validations
      } = yield this.validate();

      if (validations.get('isInvalid')) {
        return;
      }

      const flashMessages = this.get('flashMessages');
      const store = this.get('store');
      let sessionToCopy = this.get('session');
      let newCourse = yield this.get('bestSelectedCourse');
      let toSave = [];
      let session = store.createRecord('session', sessionToCopy.getProperties('title', 'attireRequired', 'equipmentRequired', 'supplemental', 'instructionalNotes'));
      session.set('course', newCourse);
      let props = yield Ember.RSVP.hash(sessionToCopy.getProperties('meshDescriptors', 'terms', 'sessionType'));
      session.setProperties(props);
      let ilmToCopy = yield sessionToCopy.get('ilmSession');

      if (ilmToCopy) {
        let ilm = store.createRecord('ilmSession', ilmToCopy.getProperties('hours', 'dueDate'));
        ilm.set('session', session);
        toSave.pushObject(ilm);
      }

      let sessionDescriptionToCopy = yield sessionToCopy.get('sessionDescription');

      if (sessionDescriptionToCopy) {
        let sessionDescription = store.createRecord('sessionDescription', sessionDescriptionToCopy.getProperties('description'));
        sessionDescription.set('session', session);
        toSave.pushObject(sessionDescription);
      }

      let learningMaterialsToCopy = yield sessionToCopy.get('learningMaterials');

      for (let i = 0; i < learningMaterialsToCopy.length; i++) {
        let learningMaterialToCopy = learningMaterialsToCopy.toArray()[i];
        let lm = yield learningMaterialToCopy.get('learningMaterial');
        let learningMaterial = store.createRecord('sessionLearningMaterial', learningMaterialToCopy.getProperties('notes', 'required', 'publicNotes', 'position'));
        learningMaterial.set('learningMaterial', lm);
        learningMaterial.set('session', session);
        toSave.pushObject(learningMaterial);
      }

      const originalCourse = yield sessionToCopy.course;

      if (newCourse.id === originalCourse.id) {
        const postrequisiteToCopy = yield sessionToCopy.postrequisite;
        session.set('postrequisite', postrequisiteToCopy);
        const prerequisitesToCopy = yield sessionToCopy.prerequisites;
        session.set('prerequisites', prerequisitesToCopy);
      } // save the session first to fill out relationships with the session id


      yield session.save();
      yield Ember.RSVP.all(toSave.invoke('save')); //parse objectives last because it is a many2many relationship
      //and ember data tries to save it too soon

      let relatedObjectives = yield sessionToCopy.get('objectives');
      let objectivesToCopy = relatedObjectives.sortBy('id');

      for (let i = 0; i < objectivesToCopy.length; i++) {
        let objectiveToCopy = objectivesToCopy.toArray()[i];
        let meshDescriptors = yield objectiveToCopy.get('meshDescriptors');
        let objective = store.createRecord('objective', objectiveToCopy.getProperties('title', 'position'));
        objective.set('meshDescriptors', meshDescriptors);
        objective.set('sessions', [session]); //save each objective as it is created to preserve to sequence order of objectives by id

        yield objective.save();
      }

      flashMessages.success('general.copySuccess');
      return this.get('visit')(session);
    }).drop()
  });

  _exports.default = _default;
});