define("ilios-common/components/leadership-list", ["exports", "ilios-common/templates/components/leadership-list"], function (_exports, _leadershipList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _leadershipList.default,
    classNames: ['leadership-list'],
    administrators: null,
    directors: null,
    showDirectors: true,
    showAdministrators: true,
    'data-test-leadership-list': true
  });

  _exports.default = _default;
});