define("ilios-common/mixins/course-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    permissionChecker: Ember.inject.service(),
    titleToken: 'general.coursesAndSessions',
    editable: false,

    async afterModel(course) {
      const permissionChecker = this.get('permissionChecker');
      const editable = await permissionChecker.canUpdateCourse(course);
      this.set('editable', editable);
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('editable', this.get('editable'));
    }

  });

  _exports.default = _default;
});