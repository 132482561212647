define("ilios-common/mixins/dashboard-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),

    async model() {
      const store = this.get('store');
      const schools = await store.findAll('school');
      const academicYears = await store.findAll('academic-year');
      return {
        schools,
        academicYears
      };
    }

  });

  _exports.default = _default;
});