define("ilios-common/components/ilios-calendar", ["exports", "ilios-common/templates/components/ilios-calendar", "moment"], function (_exports, _iliosCalendar, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _iliosCalendar.default,
    classNames: ['ilios-calendar'],
    selectedView: null,
    selectedDate: null,
    calendarEventsPromise: null,
    icsFeedUrl: null,
    showIcsFeed: false,
    compiledCalendarEvents: Ember.computed('calendarEventsPromise.[]', 'selectedView', async function () {
      const events = await this.get('calendarEventsPromise');

      if (this.get('selectedView') === 'day') {
        return events;
      } else {
        let hashedEvents = {};
        events.forEach(event => {
          let hash = (0, _moment.default)(event.startDate).format() + (0, _moment.default)(event.endDate).format() + event.name;

          if (!(hash in hashedEvents)) {
            hashedEvents[hash] = [];
          } //clone our event so we don't trample on the original when we change location


          hashedEvents[hash].pushObject(Ember.copy(event));
        });
        let compiledEvents = [];
        let hash;

        for (hash in hashedEvents) {
          let arr = hashedEvents[hash];
          let event = arr[0];

          if (arr.length > 1) {
            event.isMulti = true;
          }

          compiledEvents.pushObject(event);
        }

        return compiledEvents;
      }
    }),
    actions: {
      changeDate(newDate) {
        this.get('changeDate')(newDate);
      },

      goForward() {
        let newDate = (0, _moment.default)(this.get('selectedDate')).add(1, this.get('selectedView')).toDate();
        this.get('changeDate')(newDate);
      },

      goBack() {
        let newDate = (0, _moment.default)(this.get('selectedDate')).subtract(1, this.get('selectedView')).toDate();
        this.get('changeDate')(newDate);
      },

      gotoToday() {
        let newDate = (0, _moment.default)().toDate();
        this.get('changeDate')(newDate);
      },

      selectEvent(event) {
        this.get('selectEvent')(event);
      },

      refreshIcsFeed() {
        this.set('icsFeedUrl', null);
        this.get('refreshIcsFeed')();
      },

      sortEvents(a, b) {
        let startDiff = (0, _moment.default)(a.startDate).diff((0, _moment.default)(b.startDate));

        if (startDiff !== 0) {
          return startDiff;
        }

        let durationA = (0, _moment.default)(a.startDate).diff((0, _moment.default)(a.endDate));
        let durationB = (0, _moment.default)(b.startDate).diff((0, _moment.default)(b.endDate));
        let durationDiff = durationA - durationB;

        if (durationDiff !== 0) {
          return durationDiff;
        }

        return a.title - b.title;
      }

    }
  });

  _exports.default = _default;
});