define("ilios-common/components/new-learningmaterial", ["exports", "ember-cp-validations", "ilios-common/mixins/validation-error-display", "ember-concurrency", "ilios-common/templates/components/new-learningmaterial"], function (_exports, _emberCpValidations, _validationErrorDisplay, _emberConcurrency, _newLearningmaterial) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    equal,
    reads
  } = Ember.computed;
  const Validations = (0, _emberCpValidations.buildValidations)({
    title: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 4,
      max: 60
    })],
    originalAuthor: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 2,
      max: 80
    })],
    fileHash: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      dependentKeys: ['model.isFile'],

      disabled(model) {
        return !model.get('isFile');
      }

    })],
    filename: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      dependentKeys: ['model.isFile'],

      disabled(model) {
        return !model.get('isFile');
      }

    })],
    link: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      dependentKeys: ['model.isLink'],

      disabled(model) {
        return !model.get('isLink');
      }

    })],
    citation: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      dependentKeys: ['model.isCitation'],

      disabled(model) {
        return !model.get('isCitation');
      }

    })],
    copyrightRationale: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      dependentKeys: ['model.isFile', 'model.copyrightPermission'],

      disabled(model) {
        return !model.get('isFile') || model.get('copyrightPermission');
      }

    }), (0, _emberCpValidations.validator)('length', {
      min: 2,
      max: 65000
    })]
  });

  var _default = Ember.Component.extend(Validations, _validationErrorDisplay.default, {
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    iliosConfig: Ember.inject.service(),
    layout: _newLearningmaterial.default,
    classNames: ['new-learningmaterial'],
    learningMaterialStatuses: null,
    learningMaterialUserRoles: null,
    filename: null,
    fileHash: null,
    statusId: null,
    userRoleId: null,
    description: null,
    originalAuthor: null,
    title: null,
    link: 'http://',
    copyrightPermission: false,
    copyrightRationale: null,
    owner: null,
    citation: null,
    fileUploadErrorMessage: false,
    host: reads('iliosConfig.apiHost'),
    isFile: equal('type', 'file'),
    isCitation: equal('type', 'citation'),
    isLink: equal('type', 'link'),
    uploadPath: Ember.computed('host', function () {
      return this.get('host') + '/upload';
    }),
    selectedStatus: Ember.computed('learningMaterialStatuses.[]', 'statusId', function () {
      const learningMaterialStatuses = this.get('learningMaterialStatuses');

      if (!learningMaterialStatuses) {
        return;
      }

      const statusId = this.get('statusId');

      if (statusId) {
        return learningMaterialStatuses.findBy('id', statusId);
      }

      return learningMaterialStatuses.findBy('title', 'Final');
    }),
    selectedUserRole: Ember.computed('learningMaterialUserRoles.[]', 'userRoleId', function () {
      const learningMaterialUserRoles = this.get('learningMaterialUserRoles');

      if (!learningMaterialUserRoles) {
        return;
      }

      const userRoleId = this.get('userRoleId');

      if (userRoleId) {
        return learningMaterialUserRoles.findBy('id', userRoleId);
      }

      return learningMaterialUserRoles.get('firstObject');
    }),
    actions: {
      setFile(e) {
        this.setProperties({
          filename: e.filename,
          fileHash: e.fileHash,
          showUploadStatus: false,
          fileUploadPercentage: 100,
          fileUploadErrorMessage: null
        });
      },

      startUploadingFile() {
        Ember.set(this, 'fileHash', null);
        Ember.set(this, 'showUploadStatus', true);
        Ember.set(this, 'fileUploadPercentage', 0);
        Ember.set(this, 'fileUploadErrorMessage', 0);
      },

      setFileUploadPercentage(percent) {
        Ember.set(this, 'fileUploadPercentage', Math.floor(percent));
      }

    },
    prepareSave: (0, _emberConcurrency.task)(function* () {
      this.send('addErrorDisplaysFor', ['title', 'originalAuthor', 'fileHash', 'url', 'citation']);
      let {
        validations
      } = yield this.validate();

      if (validations.get('isInvalid')) {
        return;
      }

      const store = this.get('store');
      const save = this.get('save');
      const currentUser = this.get('currentUser');
      const title = this.get('title');
      const type = this.get('type');
      const status = this.get('selectedStatus');
      const userRole = this.get('selectedUserRole');
      const description = this.get('description');
      const originalAuthor = this.get('originalAuthor');
      const owningUser = yield currentUser.get('model');
      let learningMaterial = store.createRecord('learningMaterial', {
        title,
        status,
        userRole,
        description,
        originalAuthor,
        owningUser
      });

      switch (type) {
        case 'file':
          {
            const copyrightPermission = this.get('copyrightPermission');
            const copyrightRationale = this.get('copyrightRationale');
            const filename = this.get('filename');
            const fileHash = this.get('fileHash');
            learningMaterial.setProperties({
              copyrightRationale,
              copyrightPermission,
              filename,
              fileHash
            });
            break;
          }

        case 'link':
          {
            const link = this.get('link');
            learningMaterial.setProperties({
              link
            });
            break;
          }

        case 'citation':
          {
            const citation = this.get('citation');
            learningMaterial.setProperties({
              citation
            });
            break;
          }
      }

      yield save(learningMaterial);
      this.send('clearErrorDisplay');
    })
  });

  _exports.default = _default;
});