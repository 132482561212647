define("ember-intl/adapters/default", ["exports", "ember-intl/models/translation"], function (_exports, _translation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    _seen: null,

    /** @private **/
    locales: Ember.computed('_seen.[]', function () {
      return Ember.get(this, '_seen').map(l => l.localeName);
    }).readOnly(),

    /** @private **/
    init() {
      this._super();

      this._seen = Ember.A();
    },

    /** @private **/
    lookupLocale(localeName) {
      return this._seen.findBy('localeName', localeName);
    },

    /** @private **/
    localeFactory(localeName) {
      const owner = Ember.getOwner(this);
      const lookupName = "ember-intl@translation:".concat(localeName);
      let model = owner.lookup(lookupName);

      if (model) {
        return model;
      }

      let Klass;

      if (owner.hasRegistration('model:ember-intl-translation')) {
        Klass = owner.factoryFor('model:ember-intl-translation').class;
      } else {
        Klass = _translation.default;
      }

      const ModelKlass = Klass.extend();
      Object.defineProperty(ModelKlass.proto(), 'localeName', {
        writable: false,
        enumerable: true,
        value: localeName
      });
      owner.register(lookupName, ModelKlass);
      model = owner.lookup(lookupName);

      this._seen.pushObject(model);

      return model;
    },

    /** @private **/
    has(localeName, translationKey) {
      const model = this.lookupLocale(localeName);
      return model && model.has(translationKey);
    },

    /** @private **/
    lookup(localeName, translationKey) {
      const model = this.lookupLocale(localeName);

      if (model && model.has(translationKey)) {
        return model.getValue(translationKey);
      }
    }

  });

  _exports.default = _default;
});