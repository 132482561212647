define("ilios-common/components/lm-type-icon", ["exports", "ilios-common/templates/components/lm-type-icon"], function (_exports, _lmTypeIcon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _lmTypeIcon.default,
    listItem: false,
    type: null,
    mimetype: null,
    tagName: 'span',
    classNames: ['lm-type-icon'],
    'data-test-lm-type-icon': true,
    icon: Ember.computed('type', 'mimetype', function () {
      let type = this.get('type');
      let mimetype = this.get('mimetype') || '';

      if (type === 'link') {
        return 'link';
      } else if (type === 'citation') {
        return 'paragraph';
      } else {
        if (mimetype.search(/pdf/) !== -1) {
          return 'file-pdf';
        }

        if (mimetype.search(/ppt|keynote|pps|pptx|powerpoint/) !== -1) {
          return 'file-powerpoint';
        }

        if (mimetype.search(/mp4|mpg|mpeg|mov/) !== -1) {
          return 'file-video';
        }

        if (mimetype.search(/wav|mp3|aac|flac/) !== -1) {
          return 'file-audio';
        }
      }

      return 'file';
    }),
    title: Ember.computed('type', function () {
      const type = this.type ? this.type : 'file';
      return "general.".concat(type);
    })
  });

  _exports.default = _default;
});