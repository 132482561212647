define("ilios-common/templates/mymaterials", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KJje8b/4",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[28,\"t\",[\"general.learningMaterials\"],null]],null],false],[0,\"\\n\"],[5,\"my-materials\",[],[[\"@courseIdFilter\",\"@filter\",\"@materials\",\"@sortBy\",\"@setCourseIdFilter\",\"@setFilter\",\"@setSortBy\"],[[23,0,[\"course\"]],[23,0,[\"filter\"]],[23,0,[\"materials\"]],[23,0,[\"sortBy\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"course\"]]],null]],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"filter\"]]],null]],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"sortBy\"]]],null]],null]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/mymaterials.hbs"
    }
  });

  _exports.default = _default;
});