define("ilios-common/components/ilios-calendar-day", ["exports", "moment", "ilios-common/templates/components/ilios-calendar-day"], function (_exports, _moment, _iliosCalendarDay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _iliosCalendarDay.default,
    classNames: ['ilios-calendar-day'],
    date: null,
    calendarEvents: null,
    areEventsSelectable: true,
    ilmPreWorkEvents: Ember.computed('calendarEvents.[]', function () {
      const calendarEvents = this.calendarEvents || [];
      const preWork = calendarEvents.reduce((arr, eventObject) => {
        return arr.pushObjects(eventObject.prerequisites);
      }, []);
      return preWork.filter(ev => ev.ilmSession);
    }),
    nonIlmPreWorkEvents: Ember.computed('calendarEvents.[]', function () {
      const calendarEvents = this.calendarEvents || [];
      return calendarEvents.filter(ev => {
        return ev.postrequisites.length === 0 || !ev.ilmSession;
      });
    }),
    singleDayEvents: Ember.computed('nonIlmPreWorkEvents.[]', function () {
      const events = this.get('nonIlmPreWorkEvents');

      if (Ember.isEmpty(events)) {
        return [];
      }

      return events.filter(event => (0, _moment.default)(event.startDate).isSame((0, _moment.default)(event.endDate), 'day'));
    }),
    multiDayEvents: Ember.computed('nonIlmPreWorkEvents.[]', function () {
      const events = this.get('nonIlmPreWorkEvents');

      if (Ember.isEmpty(events)) {
        return [];
      }

      return events.filter(event => !(0, _moment.default)(event.startDate).isSame((0, _moment.default)(event.endDate), 'day'));
    }),

    didInsertElement() {
      Ember.run.next(() => {
        if (!this.isDestroyed && !this.isDestroying && this.element) {
          this.element.querySelector(".el-calendar .week").scrollTop = 500;
        }
      });
    }

  });

  _exports.default = _default;
});