define("ilios-common/utils/offering-date-block", ["exports", "moment", "ember-moment/computeds/format"], function (_exports, _moment, _format) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    sort
  } = Ember.computed;
  const OfferingBlock = Ember.Object.extend({
    init() {
      this._super(...arguments);

      this.set('offerings', []);
    },

    offerings: null,

    addOffering(offering) {
      this.get('offerings').pushObject(offering);
    }

  });
  const OfferingDateBlock = OfferingBlock.extend({
    dateKey: null,
    //convert our day of the year key into a date at midnight
    date: Ember.computed('dateKey', function () {
      let year = this.get('dateKey').substring(0, 4);
      let dayOfYear = this.get('dateKey').substring(4);
      let date = new Date(year, 0);
      return new Date(date.setDate(dayOfYear));
    }),
    dateStamp: (0, _format.default)('date', 'X'),
    dayOfWeek: (0, _format.default)('date', 'dddd'),
    dayOfMonth: (0, _format.default)('date', 'MMMM Do'),
    offeringTimeBlocks: Ember.computed('offerings.@each.{startDate,endDate}', function () {
      let offeringGroups = {};
      this.get('offerings').forEach(function (offering) {
        let key = offering.get('timeKey');

        if (!(key in offeringGroups)) {
          offeringGroups[key] = OfferingTimeBlock.create({
            timeKey: key
          });
        }

        offeringGroups[key].addOffering(offering);
      }); //convert indexed object to array

      let offeringGroupArray = [];
      let key;

      for (key in offeringGroups) {
        offeringGroupArray.pushObject(offeringGroups[key]);
      }

      return offeringGroupArray.sortBy('timeKey');
    })
  });
  const OfferingTimeBlock = OfferingBlock.extend({
    init() {
      this._super(...arguments);

      this.set('sortOfferingsBy', ['learnerGroups.firstObject.title']);
    },

    timeKey: null,
    isMultiDay: Ember.computed('startDate', 'endDate', function () {
      return this.get('startDate').format('DDDDYYYY') !== this.get('endDate').format('DDDDYYYY');
    }),
    //pull our times out of the key
    startDate: Ember.computed('timeKey', function () {
      let key = this.get('timeKey').substring(0, 11);
      return (0, _moment.default)(key, 'YYYYDDDHHmm');
    }),
    endDate: Ember.computed('timeKey', function () {
      let key = this.get('timeKey').substring(11);
      return (0, _moment.default)(key, 'YYYYDDDHHmm');
    }),
    startTime: (0, _format.default)('startDate', 'LT'),
    endTime: (0, _format.default)('endDate', 'LT'),
    longStartText: (0, _format.default)('startDate', 'dddd MMMM Do [@] LT'),
    longEndText: (0, _format.default)('endDate', 'dddd MMMM Do [@] LT'),
    sortOfferingsBy: null,
    sortedOfferings: sort('offerings', 'sortOfferingsBy'),
    durationHours: Ember.computed('totalMinutes', function () {
      return Math.floor(this.totalMinutes / 60);
    }),
    durationMinutes: Ember.computed('totalMinutes', function () {
      return this.totalMinutes % 60;
    }),
    totalMinutes: Ember.computed('startDate', 'endDate', function () {
      const startDate = this.get('startDate');
      const endDate = this.get('endDate');
      const diff = endDate.diff(startDate);

      const duration = _moment.default.duration(diff).as('minutes');

      return duration;
    })
  });
  var _default = OfferingDateBlock;
  _exports.default = _default;
});