define("ilios-common/components/visualizer-course-session-type", ["exports", "ember-concurrency", "ilios-common/templates/components/visualizer-course-session-type"], function (_exports, _emberConcurrency, _visualizerCourseSessionType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    layout: _visualizerCourseSessionType.default,
    course: null,
    sessionType: null,
    isIcon: false,
    classNameBindings: ['isIcon::not-icon', ':visualizer-course-session-type'],
    tooltipContent: null,
    tooltipTitle: null,
    data: Ember.computed('course.sessions.[]', 'sessionType', async function () {
      const course = this.get('course');
      const sessionType = this.get('sessionType');
      const courseSessions = await course.get('sessions');
      const sessionTypeSessionIds = sessionType.hasMany('sessions').ids();
      const sessions = courseSessions.filter(session => sessionTypeSessionIds.includes(session.get('id')));
      const termData = await Ember.RSVP.map(sessions, async session => {
        const hours = await session.get('totalSumDuration');
        const minutes = Math.round(hours * 60);
        const terms = await session.get('terms');
        return Ember.RSVP.map(terms.toArray(), async term => {
          const vocabulary = await term.get('vocabulary');
          return {
            sessionTitle: session.get('title'),
            termTitle: term.get('title'),
            vocabularyTitle: vocabulary.get('title'),
            minutes
          };
        });
      });
      const flat = termData.reduce((flattened, obj) => {
        return flattened.pushObjects(obj.toArray());
      }, []);
      const data = flat.reduce((set, obj) => {
        const label = obj.vocabularyTitle + ' - ' + obj.termTitle;
        let existing = set.findBy('label', label);

        if (!existing) {
          existing = {
            data: 0,
            label,
            meta: {
              vocabularyTitle: obj.vocabularyTitle,
              sessions: []
            }
          };
          set.pushObject(existing);
        }

        existing.data += obj.minutes;
        existing.meta.sessions.pushObject(obj.sessionTitle);
        return set;
      }, []);
      const totalMinutes = data.mapBy('data').reduce((total, minutes) => total + minutes, 0);
      const mappedTermsWithLabel = data.map(obj => {
        const percent = (obj.data / totalMinutes * 100).toFixed(1);
        obj.label = "".concat(obj.label, " ").concat(percent, "%");
        obj.meta.totalMinutes = totalMinutes;
        obj.meta.percent = percent;
        return obj;
      });
      return mappedTermsWithLabel;
    }),
    sortedData: Ember.computed('data.[]', async function () {
      const data = await this.get('data');
      data.sort((first, second) => {
        return first.meta.vocabularyTitle.localeCompare(second.meta.vocabularyTitle) || first.data - second.data;
      });
      return data;
    }),
    barHover: (0, _emberConcurrency.task)(function* (obj) {
      yield (0, _emberConcurrency.timeout)(100);
      const isIcon = this.get('isIcon');

      if (isIcon || Ember.isEmpty(obj) || obj.empty) {
        this.set('tooltipTitle', null);
        this.set('tooltipContent', null);
        return;
      }

      const intl = this.get('intl');
      const {
        label,
        data,
        meta
      } = obj;
      const title = Ember.String.htmlSafe("".concat(label, " ").concat(data, " ").concat(intl.t('general.minutes')));
      const sessions = meta.sessions.uniq().sort().join(', ');
      this.set('tooltipTitle', title);
      this.set('tooltipContent', sessions);
    }).restartable()
  });

  _exports.default = _default;
});