define("ilios-common/components/visualizer-course-instructor-term", ["exports", "ember-concurrency", "ilios-common/templates/components/visualizer-course-instructor-term"], function (_exports, _emberConcurrency, _visualizerCourseInstructorTerm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    layout: _visualizerCourseInstructorTerm.default,
    course: null,
    user: null,
    isIcon: false,
    classNameBindings: ['isIcon::not-icon', ':visualizer-course-instructor-session-type'],
    tooltipContent: null,
    tooltipTitle: null,
    data: Ember.computed('course.sessions.[]', 'vocabulary', async function () {
      const course = this.get('course');
      const user = this.get('user');
      const sessions = await course.get('sessions');
      const sessionsWithUser = await Ember.RSVP.filter(sessions.toArray(), async session => {
        const instructors = await session.get('allInstructors');
        return instructors.mapBy('id').includes(user.get('id'));
      });
      const dataMap = await Ember.RSVP.map(sessionsWithUser, async session => {
        const terms = await session.get('terms');
        const hours = await session.get('totalSumDuration');
        const minutes = Math.round(hours * 60);
        return Ember.RSVP.map(terms.toArray(), async term => {
          const vocabulary = await term.get('vocabulary');
          return {
            sessionTitle: session.get('title'),
            termTitle: term.get('title'),
            vocabularyTitle: vocabulary.get('title'),
            minutes
          };
        });
      });
      const flat = dataMap.reduce((flattened, obj) => {
        return flattened.pushObjects(obj.toArray());
      }, []);
      const sessionTypeData = flat.reduce((set, obj) => {
        const name = "".concat(obj.vocabularyTitle, " > ").concat(obj.termTitle);
        let existing = set.findBy('label', name);

        if (!existing) {
          existing = {
            data: 0,
            label: name,
            meta: {
              sessions: [],
              vocabularyTitle: obj.vocabularyTitle
            }
          };
          set.pushObject(existing);
        }

        existing.data += obj.minutes;
        existing.meta.sessions.pushObject(obj.sessionTitle);
        return set;
      }, []);
      const totalMinutes = sessionTypeData.mapBy('data').reduce((total, minutes) => total + minutes, 0);
      const mappedWithLabel = sessionTypeData.map(obj => {
        const percent = (obj.data / totalMinutes * 100).toFixed(1);
        obj.label = "".concat(obj.label, " ").concat(percent, "%");
        obj.meta.totalMinutes = totalMinutes;
        obj.meta.percent = percent;
        return obj;
      });
      return mappedWithLabel;
    }),
    sortedData: Ember.computed('data.[]', async function () {
      const data = await this.get('data');
      data.sort((first, second) => {
        return first.meta.vocabularyTitle.localeCompare(second.meta.vocabularyTitle) || second.data - first.data;
      });
      return data;
    }),
    donutHover: (0, _emberConcurrency.task)(function* (obj) {
      yield (0, _emberConcurrency.timeout)(100);
      const isIcon = this.get('isIcon');

      if (isIcon || Ember.isEmpty(obj) || obj.empty) {
        this.set('tooltipTitle', null);
        this.set('tooltipContent', null);
        return;
      }

      const intl = this.get('intl');
      const {
        label,
        data,
        meta
      } = obj;
      const title = Ember.String.htmlSafe("".concat(label, " ").concat(data, " ").concat(intl.t('general.minutes')));
      const sessions = meta.sessions.uniq().sort().join();
      this.set('tooltipTitle', title);
      this.set('tooltipContent', sessions);
    }).restartable()
  });

  _exports.default = _default;
});