define("ilios-common/components/session-details", ["exports", "ilios-common/utils/scroll-to", "ilios-common/templates/components/session-details"], function (_exports, _scrollTo, _sessionDetails) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _sessionDetails.default,
    tagName: 'section',
    classNames: ['session-details'],
    sessionTypes: null,
    session: null,
    editable: false,
    sessionObjectiveDetails: null,
    sessionTaxonomyDetails: null,
    sessionLeadershipDetails: null,
    sessionManageLeadership: false,
    'data-test-session-details': true,

    didInsertElement() {
      (0, _scrollTo.default)("#".concat(this.element.id));
    },

    actions: {
      save() {
        const session = this.get('session');
        session.save();
      }

    }
  });

  _exports.default = _default;
});