define("ilios-common/helpers/intersection-count", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.intersectionCount = intersectionCount;
  _exports.default = void 0;

  function intersectionCount([a, b]
  /*, hash*/
  ) {
    if (!Ember.isArray(a) || !Ember.isArray(b) || Ember.isEmpty(a) || Ember.isEmpty(b)) {
      return 0;
    }

    if (a.length > b.length) {
      let tmp = a;
      a = b;
      b = tmp;
    }

    let count = 0;
    a.forEach(item => {
      if (b.includes(item)) {
        count++;
      }
    });
    return count;
  }

  var _default = Ember.Helper.extend({
    a: [],
    b: [],

    compute([a, b]) {
      this.set('a', a);
      this.set('b', b);
      return intersectionCount([a, b]);
    },

    /* eslint ember/no-observers: 0 */
    recomputeOnArrayChange: Ember.observer('a.[]', 'b.[]', function () {
      this.recompute();
    })
  });

  _exports.default = _default;
});