define("ilios-common/services/ajax", ["exports", "ember-ajax/services/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    reads
  } = Ember.computed;

  var _default = _ajax.default.extend({
    iliosConfig: Ember.inject.service(),
    session: Ember.inject.service(),
    host: reads('iliosConfig.apiHost'),
    headers: Ember.computed('session.isAuthenticated', 'session.data.authenticated.jwt', function () {
      let headers = {};

      if (this.session) {
        const jwt = this.session.get('data.authenticated.jwt');

        if (jwt) {
          headers['X-JWT-Authorization'] = "Token ".concat(jwt);
        }
      }

      return headers;
    })
  });

  _exports.default = _default;
});