define("ember-intl/models/translation", ["exports", "ember-intl/-private/empty-object"], function (_exports, _emptyObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const hasOwnProperty = Object.prototype.hasOwnProperty;

  function flatten(src) {
    const result = new _emptyObject.default();

    for (const key in src) {
      if (!hasOwnProperty.call(src, key)) {
        continue;
      }

      const value = src[key];

      if (typeof value === 'object' && value) {
        const hash = flatten(value);

        for (const suffix in hash) {
          result["".concat(key, ".").concat(suffix)] = hash[suffix];
        }
      } else {
        result[key] = value;
      }
    }

    return result;
  }

  const TranslationModel = Ember.Object.extend({
    localeName: null,

    init() {
      this._super();

      if (!this.translations) {
        this.translations = new _emptyObject.default();
      }
    },

    /**
     * Adds a translation hash
     */
    addTranslations(translations) {
      Ember.assign(this.translations, flatten(translations));
    },

    /**
     * Custom accessor hook that can be overridden.
     * This would enable consumers that have dot notated strings
     * to implement this function as `return this[key];`
     */
    getValue(key) {
      return this.translations[key];
    },

    /**
     * Determines if the translation model contains a key
     */
    has(key) {
      return hasOwnProperty.call(this.translations, key);
    }

  });
  var _default = TranslationModel;
  _exports.default = _default;
});