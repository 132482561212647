define("ilios-common/serializers/authentication", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    RESTSerializer
  } = _emberData.default;

  var _default = RESTSerializer.extend({
    isNewSerializerAPI: true,
    primaryKey: 'user',

    serialize(snapshot, options) {
      var json = this._super(snapshot, options); //don't persist empty passwords


      if (Ember.isEmpty(json.password)) {
        delete json.password;
      } //don't persist empty usernames


      if (Ember.isEmpty(json.username)) {
        delete json.username;
      }

      return json;
    }

  });

  _exports.default = _default;
});