define("elemental-calendar/helpers/formatted-date", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formattedDate = formattedDate;
  _exports.default = void 0;

  function formattedDate(params, hash = {}) {
    let output;
    let momentDate = (0, _moment.default)(params[0]);

    if (hash.type === 'weekly-header') {
      output = momentDate.format('ddd M/D');
    } else if (hash.type === 'monthly-day') {
      output = momentDate.format('D');
    } else if (hash.type === 'daily-header') {
      output = momentDate.format('dddd M/D');
    }

    return output;
  }

  var _default = Ember.Helper.helper(formattedDate);

  _exports.default = _default;
});