define("ilios-common/templates/components/detail-terms-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1f3Z6siL",
    "block": "{\"symbols\":[\"title\"],\"statements\":[[4,\"if\",[[24,[\"term\",\"description\",\"length\"]]],null,{\"statements\":[[4,\"attach-tooltip\",null,[[\"lazyRender\",\"animation\"],[true,\"shift\"]],{\"statements\":[[0,\"    \"],[1,[24,[\"term\",\"description\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[4,\"if\",[[24,[\"term\",\"isTopLevel\"]]],null,{\"statements\":[[0,\"  \"],[1,[24,[\"term\",\"title\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[28,\"await\",[[24,[\"term\",\"allParentTitles\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"muted\"],[8],[0,\"\\n      \"],[1,[23,1,[]],false],[0,\"\\n      »\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[1,[24,[\"term\",\"title\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[4,\"if\",[[28,\"and\",[[24,[\"canEdit\"]],[28,\"not\",[[28,\"await\",[[24,[\"term\",\"isActiveInTree\"]]],null]],null]],null]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"inactive\"],[8],[0,\"\\n    (\"],[1,[28,\"t\",[\"general.inactive\"],null],false],[0,\")\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"canEdit\"]]],null,{\"statements\":[[0,\"  \"],[5,\"fa-icon\",[[12,\"class\",\"remove\"]],[[\"@icon\"],[\"times\"]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/detail-terms-list-item.hbs"
    }
  });

  _exports.default = _default;
});