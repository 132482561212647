define("ilios-common/components/dashboard-materials", ["exports", "ilios-common/templates/components/dashboard-materials", "moment"], function (_exports, _dashboardMaterials, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    reads
  } = Ember.computed;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    commonAjax: Ember.inject.service(),
    iliosConfig: Ember.inject.service(),
    layout: _dashboardMaterials.default,
    daysInAdvance: 60,
    classNames: ['dashboard-materials'],
    namespace: reads('iliosConfig.apiNameSpace'),
    materials: Ember.computed('currentUser.currentUserId', async function () {
      const from = (0, _moment.default)().hour(0).minute(0).unix();
      const to = (0, _moment.default)().hour(23).minute(59).add(this.daysInAdvance, 'days').unix();
      const commonAjax = this.get('commonAjax');
      const currentUser = this.get('currentUser');
      const namespace = this.get('namespace');
      const userId = currentUser.get('currentUserId');
      let url = "".concat(namespace, "/usermaterials/").concat(userId, "?before=").concat(to, "&after=").concat(from);
      const data = await commonAjax.request(url);
      return data.userMaterials;
    }),
    actions: {
      sortString(a, b) {
        return a.localeCompare(b);
      }

    }
  });

  _exports.default = _default;
});