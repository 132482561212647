define("ember-simple-charts/templates/components/simple-chart-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6QPwSxna",
    "block": "{\"symbols\":[\"@title\",\"&default\",\"@target\"],\"statements\":[[4,\"ember-popper\",null,[[\"class\",\"placement\",\"popperTarget\"],[\"simple-chart-tooltip\",\"top\",[23,3,[]]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"div\",true],[10,\"class\",\"body\"],[8],[14,2],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-simple-charts/templates/components/simple-chart-tooltip.hbs"
    }
  });

  _exports.default = _default;
});