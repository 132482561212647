define("ilios-common/mixins/print-course-controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    currentUser: Ember.inject.service(),
    queryParams: ['unpublished'],
    unpublished: false,
    canViewUnpublished: false
  });

  _exports.default = _default;
});