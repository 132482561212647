define("ilios-common/models/session-description", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo,
    Model
  } = _emberData.default;

  var _default = Model.extend({
    description: attr('string'),
    session: belongsTo('session', {
      async: true
    }),
    textDescription: Ember.computed('description', function () {
      var title = this.get('description');

      if (title === undefined) {
        return '';
      }

      return title.replace(/(<([^>]+)>)/ig, "");
    })
  });

  _exports.default = _default;
});