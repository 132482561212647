define("ember-simple-charts/components/simple-chart", ["exports", "ember-concurrency", "ember-simple-charts/templates/components/simple-chart"], function (_exports, _emberConcurrency, _simpleChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEBOUNCE_MS = 100;

  var _default = Ember.Component.extend({
    layout: _simpleChart.default,
    classNames: ['simple-chart'],
    tagName: 'div',
    mouse: (0, _emberConcurrency.taskGroup)().restartable(),
    chartName: Ember.computed('type', function () {
      const name = Ember.get(this, 'name');
      return "simple-chart-".concat(name);
    }),
    isClickable: Ember.computed('click', function () {
      const click = Ember.get(this, 'click');
      return Ember.isPresent(click);
    }),
    handleHover: (0, _emberConcurrency.task)(function* (data, tooltipTarget) {
      const hover = Ember.get(this, 'hover');
      yield (0, _emberConcurrency.timeout)(DEBOUNCE_MS);

      if (hover) {
        try {
          yield hover(data);

          if (!(Ember.get(this, 'isDestroyed') || Ember.get(this, 'isDestroying'))) {
            Ember.set(this, 'tooltipTarget', tooltipTarget);
          }
        } catch (e) {//we will just ignore errors here since the mouse state is transient
        }
      }
    }).group('mouse'),
    handleLeave: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(DEBOUNCE_MS);
      const leave = Ember.get(this, 'leave');

      if (leave) {
        try {
          yield leave();

          if (!(Ember.get(this, 'isDestroyed') || Ember.get(this, 'isDestroying'))) {
            Ember.set(this, 'tooltipTarget', null);
          }
        } catch (e) {//we will just ignore errors here since the mouse state is transient
        }
      }
    }).group('mouse'),
    handleClick: (0, _emberConcurrency.task)(function* (data) {
      const click = Ember.get(this, 'click');

      if (click) {
        try {
          yield click(data);

          if (!(Ember.get(this, 'isDestroyed') || Ember.get(this, 'isDestroying'))) {
            Ember.set(this, 'tooltipTarget', null);
          }
        } catch (e) {//we will just ignore errors here since the mouse state is transient
        }
      }
    }).group('mouse')
  });

  _exports.default = _default;
});