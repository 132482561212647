define("ilios-common/routes/events", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    userEvents: Ember.inject.service(),
    schoolEvents: Ember.inject.service(),

    model(params) {
      let slug = params.slug;
      let container = slug.substring(0, 1);
      let eventService;

      if (container === 'S') {
        eventService = this.get('schoolEvents');
      }

      if (container === 'U') {
        eventService = this.get('userEvents');
      }

      return eventService.getEventForSlug(slug);
    }

  });

  _exports.default = _default;
});