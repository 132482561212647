define("ilios-common/components/ilios-course-details", ["exports", "ilios-common/templates/components/ilios-course-details", "ilios-common/utils/scroll-to"], function (_exports, _iliosCourseDetails, _scrollTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _iliosCourseDetails.default,
    tagName: 'section',
    classNames: ['course-details'],
    course: null,
    editable: false,
    showDetails: null,
    courseObjectiveDetails: null,
    courseTaxonomyDetails: null,
    courseCompetencyDetails: null,
    'data-test-ilios-course-details': true,
    actions: {
      collapse() {
        this.get('setShowDetails')(false); //when the button is clicked to collapse, animate the focus to the top of the page

        (0, _scrollTo.default)("body");
      }

    }
  });

  _exports.default = _default;
});