define('ember-require-module/index', ['exports', 'require'], function (exports, _require2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = requireModule;
  function requireModule(module) {
    let exportName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'default';

    if (_require2.default.has(module)) {
      return (0, _require2.default)(module)[exportName];
    }
  }
});