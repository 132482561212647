define("ilios-common/components/visualizer-course-session-types", ["exports", "ember-concurrency", "ilios-common/templates/components/visualizer-course-session-types"], function (_exports, _emberConcurrency, _visualizerCourseSessionTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    layout: _visualizerCourseSessionTypes.default,
    course: null,
    isIcon: false,
    chartType: 'horz-bar',
    classNameBindings: ['isIcon::not-icon', ':visualizer-course-session-types'],
    tooltipContent: null,
    tooltipTitle: null,
    data: Ember.computed('course.sessions.[]', async function () {
      const course = this.get('course');
      const sessions = await course.get('sessions');
      const dataMap = await Ember.RSVP.map(sessions.toArray(), async session => {
        const sessionType = await session.get('sessionType');
        const hours = await session.get('totalSumDuration');
        const minutes = Math.round(hours * 60);
        return {
          sessionTitle: session.get('title'),
          sessionTypeTitle: sessionType.get('title'),
          sessionTypeId: sessionType.get('id'),
          minutes
        };
      });
      const mappedSessionTypes = dataMap.reduce((set, obj) => {
        let existing = set.findBy('label', obj.sessionTypeTitle);

        if (!existing) {
          existing = {
            data: 0,
            label: obj.sessionTypeTitle,
            meta: {
              sessionType: obj.sessionTypeTitle,
              sessionTypeId: obj.sessionTypeId,
              sessions: []
            }
          };
          set.pushObject(existing);
        }

        existing.data += obj.minutes;
        existing.meta.sessions.pushObject(obj.sessionTitle);
        return set;
      }, []);
      const totalMinutes = mappedSessionTypes.mapBy('data').reduce((total, minutes) => total + minutes, 0);
      const mappedSessionTypesWithLabel = mappedSessionTypes.map(obj => {
        const percent = (obj.data / totalMinutes * 100).toFixed(1);
        obj.label = "".concat(obj.meta.sessionType, " ").concat(percent, "%");
        obj.meta.totalMinutes = totalMinutes;
        obj.meta.percent = percent;
        return obj;
      });
      return mappedSessionTypesWithLabel;
    }),
    sortedData: Ember.computed('data.[]', async function () {
      const data = await this.get('data');
      data.sort((first, second) => {
        return first.data - second.data;
      });
      return data;
    }),
    actions: {
      barClick(obj) {
        const course = this.get('course');
        const isIcon = this.get('isIcon');
        const router = this.get('router');

        if (isIcon || Ember.isEmpty(obj) || obj.empty || Ember.isEmpty(obj.meta)) {
          return;
        }

        router.transitionTo('course-visualize-session-type', course.get('id'), obj.meta.sessionTypeId);
      }

    },
    barHover: (0, _emberConcurrency.task)(function* (obj) {
      yield (0, _emberConcurrency.timeout)(100);
      const isIcon = this.get('isIcon');

      if (isIcon || Ember.isEmpty(obj) || obj.empty) {
        this.set('tooltipTitle', null);
        this.set('tooltipContent', null);
        return;
      }

      const intl = this.get('intl');
      const {
        label,
        data,
        meta
      } = obj;
      const title = Ember.String.htmlSafe("".concat(label, " ").concat(data, " ").concat(intl.t('general.minutes')));
      const sessions = meta.sessions.uniq().sort().join();
      this.set('tooltipTitle', title);
      this.set('tooltipContent', sessions);
    }).restartable()
  });

  _exports.default = _default;
});