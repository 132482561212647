define("ilios-common/templates/components/detail-cohort-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6Ey+22lD",
    "block": "{\"symbols\":[\"cohort\",\"cohort\"],\"statements\":[[7,\"ul\",true],[10,\"class\",\"selected-cohorts\"],[8],[0,\"\\n\"],[4,\"each\",[[28,\"sort-by\",[\"title\",[24,[\"selectedCohorts\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"li\",false],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],\"remove\",[23,2,[]]]],[8],[0,\"\\n      \"],[1,[23,2,[\"programYear\",\"program\",\"school\",\"title\"]],false],[0,\"\\n      |\\n      \"],[1,[23,2,[\"programYear\",\"program\",\"title\"]],false],[0,\"\\n      |\\n      \"],[1,[23,2,[\"title\"]],false],[0,\"\\n      |\\n      \"],[5,\"fa-icon\",[[12,\"class\",\"remove\"]],[[\"@icon\"],[\"times\"]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[9],[0,\"\\n\"],[7,\"ul\",true],[10,\"class\",\"selectable-cohorts\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"is-fulfilled\",[[24,[\"sortedAvailableCohorts\"]]],null]],null,{\"statements\":[[4,\"each\",[[28,\"await\",[[24,[\"sortedAvailableCohorts\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"li\",false],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],\"add\",[23,1,[]]]],[8],[0,\"\\n        \"],[1,[23,1,[\"programYear\",\"program\",\"school\",\"title\"]],false],[0,\"\\n        |\\n        \"],[1,[23,1,[\"programYear\",\"program\",\"title\"]],false],[0,\"\\n        |\\n        \"],[1,[23,1,[\"title\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"li\",true],[8],[0,\"\\n      \"],[5,\"loading-spinner\",[],[[],[]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/detail-cohort-manager.hbs"
    }
  });

  _exports.default = _default;
});