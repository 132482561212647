define("ilios-common/components/dashboard-week", ["exports", "ilios-common/templates/components/dashboard-week", "moment"], function (_exports, _dashboardWeek, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _dashboardWeek.default,
    classNames: ['dashboard-week'],
    'data-test-dashboard-week': true,
    expanded: Ember.computed(function () {
      const lastSunday = (0, _moment.default)().day(1).subtract(1, 'week').format('W');
      const thisSunday = (0, _moment.default)().day(1).format('W');
      const nextSunday = (0, _moment.default)().day(1).add(1, 'week').format('W');
      return "".concat(lastSunday, "-").concat(thisSunday, "-").concat(nextSunday);
    }),
    year: Ember.computed(function () {
      return (0, _moment.default)().year();
    }),
    week: Ember.computed(function () {
      return (0, _moment.default)().isoWeek();
    })
  });

  _exports.default = _default;
});