define("ilios-common/services/user-events", ["exports", "ilios-common/mixins/events", "moment"], function (_exports, _events, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_events.default, {
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    session: Ember.inject.service(),
    commonAjax: Ember.inject.service(),
    iliosConfig: Ember.inject.service(),
    namespace: Ember.computed.reads('iliosConfig.apiNameSpace'),

    /**
     * Retrieves a list of user-events for the current user that occur in a given date range, sorted by start dates.
     * @method getEvents
     * @param {int} from
     * @param {int} to
     * @return {Promise.<Array>}
     */
    async getEvents(from, to) {
      const user = await this.get('currentUser.model');

      if (!user) {
        return [];
      }

      let url = '';
      const namespace = this.get('namespace');

      if (namespace) {
        url += '/' + namespace;
      }

      url += '/userevents/' + user.get('id') + '?from=' + from + '&to=' + to;
      const commonAjax = this.get('commonAjax');
      const data = await commonAjax.request(url);
      return data.userEvents.map(obj => this.createEventFromData(obj, true)).sortBy('startDate', 'name');
    },

    /**
     * Retrieves and event by it's given slug.
     * @method getEventForSlug
     * @param {String} slug
     * @return {Promise.<Object>}
     */
    async getEventForSlug(slug) {
      const from = (0, _moment.default)(slug.substring(1, 9), 'YYYYMMDD').hour(0);
      const to = from.clone().hour(24);
      const type = slug.substring(9, 10);
      const id = parseInt(slug.substring(10), 10);
      const events = await this.getEvents(from.unix(), to.unix());
      return events.find(event => {
        if (type === 'O') {
          return parseInt(event.offering, 10) === id;
        }

        if (type === 'I') {
          return parseInt(event.ilmSession, 10) === id;
        }
      });
    },

    /**
     * Generates a slug for a given event.
     * @method getSlugForEvent
     * @param {Object} event
     * @return {String}
     */
    getSlugForEvent(event) {
      let slug = 'U';
      slug += (0, _moment.default)(event.startDate).format('YYYYMMDD');

      if (event.offering) {
        slug += 'O' + event.offering;
      }

      if (event.ilmSession) {
        slug += 'I' + event.ilmSession;
      }

      return slug;
    }

  });

  _exports.default = _default;
});