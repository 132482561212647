define("ilios-common/components/new-session", ["exports", "ember-cp-validations", "ilios-common/mixins/validation-error-display", "ember-concurrency", "ilios-common/templates/components/new-session"], function (_exports, _emberCpValidations, _validationErrorDisplay, _emberConcurrency, _newSession) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    title: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 3,
      max: 200,
      descriptionKey: 'general.title'
    })]
  });

  var _default = Ember.Component.extend(_validationErrorDisplay.default, Validations, {
    store: Ember.inject.service(),
    layout: _newSession.default,
    classNames: ['new-session'],
    sessionTypes: null,
    title: null,
    selectedSessionTypeId: null,
    isSaving: false,
    'data-test-new-session': true,
    activeSessionTypes: Ember.computed('sessionTypes.[]', async function () {
      const sessionTypes = await this.get('sessionTypes');
      return sessionTypes.filterBy('active', true);
    }),
    selectedSessionType: Ember.computed('activeSessionTypes.[]', 'selectedSessionTypeId', async function () {
      let selectedSessionType;
      const sessionTypes = await this.sessionTypes;
      const selectedSessionTypeId = this.selectedSessionTypeId;

      if (Ember.isPresent(selectedSessionTypeId)) {
        selectedSessionType = sessionTypes.find(sessionType => {
          return parseInt(sessionType.id, 10) === parseInt(selectedSessionTypeId, 10);
        });
      }

      if (Ember.isEmpty(selectedSessionType)) {
        // try and default to a type names 'Lecture';
        selectedSessionType = sessionTypes.find(sessionType => sessionType.title === 'Lecture');
      }

      if (Ember.isEmpty(selectedSessionType)) {
        selectedSessionType = sessionTypes.firstObject;
      }

      return selectedSessionType;
    }),
    saveNewSession: (0, _emberConcurrency.task)(function* () {
      const save = this.get('save');
      this.send('addErrorDisplayFor', 'title');
      const {
        validations
      } = yield this.validate();

      if (validations.get('isValid')) {
        const sessionType = yield this.get('selectedSessionType');
        let session = this.get('store').createRecord('session', {
          title: this.get('title'),
          sessionType
        });
        yield save(session);
        this.cancel();
      }
    }),

    keyUp(event) {
      const keyCode = event.keyCode;
      const target = event.target;

      if ('text' !== target.type) {
        return;
      }

      if (13 === keyCode) {
        this.get('saveNewSession').perform();
        return;
      }

      if (27 === keyCode) {
        this.cancel();
      }
    }

  });

  _exports.default = _default;
});