define("ilios-common/components/course-sessions", ["exports", "ember-concurrency", "ilios-common/templates/components/course-sessions"], function (_exports, _emberConcurrency, _courseSessions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    permissionChecker: Ember.inject.service(),
    layout: _courseSessions.default,
    tagName: 'section',
    classNames: ['course-sessions'],
    'data-test-course-sessions': true,
    course: null,
    canCreateSession: false,
    canUpdateCourse: false,
    sortBy: null,
    filterBy: null,
    expandedSessionIds: null,
    sessionsCount: Ember.computed('course.sessions.[]', function () {
      const course = this.get('course');
      const sessionIds = course.hasMany('sessions').ids();
      return sessionIds.length;
    }),
    sessions: Ember.computed('course.sessions.[]', async function () {
      const course = this.get('course');
      return await course.get('sessions');
    }),
    sessionsWithOfferings: Ember.computed('sessions.[]', async function () {
      const sessions = await this.get('sessions');
      return sessions.filter(session => {
        const ids = session.hasMany('offerings').ids();
        return ids.length > 0;
      });
    }),
    sessionObjects: Ember.computed('course', 'sessions.[]', async function () {
      const intl = this.get('intl');
      const permissionChecker = this.get('permissionChecker');
      const course = this.get('course');
      const sessions = await this.get('sessions');
      const sessionObjects = await Ember.RSVP.map(sessions.toArray(), async session => {
        const canDelete = await permissionChecker.canDeleteSession(session);
        const canUpdate = await permissionChecker.canUpdateSession(session);
        const postrequisite = await session.postrequisite;
        let sessionObject = {
          session,
          course,
          canDelete,
          canUpdate,
          postrequisite,
          id: session.get('id'),
          title: session.get('title'),
          instructionalNotes: session.get('instructionalNotes'),
          isPublished: session.get('isPublished'),
          isNotPublished: session.get('isNotPublished'),
          isScheduled: session.get('isScheduled')
        };
        const sessionType = await session.get('sessionType');
        sessionObject.sessionTypeTitle = sessionType.get('title');
        const ilmSession = await session.get('ilmSession');

        if (ilmSession) {
          sessionObject.isIlm = true;
          sessionObject.firstOfferingDate = ilmSession.get('dueDate');
        } else {
          sessionObject.isIlm = false;
          const firstOfferingDate = await session.get('firstOfferingDate');
          sessionObject.firstOfferingDate = firstOfferingDate;
        }

        const offerings = await session.get('offerings');
        sessionObject.offeringCount = offerings.length;
        sessionObject.objectiveCount = session.hasMany('objectives').ids().length;
        sessionObject.termCount = session.hasMany('terms').ids().length;
        const offeringLearerGroupCount = offerings.reduce((total, offering) => {
          let count = offering.hasMany('learnerGroups').ids().length;
          return total + count;
        }, 0);
        let ilmLearnerGroupCount = 0;

        if (ilmSession) {
          const learnerGroupIds = ilmSession.hasMany('learnerGroups').ids();
          ilmLearnerGroupCount = learnerGroupIds.length;
        }

        const learnerGroupCount = offeringLearerGroupCount + ilmLearnerGroupCount;
        sessionObject.learnerGroupCount = learnerGroupCount;
        let status = intl.t('general.notPublished');

        if (session.get('isPublished')) {
          sessionObject.isPublished = true;
          status = intl.t('general.published');
        }

        if (session.get('publishedAsTbd')) {
          sessionObject.publishedAsTbd = true;
          status = intl.t('general.scheduled');
        }

        sessionObject.status = status.toString();
        sessionObject.searchString = sessionObject.title + sessionObject.sessionTypeTitle + sessionObject.status;
        return sessionObject;
      });
      return sessionObjects;
    }),
    sessionTypes: Ember.computed('course.school.sessionTypes.[]', async function () {
      const course = this.get('course');
      const school = await course.get('school');
      const sessionTypes = await school.get('sessionTypes');
      return sessionTypes;
    }),
    filterByDebounced: Ember.computed('filterByLocalCache', 'filterBy', function () {
      const filterBy = this.get('filterBy');
      const filterByLocalCache = this.get('filterByLocalCache');
      const changeFilterBy = this.get('changeFilterBy');

      if (changeFilterBy.get('isIdle')) {
        return filterBy;
      }

      return filterByLocalCache;
    }),

    init() {
      this._super(...arguments);

      this.set('expandedSessionIds', []);
    },

    saveSession: (0, _emberConcurrency.task)(function* (session) {
      const course = this.get('course');
      session.set('course', course);
      return yield session.save();
    }),
    expandSession: (0, _emberConcurrency.task)(function* (session) {
      yield (0, _emberConcurrency.timeout)(1);
      this.expandedSessionIds.pushObject(session.id);
    }),
    closeSession: (0, _emberConcurrency.task)(function* (session) {
      yield (0, _emberConcurrency.timeout)(1);
      this.expandedSessionIds.removeObject(session.id);
    }),
    toggleExpandAll: (0, _emberConcurrency.task)(function* () {
      const sessionsWithOfferings = yield this.get('sessionsWithOfferings');

      if (this.expandedSessionIds.length === sessionsWithOfferings.length) {
        this.set('expandedSessionIds', []);
      } else {
        const ids = sessionsWithOfferings.mapBy('id');
        this.set('expandedSessionIds', ids);
      }
    }).drop(),
    changeFilterBy: (0, _emberConcurrency.task)(function* (value) {
      const setFilterBy = this.get('setFilterBy');
      this.set('filterByLocalCache', value);
      yield (0, _emberConcurrency.timeout)(250);
      setFilterBy(value);
    }).restartable()
  });

  _exports.default = _default;
});