define("ilios-common/templates/components/course-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W2g7Gt+g",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"has-route\",[\"courses\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"back-to-courses-list\"],[8],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\"],[\"courses\"]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"general.backToCourses\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\",true],[10,\"class\",\"box\"],[8],[0,\"\\n  \"],[7,\"div\",true],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/course-loading.hbs"
    }
  });

  _exports.default = _default;
});