define("ilios-common/components/visualizer-course-term", ["exports", "ember-concurrency", "ilios-common/templates/components/visualizer-course-term"], function (_exports, _emberConcurrency, _visualizerCourseTerm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    layout: _visualizerCourseTerm.default,
    course: null,
    term: null,
    isIcon: false,
    classNameBindings: ['isIcon::not-icon', ':visualizer-course-term'],
    tooltipContent: null,
    tooltipTitle: null,
    data: Ember.computed('course.sessions.[]', 'term', async function () {
      const course = this.get('course');
      const term = this.get('term');
      const courseSessions = await course.get('sessions');
      const termSessionIds = term.hasMany('sessions').ids();
      const sessions = courseSessions.filter(session => termSessionIds.includes(session.get('id')));
      const sessionTypeData = await Ember.RSVP.map(sessions, async session => {
        const hours = await session.get('totalSumDuration');
        const minutes = Math.round(hours * 60);
        const sessionType = await session.get('sessionType');
        return {
          sessionTitle: session.get('title'),
          sessionTypeTitle: sessionType.get('title'),
          minutes
        };
      });
      const data = sessionTypeData.reduce((set, obj) => {
        let existing = set.findBy('label', obj.sessionTypeTitle);

        if (!existing) {
          existing = {
            data: 0,
            label: obj.sessionTypeTitle,
            meta: {
              sessionTypeTitle: obj.sessionTypeTitle,
              sessions: []
            }
          };
          set.pushObject(existing);
        }

        existing.data += obj.minutes;
        existing.meta.sessions.pushObject(obj.sessionTitle);
        return set;
      }, []);
      const totalMinutes = data.mapBy('data').reduce((total, minutes) => total + minutes, 0);
      const mappedTermsWithLabel = data.map(obj => {
        const percent = (obj.data / totalMinutes * 100).toFixed(1);
        obj.label = "".concat(obj.meta.sessionTypeTitle, " ").concat(percent, "%");
        obj.meta.totalMinutes = totalMinutes;
        obj.meta.percent = percent;
        return obj;
      });
      return mappedTermsWithLabel;
    }),
    barHover: (0, _emberConcurrency.task)(function* (obj) {
      yield (0, _emberConcurrency.timeout)(100);
      const isIcon = this.get('isIcon');

      if (isIcon || Ember.isEmpty(obj) || obj.empty) {
        this.set('tooltipTitle', null);
        this.set('tooltipContent', null);
        return;
      }

      const intl = this.get('intl');
      const {
        label,
        data,
        meta
      } = obj;
      const title = Ember.String.htmlSafe("".concat(label, " ").concat(data, " ").concat(intl.t('general.minutes')));
      const sessions = meta.sessions.uniq().sort().join(', ');
      this.set('tooltipTitle', title);
      this.set('tooltipContent', sessions);
    }).restartable()
  });

  _exports.default = _default;
});