define('ember-invoke-action/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const makeInvokeAction = function makeInvokeAction() {
    var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        _ref$strict = _ref.strict;

    let strict = _ref$strict === undefined ? false : _ref$strict;

    return function (object, actionName) {
      for (var _len = arguments.length, args = Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      (false && !(typeof object === 'object') && Ember.assert('The first argument passed to invokeAction must be an object', typeof object === 'object'));


      let action;
      if (typeof actionName === 'string') {
        action = Ember.get(object, actionName);
      } else if (typeof actionName === 'function') {
        action = actionName;
      } else {
        (false && !(false) && Ember.assert('The second argument passed to invokeAction must be a string as actionName or a function', false));
      }

      if (typeof action === 'string') {
        object.sendAction(actionName, ...args);
      } else if (typeof action === 'function') {
        return action(...args);
      } else if (strict) {
        (false && !(false) && Ember.assert(`No invokable action ${actionName} was found`, false));
      }
    };
  };

  const getActions = object => {
    return object.actions ? object.actions : object._actions;
  };

  const makeInvoke = function makeInvoke() {
    var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        _ref2$strict = _ref2.strict;

    let strict = _ref2$strict === undefined ? false : _ref2$strict;

    return function (object, actionName) {
      for (var _len2 = arguments.length, args = Array(_len2 > 2 ? _len2 - 2 : 0), _key2 = 2; _key2 < _len2; _key2++) {
        args[_key2 - 2] = arguments[_key2];
      }

      let actions = getActions(object);
      let action = actions && actions[actionName];

      if (typeof action === 'function') {
        return action.call(object, ...args);
      } else if (strict) {
        (false && !(false) && Ember.assert(`No invokable action ${actionName} was found`, false));
      }
    };
  };

  const invokeAction = exports.invokeAction = makeInvokeAction();
  const strictInvokeAction = exports.strictInvokeAction = makeInvokeAction({ strict: true });

  const invoke = exports.invoke = makeInvoke();
  const strictInvoke = exports.strictInvoke = makeInvoke({ strict: true });

  const InvokeActionMixin = exports.InvokeActionMixin = Ember.Mixin.create({
    invokeAction() {
      return invokeAction(this, ...arguments);
    },

    strictInvokeAction() {
      return strictInvokeAction(this, ...arguments);
    },

    invoke() {
      return invoke(this, ...arguments);
    },

    strictInvoke() {
      return strictInvoke(this, ...arguments);
    }
  });

  exports.default = invokeAction;
});