define("ilios-common/components/detail-learnergroups-list", ["exports", "ilios-common/templates/components/detail-learnergroups-list"], function (_exports, _detailLearnergroupsList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _detailLearnergroupsList.default,
    classNames: ['detail-learnergroups-list'],
    learnerGroups: null,
    isManaging: false,

    /**
     * A list of objects, each representing a learner-group tree.
     * Each tree object contains its top-level group and a flat list of all its groups.
     * @property trees
     * @type {Ember.computed}
     * @public
     */
    trees: Ember.computed('learnerGroups.[]', async function () {
      const learnerGroups = this.get('learnerGroups');

      if (Ember.isEmpty(learnerGroups)) {
        return [];
      }

      const topLevelGroups = await Ember.RSVP.all(learnerGroups.toArray().mapBy('topLevelGroup'));
      return await Ember.RSVP.map(topLevelGroups.uniq(), async topLevelGroup => {
        const groups = await Ember.RSVP.filter(learnerGroups.toArray(), async learnerGroup => {
          const thisGroupsTopLevelGroup = await learnerGroup.get('topLevelGroup');
          return thisGroupsTopLevelGroup === topLevelGroup;
        });
        const sortProxies = await Ember.RSVP.map(groups, async group => {
          const sortTitle = await group.get('sortTitle');
          return Ember.Object.create({
            group,
            sortTitle
          });
        });
        return Ember.Object.create({
          topLevelGroup,
          groups: sortProxies.sortBy('sortTitle').mapBy('group')
        });
      });
    }),
    lowestLeaves: Ember.computed('learnerGroups.[]', async function () {
      const learnerGroups = this.learnerGroups;
      const ids = learnerGroups.mapBy('id');

      if (Ember.isEmpty(learnerGroups)) {
        return [];
      }

      return await Ember.RSVP.filter(learnerGroups.toArray(), async group => {
        const children = await group.allDescendants;
        const selectedChildren = children.filter(child => ids.includes(child.id));
        return selectedChildren.length === 0;
      });
    })
  });

  _exports.default = _default;
});