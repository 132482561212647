define("ilios-common/components/single-event-learningmaterial-list", ["exports", "ilios-common/templates/components/single-event-learningmaterial-list"], function (_exports, _singleEventLearningmaterialList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _singleEventLearningmaterialList.default,
    classNames: ['single-event-learningmaterial-list'],
    learningMaterials: null
  });

  _exports.default = _default;
});