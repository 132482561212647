define("ilios-common/components/ilios-calendar-week", ["exports", "moment", "ilios-common/templates/components/ilios-calendar-week"], function (_exports, _moment, _iliosCalendarWeek) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _iliosCalendarWeek.default,
    classNames: ['ilios-calendar-week'],
    date: null,
    calendarEvents: null,
    areEventsSelectable: true,
    areDaysSelectable: true,
    weekOf: Ember.computed('date', function () {
      return (0, _moment.default)(this.get('date')).startOf('week').format('MMMM Do YYYY');
    }),
    ilmPreWorkEvents: Ember.computed('calendarEvents.[]', function () {
      const calendarEvents = this.calendarEvents || [];
      const preWork = calendarEvents.reduce((arr, eventObject) => {
        return arr.pushObjects(eventObject.prerequisites);
      }, []);
      return preWork.filter(ev => ev.ilmSession);
    }),
    nonIlmPreWorkEvents: Ember.computed('calendarEvents.[]', function () {
      const calendarEvents = this.calendarEvents || [];
      return calendarEvents.filter(ev => {
        return ev.postrequisites.length === 0 || !ev.ilmSession;
      });
    }),
    singleDayEvents: Ember.computed('nonIlmPreWorkEvents.[]', function () {
      const events = this.get('nonIlmPreWorkEvents');

      if (Ember.isEmpty(events)) {
        return [];
      }

      return events.filter(event => (0, _moment.default)(event.startDate).isSame((0, _moment.default)(event.endDate), 'day'));
    }),
    multiDayEventsList: Ember.computed('nonIlmPreWorkEvents.[]', function () {
      const events = this.get('nonIlmPreWorkEvents');

      if (Ember.isEmpty(events)) {
        return [];
      }

      return events.filter(event => !(0, _moment.default)(event.startDate).isSame((0, _moment.default)(event.endDate), 'day'));
    }),

    didInsertElement() {
      Ember.run.next(() => {
        if (!this.isDestroyed && !this.isDestroying && this.element) {
          this.element.querySelector(".el-calendar .week").scrollTop = 500;
        }
      });
    },

    actions: {
      changeToDayView(date) {
        const changeDate = this.get('changeDate');
        const changeView = this.get('changeView');
        const areDaysSelectable = this.get('areDaysSelectable');

        if (areDaysSelectable && changeDate && changeView) {
          changeDate(date);
          changeView('day');
        }
      },

      selectEvent(evt) {
        const selectEvent = this.get('selectEvent');
        const areEventsSelectable = this.get('areEventsSelectable');

        if (areEventsSelectable && selectEvent) {
          selectEvent(evt);
        }
      }

    }
  });

  _exports.default = _default;
});