define("ilios-common/components/sessions-grid-loading", ["exports", "ilios-common/templates/components/sessions-grid-loading"], function (_exports, _sessionsGridLoading) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _sessionsGridLoading.default,
    classNames: ['sessions-grid-loading'],
    attributeBindings: ['ariaHidden:aria-hidden', 'role'],
    ariaHidden: 'true',
    role: 'presentation'
  });

  _exports.default = _default;
});