define("ilios-common/components/course-editing", ["exports", "ilios-common/templates/components/course-editing"], function (_exports, _courseEditing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _courseEditing.default,
    editable: false,
    courseObjectiveDetails: false,
    courseTaxonomyDetails: false,
    courseCompetencyDetails: false,
    actions: {
      save() {
        const course = this.get('course');
        course.save();
      }

    }
  });

  _exports.default = _default;
});