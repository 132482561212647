define("ilios-common/components/sessions-grid-last-updated", ["exports", "ilios-common/templates/components/sessions-grid-last-updated", "moment"], function (_exports, _sessionsGridLastUpdated, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['sessions-grid-last-updated'],
    layout: _sessionsGridLastUpdated.default,
    session: null,
    updatedAt: null,

    didReceiveAttrs() {
      this._super(...arguments);

      this.set('updatedAt', (0, _moment.default)(this.get('session.updatedAt')).format("L LT"));
    }

  });

  _exports.default = _default;
});