define("ilios-common/validators/html-presence", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    validate(value, options) {
      let text = value || '';
      let noTagsText = text.replace(/(<([^>]+)>)/ig, "");
      let strippedText = noTagsText.replace(/&nbsp;/ig, "").replace(/\s/g, "");

      if (Ember.isEmpty(strippedText)) {
        return this.createErrorMessage('blank', value, options);
      }

      return true;
    }

  });

  _exports.default = _default;
});