define("ilios-common/templates/components/leadership-collapsed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mTcnc8XR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",false],[12,\"class\",\"title clickable\"],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],[24,[\"expand\"]]]],[8],[0,\"\\n  \"],[1,[22,\"title\"],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[7,\"table\",true],[10,\"class\",\"condensed\"],[8],[0,\"\\n    \"],[7,\"thead\",true],[8],[0,\"\\n      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"th\",true],[10,\"colspan\",\"2\"],[10,\"class\",\"text-left\"],[8],[0,\"\\n          \"],[1,[28,\"t\",[\"general.summary\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"showDirectors\"]]],null,{\"statements\":[[0,\"        \"],[7,\"tr\",true],[8],[0,\"\\n          \"],[7,\"td\",true],[8],[0,\"\\n            \"],[1,[28,\"t\",[\"general.directors\"],null],false],[0,\"\\n          \"],[9],[0,\"\\n          \"],[7,\"td\",true],[8],[0,\"\\n            \"],[1,[28,\"t\",[\"general.directorCount\"],[[\"count\"],[[24,[\"directorsCount\"]]]]],false],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"showAdministrators\"]]],null,{\"statements\":[[0,\"        \"],[7,\"tr\",true],[8],[0,\"\\n          \"],[7,\"td\",true],[8],[0,\"\\n            \"],[1,[28,\"t\",[\"general.administrators\"],null],false],[0,\"\\n          \"],[9],[0,\"\\n          \"],[7,\"td\",true],[8],[0,\"\\n            \"],[1,[28,\"t\",[\"general.administratorCount\"],[[\"count\"],[[24,[\"administratorsCount\"]]]]],false],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/leadership-collapsed.hbs"
    }
  });

  _exports.default = _default;
});