define("ilios-common/routes/mymaterials", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    commonAjax: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    iliosConfig: Ember.inject.service(),
    host: Ember.computed.reads('iliosConfig.apiHost'),
    namespace: Ember.computed.reads('iliosConfig.apiNameSpace'),

    model() {
      return {
        materials: this.fetchModelData()
      };
    },

    async fetchModelData() {
      const commonAjax = this.commonAjax;
      const host = this.host;
      const namespace = this.namespace;
      const user = await this.currentUser.model;
      const url = "".concat(host, "/").concat(namespace, "/usermaterials/").concat(user.id);
      const data = await commonAjax.request(url);
      return data.userMaterials;
    }

  });

  _exports.default = _default;
});