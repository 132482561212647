define("ilios-common/components/visualizer-course-vocabularies", ["exports", "ember-concurrency", "ilios-common/templates/components/visualizer-course-vocabularies"], function (_exports, _emberConcurrency, _visualizerCourseVocabularies) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    layout: _visualizerCourseVocabularies.default,
    course: null,
    isIcon: false,
    classNameBindings: ['isIcon::not-icon', ':visualizer-course-vocabularies'],
    tooltipContent: null,
    tooltipTitle: null,
    data: Ember.computed('course.sessions.[]', async function () {
      const course = this.get('course');
      const sessions = await course.get('sessions');
      const dataMap = await Ember.RSVP.map(sessions.toArray(), async session => {
        const terms = await session.get('terms');
        const vocabularies = await Ember.RSVP.all(terms.mapBy('vocabulary'));
        const hours = await session.get('totalSumDuration');
        const minutes = Math.round(hours * 60);
        return {
          sessionTitle: session.get('title'),
          vocabularies,
          minutes
        };
      });
      const data = dataMap.reduce((set, obj) => {
        obj.vocabularies.forEach(vocabulary => {
          const vocabularyTitle = vocabulary.get('title');
          let existing = set.findBy('label', vocabularyTitle);

          if (!existing) {
            existing = {
              data: 0,
              label: vocabularyTitle,
              meta: {
                vocabulary,
                sessions: []
              }
            };
            set.pushObject(existing);
          }

          existing.data += obj.minutes;
          existing.meta.sessions.pushObject(obj.sessionTitle);
        });
        return set;
      }, []);
      return data;
    }),
    actions: {
      donutClick(obj) {
        const course = this.get('course');
        const isIcon = this.get('isIcon');
        const router = this.get('router');

        if (isIcon || Ember.isEmpty(obj) || obj.empty || Ember.isEmpty(obj.meta)) {
          return;
        }

        router.transitionTo('course-visualize-vocabulary', course.get('id'), obj.meta.vocabulary.get('id'));
      }

    },
    donutHover: (0, _emberConcurrency.task)(function* (obj) {
      yield (0, _emberConcurrency.timeout)(100);
      const intl = this.get('intl');
      const isIcon = this.get('isIcon');

      if (isIcon || Ember.isEmpty(obj) || obj.empty) {
        this.set('tooltipTitle', null);
        this.set('tooltipContent', null);
        return;
      }

      const {
        meta
      } = obj;
      const title = Ember.String.htmlSafe(meta.vocabulary.get('title'));
      this.set('tooltipTitle', title);
      this.set('tooltipContent', intl.t('general.clickForMore'));
    }).restartable()
  });

  _exports.default = _default;
});