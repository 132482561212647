define("ilios-common/utils/escape-reg-exp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = escapeRegExp;

  // @link https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_special_characters
  function escapeRegExp(string) {
    if (!string) {
      return string;
    }

    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }
});