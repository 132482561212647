define("ember-simple-charts/components/simple-chart-pack", ["exports", "d3-transition", "ember-simple-charts/mixins/chart-properties", "d3-selection", "d3-hierarchy", "d3-scale-chromatic", "d3-scale"], function (_exports, _d3Transition, _chartProperties, _d3Selection, _d3Hierarchy, _d3ScaleChromatic, _d3Scale) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_chartProperties.default, {
    classNames: ['simple-chart-pack'],

    draw(passedHeight, passedWidth) {
      const height = Math.min(passedHeight, passedWidth);
      const width = Math.min(passedHeight, passedWidth);
      const data = Ember.get(this, 'data');
      const dataOrEmptyObject = data ? data : {};
      const svg = (0, _d3Selection.select)(this.element);
      const isIcon = Ember.get(this, 'isIcon');
      const hover = Ember.get(this, 'hover');
      const leave = Ember.get(this, 'leave');
      const click = Ember.get(this, 'click');
      const isClickable = Ember.get(this, 'isClickable');
      const packLayout = (0, _d3Hierarchy.pack)().size([height - 15, width - 15]).padding(10);
      const rootNode = (0, _d3Hierarchy.hierarchy)(dataOrEmptyObject);
      rootNode.sum(d => d.value);
      packLayout(rootNode);
      const color = (0, _d3Scale.scaleSequential)(_d3ScaleChromatic.interpolateSinebow).domain([0, rootNode.value]);
      svg.selectAll('.chart').remove();
      const chart = svg.append('g').attr('class', 'chart');
      const nodes = chart.selectAll('circle').data(rootNode.descendants()).enter().append('circle').classed('node', true).attr('fill', d => color(d.value)).attr('cx', d => d.x).attr('cy', d => d.y).attr('r', d => d.r);

      if (!isIcon) {
        nodes.on('mouseenter', ({
          data
        }) => {
          const elements = chart.selectAll('circle.node');
          const selectedElement = elements.filter(({
            data: nodeData
          }) => {
            return nodeData.name === data.name;
          });
          hover(data, selectedElement.node());
        });
        nodes.on('mouseleave', leave);

        if (isClickable) {
          nodes.on('click', ({
            data
          }) => {
            click(data);
          });
          nodes.style("cursor", "pointer");
        }
      }
    }

  });

  _exports.default = _default;
});