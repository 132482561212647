define("ember-cli-string-helpers/helpers/truncate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.truncate = truncate;
  _exports.default = void 0;

  function truncate([string, characterLimit = 140, useEllipsis = true]) {
    let limit = useEllipsis ? characterLimit - 3 : characterLimit;

    if (Ember.String.isHTMLSafe(string)) {
      string = string.string;
    }

    if (string && string.length > limit) {
      return useEllipsis ? "".concat(string.substring(0, limit), "...") : string.substring(0, limit);
    } else {
      return string;
    }
  }

  var _default = Ember.Helper.helper(truncate);

  _exports.default = _default;
});