define("ilios-common/components/instructor-selection-manager", ["exports", "ilios-common/templates/components/instructor-selection-manager"], function (_exports, _instructorSelectionManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    layout: _instructorSelectionManager.default,
    instructors: null,
    availableInstructorGroups: null,
    classNames: ['instructor-selection-manager'],
    tagName: 'section',
    instructorGroups: null,
    'data-test-instructor-selection-manager': true,
    actions: {
      addInstructor(user) {
        this.addInstructor(user);
      },

      addInstructorGroup(group) {
        this.addInstructorGroup(group);
      },

      removeInstructor(user) {
        this.removeInstructor(user);
      },

      removeInstructorGroup(group) {
        this.removeInstructorGroup(group);
      }

    }
  });

  _exports.default = _default;
});