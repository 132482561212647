define("ilios-common/mixins/weeklyevents-controller", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    queryParams: ['year', 'expanded'],
    year: (0, _moment.default)().format('YYYY'),
    expanded: '',
    expandedWeeks: Ember.computed('expanded', function () {
      const expanded = this.get('expanded');
      const expandedString = expanded ? expanded : '';
      const arr = expandedString.split('-');
      return arr;
    }),
    actions: {
      toggleOpenWeek(week, shouldOpen) {
        const expanded = this.get('expanded');
        const expandedString = expanded ? expanded : '';
        let arr = expandedString.split('-');
        arr.removeObject(week);

        if (shouldOpen) {
          arr.pushObject(week);
        }

        arr = arr.sort();
        arr = arr.filter(val => Ember.isPresent(val));
        this.set('expanded', arr.join('-'));
      }

    }
  });

  _exports.default = _default;
});