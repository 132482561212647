define("ilios-common/components/user-search", ["exports", "ember-concurrency", "ilios-common/templates/components/user-search"], function (_exports, _emberConcurrency, _userSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    oneWay
  } = Ember.computed;
  let userProxy = Ember.ObjectProxy.extend({
    isUser: true,
    currentlyActiveUsers: null,
    sortTerm: oneWay('content.fullName'),
    isActive: Ember.computed('content', 'currentlyActiveUsers.[]', function () {
      let user = this.get('content');

      if (!user.get('enabled')) {
        return false;
      }

      return !this.get('currentlyActiveUsers').includes(user);
    })
  });
  let instructorGroupProxy = Ember.ObjectProxy.extend({
    isInstructorGroup: true,
    currentlyActiveInstructorGroups: null,
    sortTerm: oneWay('content.title'),
    isActive: Ember.computed('content', 'currentlyActiveInstructorGroups.[]', function () {
      return !this.get('currentlyActiveInstructorGroups').includes(this.get('content'));
    })
  });

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    layout: _userSearch.default,
    classNames: ['user-search'],
    'data-test-user-search': true,
    showMoreInputPrompt: false,
    searchReturned: false,
    currentlyActiveUsers: null,
    placeholder: null,
    roles: '',
    availableInstructorGroups: null,
    currentlyActiveInstructorGroups: null,
    actions: {
      addUser(user) {
        //don't send actions to the calling component if the user is already in the list
        //prevents a complicated if/else on the template.
        const currentlyActiveUsers = Ember.isEmpty(this.get('currentlyActiveUsers')) ? [] : this.get('currentlyActiveUsers');

        if (!currentlyActiveUsers.includes(user)) {
          this.addUser(user);
        }
      },

      addInstructorGroup(group) {
        //don't send actions to the calling component if the user is already in the list
        //prevents a complicated if/else on the template.
        const currentlyActiveInstructorGroups = Ember.isEmpty(this.get('currentlyActiveInstructorGroups')) ? [] : this.get('currentlyActiveInstructorGroups');

        if (!currentlyActiveInstructorGroups.includes(group)) {
          if (this.addInstructorGroup) {
            this.addInstructorGroup(group);
          }
        }
      }

    },
    search: (0, _emberConcurrency.task)(function* (searchTerms = '') {
      this.set('showMoreInputPrompt', false);
      this.set('searchReturned', false);
      let noWhiteSpaceTerm = searchTerms.replace(/ /g, '');

      if (noWhiteSpaceTerm.length === 0) {
        return [];
      } else if (noWhiteSpaceTerm.length < 3) {
        this.set('showMoreInputPrompt', true);
        return [];
      }

      let query = {
        q: searchTerms,
        limit: 100
      };

      if (this.get('roles')) {
        query.filters = {
          roles: this.get('roles').split(',')
        };
      }

      let users = yield this.get('store').query('user', query);
      const currentlyActiveUsers = this.get('currentlyActiveUsers') === null ? [] : this.get('currentlyActiveUsers');
      let results = users.map(user => {
        return userProxy.create({
          content: user,
          currentlyActiveUsers
        });
      });
      let availableInstructorGroups = yield this.get('availableInstructorGroups');

      if (!Ember.isEmpty(availableInstructorGroups)) {
        let fragment = searchTerms.toLowerCase().trim();
        let filteredGroups = availableInstructorGroups.filter(group => {
          return group.get('title') && group.get('title').toLowerCase().includes(fragment);
        });
        const currentlyActiveInstructorGroups = Ember.isEmpty(this.get('currentlyActiveInstructorGroups')) ? [] : this.get('currentlyActiveInstructorGroups');
        let instructorGroupProxies = filteredGroups.map(group => {
          return instructorGroupProxy.create({
            content: group,
            currentlyActiveInstructorGroups
          });
        });
        results.pushObjects(instructorGroupProxies);
      }

      const intl = this.get('intl');
      const locale = intl.get('locale');
      results.sort((a, b) => {
        const sortTermA = a.get('sortTerm');
        const sortTermB = b.get('sortTerm');
        return sortTermA.localeCompare(sortTermB, locale, {
          numeric: true
        });
      });
      this.set('searchReturned', true);
      return results;
    }).restartable()
  });

  _exports.default = _default;
});