define("ilios-common/components/learningmaterial-manager", ["exports", "ember-cp-validations", "ilios-common/mixins/validation-error-display", "ember-concurrency", "ilios-common/templates/components/learningmaterial-manager", "moment"], function (_exports, _emberCpValidations, _validationErrorDisplay, _emberConcurrency, _learningmaterialManager, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    equal,
    not,
    reads
  } = Ember.computed;
  const Validations = (0, _emberCpValidations.buildValidations)({
    endDate: [(0, _emberCpValidations.validator)('date', {
      allowBlank: true,
      descriptionKey: 'general.endDate',
      dependentKeys: ['model.startDate'],
      after: reads('model.startDate'),
      disabled: not('model.startDate'),
      precision: 'minute',
      errorFormat: 'L LT'
    })],
    title: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 4,
      max: 60
    })]
  });

  var _default = Ember.Component.extend(Validations, _validationErrorDisplay.default, {
    store: Ember.inject.service(),
    layout: _learningmaterialManager.default,
    classNames: ['learningmaterial-manager'],
    statusId: null,
    notes: null,
    learningMaterial: null,
    isCourse: false,
    editable: false,
    type: null,
    title: null,
    owningUserName: null,
    originalAuthor: null,
    userRoleTitle: null,
    description: null,
    copyrightPermission: null,
    copyrightRationale: null,
    citation: null,
    link: null,
    mimetype: null,
    absoluteFileUri: null,
    filename: null,
    uploadDate: null,
    closeManager: null,
    terms: null,
    startDate: null,
    endDate: null,
    isSession: not('isCourse'),
    isFile: equal('type', 'file'),
    isLink: equal('type', 'link'),
    isCitation: equal('type', 'citation'),
    parentMaterial: Ember.computed('learningMaterial.learningMaterial', async function () {
      const learningMaterial = this.get('learningMaterial');
      return learningMaterial.get('learningMaterial');
    }),
    courseLearningMaterialIds: Ember.computed('parentMaterial.courseLearningMaterials.[]', async function () {
      const parentMaterial = await this.get('parentMaterial');
      return parentMaterial.hasMany('courseLearningMaterials').ids();
    }),
    sessionLearningMaterialIds: Ember.computed('parentMaterial.sessionLearningMaterials.[]', async function () {
      const parentMaterial = await this.get('parentMaterial');
      return parentMaterial.hasMany('sessionLearningMaterials').ids();
    }),

    /**
     * Whether the given learning material is linked to no more than one session or course.
     * @property isUnlinked
     * @type {Ember.computed}
     * @public
     */
    isLinkedOnlyOnce: Ember.computed('courseLearningMaterialIds.[]', 'sessionLearningMaterialIds.[]', async function () {
      const cLmIds = await this.get('courseLearningMaterialIds');
      const sLmIds = await this.get('sessionLearningMaterialIds');
      return cLmIds.length + sLmIds.length === 1;
    }),
    currentStatus: Ember.computed('statusId', 'learningMaterialStatuses.[]', async function () {
      const statusId = this.get('statusId');
      const learningMaterialStatuses = await this.get('learningMaterialStatuses');
      return learningMaterialStatuses.findBy('id', statusId);
    }),

    didReceiveAttrs() {
      this._super(...arguments);

      const setup = this.get('setup');
      setup.perform();
    },

    actions: {
      updateDate(which, value) {
        const oldDate = (0, _moment.default)(this.get(which));
        let newDate = (0, _moment.default)(value);
        const hour = oldDate.get('hour');
        const minute = oldDate.get('minute');
        newDate.set({
          hour,
          minute
        });
        this.set(which, newDate.toDate());
      },

      updateTime(which, value, type) {
        const oldDate = (0, _moment.default)(this.get(which));
        const year = oldDate.get('year');
        const month = oldDate.get('month');
        const date = oldDate.get('date');
        let hour = oldDate.get('hour');
        let minute = oldDate.get('minute');

        if (type === 'hour') {
          hour = value;
        }

        if (type === 'minute') {
          minute = value;
        }

        let newDate = (0, _moment.default)();
        newDate.set({
          year,
          month,
          date,
          hour,
          minute
        });
        this.set(which, newDate.toDate());
      },

      addDate(which) {
        let now = (0, _moment.default)().hour(8).minute(0).second(0).toDate();
        this.set(which, now);
      },

      addTerm(term) {
        const terms = this.get('terms');
        terms.pushObject(term);
      },

      removeTerm(term) {
        const terms = this.get('terms');
        terms.removeObject(term);
      }

    },
    setup: (0, _emberConcurrency.task)(function* () {
      const learningMaterial = this.get('learningMaterial');

      if (!learningMaterial) {
        yield (0, _emberConcurrency.timeout)(10000);
        const setup = this.get('setup');
        yield setup.perform();
      }

      this.setProperties(learningMaterial.getProperties('notes', 'required', 'publicNotes', 'startDate', 'endDate'));
      const meshDescriptors = yield learningMaterial.get('meshDescriptors');
      this.set('terms', meshDescriptors.toArray());
      const parent = yield learningMaterial.get('learningMaterial');
      this.setProperties(parent.getProperties('type', 'title', 'originalAuthor', 'description', 'copyrightPermission', 'copyrightRationale', 'citation', 'link', 'mimetype', 'absoluteFileUri', 'filename', 'uploadDate'));
      const status = yield parent.get('status');
      this.set('statusId', status.get('id'));
      const owningUser = yield parent.get('owningUser');
      this.set('owningUserName', owningUser.get('fullName'));
      const userRole = yield parent.get('userRole');
      this.set('userRoleTitle', userRole.get('title'));
      return true;
    }).restartable(),
    save: (0, _emberConcurrency.task)(function* () {
      this.send('addErrorDisplaysFor', ['endDate', 'title']);
      let {
        validations
      } = yield this.validate();

      if (validations.get('isInvalid')) {
        return;
      }

      this.send('clearErrorDisplay');
      const {
        description,
        learningMaterial,
        title,
        notes,
        required,
        publicNotes,
        startDate,
        endDate,
        statusId,
        terms,
        closeManager
      } = this.getProperties('description', 'learningMaterial', 'title', 'notes', 'required', 'publicNotes', 'startDate', 'endDate', 'statusId', 'terms', 'closeManager');
      learningMaterial.set('publicNotes', publicNotes);
      learningMaterial.set('required', required);
      learningMaterial.set('notes', notes);
      learningMaterial.set('startDate', startDate);
      learningMaterial.set('endDate', endDate);
      const statues = yield this.get('learningMaterialStatuses');
      let status = statues.findBy('id', statusId);
      const parent = yield learningMaterial.get('learningMaterial');
      parent.set('status', status);
      parent.set('title', title);
      parent.set('description', description);
      learningMaterial.set('meshDescriptors', terms);
      yield learningMaterial.save();
      yield parent.save();
      closeManager();
    })
  });

  _exports.default = _default;
});