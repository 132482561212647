define("ilios-common/templates/components/session-objective-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oixXy68r",
    "block": "{\"symbols\":[\"objective\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"objectivetitle\"],[8],[0,\"\\n\"],[0,\"  \"],[1,[24,[\"sessionObjective\",\"title\"]],true],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[28,\"await\",[[24,[\"showObjectiveList\"]]],null]],[[\"class\"],[\"vertical\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"parent-picker\"],[8],[0,\"\\n    \"],[7,\"h5\",true],[8],[0,\"\\n      \"],[1,[28,\"get\",[[28,\"await\",[[24,[\"course\"]]],null],\"title\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"ul\",true],[8],[0,\"\\n\"],[4,\"each\",[[28,\"await\",[[24,[\"proxiedObjectives\"]]],null]],null,{\"statements\":[[4,\"if\",[[23,1,[\"selected\"]]],null,{\"statements\":[[0,\"          \"],[7,\"li\",false],[12,\"class\",\"selected clickable\"],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],\"removeParent\",[23,1,[]]]],[8],[0,\"\\n            \"],[5,\"input\",[],[[\"@type\",\"@checked\"],[\"checkbox\",true]]],[0,\"\\n            \"],[1,[23,1,[\"textTitle\"]],false],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[7,\"li\",false],[12,\"class\",\"clickable\"],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],\"addParent\",[23,1,[]]]],[8],[0,\"\\n            \"],[5,\"input\",[],[[\"@type\",\"@checked\"],[\"checkbox\",false]]],[0,\"\\n            \"],[1,[23,1,[\"textTitle\"]],false],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"no-group\"],[8],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"general.missingCourseObjectivesMessage\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/session-objective-manager.hbs"
    }
  });

  _exports.default = _default;
});