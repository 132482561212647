define('ember-pad/utils/pad', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.padStart = padStart;
  exports.padEnd = padEnd;
  exports.padStartTpl = padStartTpl;
  exports.padEndTpl = padEndTpl;
  function pad(len, width, z) {
    let s = z;
    let w = width - len;

    while (--w) {
      s += z;
    }

    return s;
  }

  /**
   * Pad the string at the start.
   *
   * @method padStart
   * @for utils.Pad
   * @param {string} n The string to pad
   * @param {number} width The width to which the string needs to be padded
   * @param {string} [z='0'] The character to pad the string with
   * @return {string} The padded string
   */
  function padStart(n, width) {
    let z = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '0';

    let nstr = String(n);
    let len = nstr.length;

    if (len >= width) {
      return nstr;
    }

    return pad(len, width, z) + nstr;
  }

  exports.default = padStart;


  /**
   * Pad the string at the end.
   *
   * @method padEnd
   * @for utils.Pad
   * @param {string} n The string to pad
   * @param {number} width The width to which the string needs to be padded
   * @param {string} [z='0'] The character to pad the string with
   * @return {string} The padded string
   */
  function padEnd(n, width) {
    let z = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '0';

    let nstr = String(n);
    let len = nstr.length;

    if (len >= width) {
      return nstr;
    }

    return nstr + pad(len, width, z);
  }

  /*
   * Template literal function for padding the string at the start.
   *
   * padStartTpl`${1}:${2}`(2) === '01:02'
   * padStartTpl(2)`${1}:${2}` === '01:02'
   */
  function padStartTpl(strings) {
    for (var _len = arguments.length, values = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      values[_key - 1] = arguments[_key];
    }

    if (Array.isArray(strings)) {
      return (width, z) => processTpl(strings, values, width, z, padStart);
    }

    let width = strings;
    let z = values[0];

    return function (strings) {
      for (var _len2 = arguments.length, values = Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
        values[_key2 - 1] = arguments[_key2];
      }

      return processTpl(strings, values, width, z, padStart);
    };
  }

  exports.padTpl = padStartTpl;


  /*
   * Template literal function for padding the string at the end.
   *
   * padEndTpl`${1}:${2}`(2) === '10:20'
   * padEndTpl(2)`${1}:${2}` === '10:20'
   */
  function padEndTpl(strings) {
    for (var _len3 = arguments.length, values = Array(_len3 > 1 ? _len3 - 1 : 0), _key3 = 1; _key3 < _len3; _key3++) {
      values[_key3 - 1] = arguments[_key3];
    }

    if (Array.isArray(strings)) {
      return (width, z) => processTpl(strings, values, width, z, padEnd);
    }

    let width = strings;
    let z = values[0];

    return function (strings) {
      for (var _len4 = arguments.length, values = Array(_len4 > 1 ? _len4 - 1 : 0), _key4 = 1; _key4 < _len4; _key4++) {
        values[_key4 - 1] = arguments[_key4];
      }

      return processTpl(strings, values, width, z, padEnd);
    };
  }

  function processTpl(strings, values, width, z, pad) {
    let reducer = (str, v, i) => str + strings[i] + pad(v, width, z);
    return values.reduce(reducer, '');
  }
});