define("ilios-common/templates/components/click-choice-buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Fc36yg7R",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\",false],[12,\"class\",[29,[\"first-button \",[28,\"if\",[[24,[\"firstChoicePicked\"]],\"active\"],null]]]],[3,\"action\",[[23,0,[]],\"clickFirstButton\"]],[8],[0,\"\\n  \"],[1,[22,\"buttonContent1\"],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"button\",false],[12,\"class\",[29,[\"second-button \",[28,\"unless\",[[24,[\"firstChoicePicked\"]],\"active\"],null]]]],[3,\"action\",[[23,0,[]],\"clickSecondButton\"]],[8],[0,\"\\n  \"],[1,[22,\"buttonContent2\"],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/click-choice-buttons.hbs"
    }
  });

  _exports.default = _default;
});