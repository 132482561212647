define("ilios-common/templates/components/toggle-icons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9CjH00GQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"accessible\"],[8],[0,\"\\n  \"],[7,\"input\",true],[11,\"id\",[28,\"concat\",[\"first-toggle-\",[24,[\"elementId\"]]],null]],[11,\"name\",[28,\"concat\",[\"toggle-\",[24,[\"elementId\"]]],null]],[11,\"checked\",[28,\"eq\",[[24,[\"firstOptionSelected\"]],true],null]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"firstChoice\"],null]],[10,\"type\",\"radio\"],[8],[9],[0,\"\\n  \"],[7,\"label\",true],[11,\"for\",[28,\"concat\",[\"first-toggle-\",[24,[\"elementId\"]]],null]],[8],[0,\"\\n    \"],[1,[22,\"firstLabel\"],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"input\",true],[11,\"id\",[28,\"concat\",[\"second-toggle-\",[24,[\"elementId\"]]],null]],[11,\"name\",[28,\"concat\",[\"toggle-\",[24,[\"elementId\"]]],null]],[11,\"checked\",[28,\"eq\",[[24,[\"firstOptionSelected\"]],false],null]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"secondChoice\"],null]],[10,\"type\",\"radio\"],[8],[9],[0,\"\\n  \"],[7,\"label\",true],[11,\"for\",[28,\"concat\",[\"second-toggle-\",[24,[\"elementId\"]]],null]],[8],[0,\"\\n    \"],[1,[22,\"secondLabel\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"icons\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"firstOptionSelected\"]]],null,{\"statements\":[[0,\"    \"],[5,\"fa-icon\",[[12,\"class\",\"first\"]],[[\"@icon\",\"@title\",\"@click\"],[[22,\"firstIcon\"],[22,\"secondLabel\"],[28,\"action\",[[23,0,[]],\"secondChoice\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"fa-icon\",[[12,\"class\",\"second\"]],[[\"@icon\",\"@title\",\"@click\"],[[22,\"secondIcon\"],[22,\"firstLabel\"],[28,\"action\",[[23,0,[]],\"firstChoice\"],null]]]],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/toggle-icons.hbs"
    }
  });

  _exports.default = _default;
});