define("ilios-common/components/collapsed-competencies", ["exports", "ilios-common/templates/components/collapsed-competencies"], function (_exports, _collapsedCompetencies) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _collapsedCompetencies.default,
    tagName: 'section',
    classNames: ['collapsed-competencies'],

    /**
     * The model object with assigned competencies.
     * Can be either a course or program-year.
     *
     * @property subject
     * @type {Object}
     * @public
     */
    subject: null,

    /**
     * A summary of schools and their competencies that are assigned to the given subject.
     * @property summary
     * @type {Ember.computed}
     * @public
     */
    summary: Ember.computed('subject.competencies.[]', async function () {
      const competencies = await this.subject.get('competencies');
      const schools = await Ember.RSVP.all(competencies.mapBy('school'));
      const schoolIds = schools.mapBy('id').uniq();
      return schoolIds.map(id => {
        return {
          competencies: schools.filterBy('id', id),
          school: schools.findBy('id', id)
        };
      });
    })
  });

  _exports.default = _default;
});