define("ilios-common/templates/components/loading-part", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m0rbe++f",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"pulse-loader\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/loading-part.hbs"
    }
  });

  _exports.default = _default;
});