define("ilios-common/components/sessions-grid-offering", ["exports", "ember-cp-validations", "ilios-common/mixins/validation-error-display", "ember-concurrency", "scroll-into-view", "ilios-common/templates/components/sessions-grid-offering"], function (_exports, _emberCpValidations, _validationErrorDisplay, _emberConcurrency, _scrollIntoView, _sessionsGridOffering) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    room: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 255
    })]
  });

  var _default = Ember.Component.extend(_validationErrorDisplay.default, Validations, {
    layout: _sessionsGridOffering.default,
    tagName: 'tr',
    classNameBindings: [':sessions-grid-offering', 'firstRow', 'even', 'wasUpdated'],
    canUpdate: false,
    room: null,
    firstRow: false,
    even: false,
    isEditing: false,
    wasUpdated: false,
    'data-test-sessions-grid-offering': true,

    didReceiveAttrs() {
      this._super(...arguments);

      const offering = this.get('offering');
      this.set('room', offering.room);
    },

    actions: {
      revertRoomChanges() {
        const offering = this.get('offering');
        this.set('room', offering.get('room'));
      },

      close() {
        this.set('isEditing', false);
        (0, _scrollIntoView.default)(this.element);
      }

    },
    changeRoom: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(10);
      this.send('addErrorDisplayFor', 'room');
      const offering = this.get('offering');
      const room = this.get('room');
      const {
        validations
      } = yield this.validate();

      if (validations.get('isInvalid')) {
        return;
      }

      this.send('removeErrorDisplayFor', 'room');
      offering.set('room', room);
      yield offering.save();
    }).drop(),
    save: (0, _emberConcurrency.task)(function* (startDate, endDate, room, learnerGroups, instructorGroups, instructors) {
      const offering = this.get('offering');
      offering.setProperties({
        startDate,
        endDate,
        room,
        learnerGroups,
        instructorGroups,
        instructors
      });
      yield offering.save();
      this.updateUi.perform();
    }).drop(),
    updateUi: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(10);
      this.set('wasUpdated', true);
      (0, _scrollIntoView.default)(this.element);
      yield (0, _emberConcurrency.timeout)(4000);
      this.set('wasUpdated', false);
    }).restartable()
  });

  _exports.default = _default;
});