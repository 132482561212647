define("elemental-calendar/components/el-weekly-calendar", ["exports", "elemental-calendar/templates/components/el-weekly-calendar", "elemental-calendar/models/week", "elemental-calendar/models/event-list"], function (_exports, _elWeeklyCalendar, _week, _eventList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _elWeeklyCalendar.default,
    classNames: ['el-calendar'],
    date: null,
    events: null,
    week: Ember.computed('date', 'events.[]', function () {
      let eventList = _eventList.default.create({
        events: this.events
      });

      return _week.default.create({
        date: this.date,
        eventList: eventList
      });
    })
  });

  _exports.default = _default;
});