define("ilios-common/templates/components/pulse-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kxUCn8z/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"width\",\"168\"],[10,\"height\",\"106\"],[10,\"viewBox\",\"0 0 168 106\"],[8],[0,\"\\n  \"],[7,\"path\",true],[10,\"class\",\"path\"],[10,\"stroke\",\"#ca6728\"],[10,\"stroke-width\",\"4\"],[10,\"stroke-linejoin\",\"round\"],[10,\"stroke-linecap\",\"round\"],[10,\"stroke-miterlimit\",\"10\"],[10,\"stroke-dasharray\",\"391 300\"],[10,\"stroke-dashoffset\",\"0\"],[10,\"fill\",\"none\"],[10,\"d\",\"M0,80.9h20.6c0.5,0,1.4-0.2,1.8-0.5L38,70.1\\n\\tc0.5-0.3,1.2-0.3,1.6,0l12.7,9.4c0.4,0.3,1.3,0.6,1.8,0.6l13.3,0c0.6,0,1.2,0.4,1.5,0.9l6.2,11.3c0.3,0.5,0.5,0.4,0.5-0.1l4.4-90.8\\n\\tc0-0.5,0.1-0.5,0.1,0l6.9,102.1c0,0.5,0.2,0.6,0.4,0l7-22.4c0.2-0.5,0.7-1,1.3-1l16.1,0c0.5,0,1.3-0.3,1.8-0.7L129,66.4\\n\\tc0.4-0.4,1.1-0.3,1.5,0l13.3,13.1c0.4,0.4,1.2,0.7,1.7,0.7l20.1,0,\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/pulse-loader.hbs"
    }
  });

  _exports.default = _default;
});