define("ilios-common/components/editable-field", ["exports", "ilios-common/templates/components/editable-field", "ember-concurrency"], function (_exports, _editableField, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _editableField.default,
    value: null,
    isEditing: false,
    isSaving: false,
    isSaveDisabled: false,
    renderHtml: false,
    classNames: ['editinplace'],
    saveOnEnter: false,
    closeOnEscape: false,
    clickPrompt: null,
    looksEmpty: Ember.computed('value', function () {
      let value = this.get('value') || '';
      let text = value.toString();
      let noTagsText = text.replace(/(<([^>]+)>)/ig, "");
      let strippedText = noTagsText.replace(/&nbsp;/ig, "").replace(/\s/g, "");
      return Ember.isEmpty(strippedText);
    }),
    saveData: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(1);
      const result = yield this.get('save')();

      if (result !== false) {
        this.set('isEditing', false);
      }
    }).drop(),
    closeEditor: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(1);
      yield this.get('close')();
      this.set('isEditing', false);
    }).drop(),
    edit: (0, _emberConcurrency.task)(function* () {
      this.set('isEditing', true);
      yield (0, _emberConcurrency.timeout)(10);
      const elements = this.element.querySelectorAll('input,textarea,select,.fr-element');

      if (elements.length) {
        const visibleControls = Array.from(elements).filter(el => {
          return !el.hidden;
        });
        visibleControls[0].focus();
      }
    }).drop(),

    keyUp(event) {
      const keyCode = event.keyCode;
      const target = event.target; // only process key events coming from text input/textarea.

      if (!['text', 'textarea'].includes(target.type)) {
        return;
      }

      if (13 === keyCode && this.get('saveOnEnter')) {
        this.get('saveData').perform();
      } else if (27 === keyCode && this.get('closeOnEscape')) {
        this.get('closeEditor').perform();
      }
    }

  });

  _exports.default = _default;
});