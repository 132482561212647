define("ilios-common/components/course-rollover", ["exports", "moment", "ember-concurrency", "ember-cp-validations", "ilios-common/mixins/validation-error-display", "ilios-common/templates/components/course-rollover"], function (_exports, _moment, _emberConcurrency, _emberCpValidations, _validationErrorDisplay, _courseRollover) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    reads
  } = Ember.computed;
  const Validations = (0, _emberCpValidations.buildValidations)({
    title: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 3,
      max: 200
    })],
    selectedYear: [(0, _emberCpValidations.validator)('presence', true)]
  });

  var _default = Ember.Component.extend(_validationErrorDisplay.default, Validations, {
    commonAjax: Ember.inject.service(),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    iliosConfig: Ember.inject.service(),
    layout: _courseRollover.default,
    classNames: ['course-rollover'],
    years: null,
    selectedYear: null,
    course: null,
    startDate: null,
    skipOfferings: false,
    title: null,
    isSaving: false,
    selectedCohorts: null,
    host: reads('iliosConfig.apiHost'),
    namespace: reads('iliosConfig.apiNameSpace'),

    init() {
      this._super(...arguments);

      this.set('selectedCohorts', []);
    },

    didReceiveAttrs() {
      this._super(...arguments);

      let lastYear = parseInt((0, _moment.default)().subtract(1, 'year').format('YYYY'), 10);
      let years = [];

      for (let i = 0; i < 6; i++) {
        years.push(lastYear + i);
      }

      this.set('years', years);
      const course = this.get('course');

      if (Ember.isPresent(course)) {
        this.set('title', course.get('title'));
      }

      this.get('loadUnavailableYears').perform();
      this.get('changeSelectedYear').perform(lastYear);
    },

    actions: {
      changeTitle(newTitle) {
        this.set('title', newTitle);
        this.get('loadUnavailableYears').perform();
      },

      addCohort(cohort) {
        let selectedCohorts = this.get('selectedCohorts');
        selectedCohorts.pushObject(cohort);
        this.set('selectedCohorts', selectedCohorts);
      },

      removeCohort(cohort) {
        let selectedCohorts = this.get('selectedCohorts');
        selectedCohorts.removeObject(cohort);
        this.set('selectedCohorts', selectedCohorts);
      }

    },

    keyUp(event) {
      const keyCode = event.keyCode;
      const target = event.target;

      if ('text' !== target.type) {
        return;
      }

      if (13 === keyCode) {
        this.get('save').perform();
      }
    },

    save: (0, _emberConcurrency.task)(function* () {
      this.set('isSaving', true);
      yield (0, _emberConcurrency.timeout)(10);
      this.send('addErrorDisplaysFor', ['title', 'selectedYear']);
      let {
        validations
      } = yield this.validate();

      if (validations.get('isInvalid')) {
        this.set('isSaving', false);
        return;
      }

      const commonAjax = this.get('commonAjax');
      const courseId = this.get('course.id');
      const year = this.get('selectedYear');
      const newCourseTitle = this.get('title');
      const selectedCohortIds = this.get('selectedCohorts').mapBy('id');
      let newStartDate = (0, _moment.default)(this.get('startDate')).format('YYYY-MM-DD');
      let skipOfferings = this.get('skipOfferings');
      let data = {
        year,
        newCourseTitle
      };

      if (newStartDate) {
        data.newStartDate = newStartDate;
      }

      if (skipOfferings) {
        data.skipOfferings = true;
      }

      if (selectedCohortIds) {
        data.newCohorts = selectedCohortIds;
      }

      const host = this.get('host') ? this.get('host') : '';
      const namespace = this.get('namespace');
      let url = host + '/' + namespace + "/courses/".concat(courseId, "/rollover");
      const newCoursesObj = yield commonAjax.request(url, {
        method: 'POST',
        data
      });
      const flashMessages = this.get('flashMessages');
      const store = this.get('store');
      flashMessages.success('general.courseRolloverSuccess');
      store.pushPayload(newCoursesObj);
      let newCourse = store.peekRecord('course', newCoursesObj.courses[0].id);
      return this.get('visit')(newCourse);
    }).drop(),
    loadUnavailableYears: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(250); //debounce title changes

      const title = this.get('title');
      const store = this.get('store');
      let existingCoursesWithTitle = yield store.query('course', {
        filters: {
          title
        }
      });
      return existingCoursesWithTitle.mapBy('year');
    }).restartable(),
    changeSelectedYear: (0, _emberConcurrency.task)(function* (selectedYear) {
      this.setProperties({
        selectedYear
      });
      yield (0, _emberConcurrency.timeout)(100); //let max/min cp's recalculate

      const date = (0, _moment.default)(this.get('course.startDate'));
      const day = date.isoWeekday();
      const week = date.isoWeek();
      let startDate = (0, _moment.default)().year(selectedYear).isoWeek(week).isoWeekday(day).toDate();
      this.setProperties({
        startDate
      });
    }).restartable(),

    /**
     * "disableDayFn" callback function pikaday.
     * @link https://github.com/dbushell/Pikaday#configuration
     * @param {Date} date
     * @returns {boolean}
     */
    disableDayFn(date) {
      // KLUDGE!
      // We're sneaking the course into pikaday via the options hash.
      // See https://github.com/edgycircle/ember-pikaday#using-pikaday-specific-options
      // If ember-pikaday ever locks down this backdoor, then we're hosed.
      // @todo Find a better way. [ST 2016/06/30]
      if (this.course) {
        // ensure that only dates that fall on the same weekday as the course's start date can be selected.
        return this.course.get('startDate').getUTCDay() !== date.getUTCDay();
      }

      return false; // don't disable anything if we don't have a course to compare to.
    }

  });

  _exports.default = _default;
});