define("ilios-common/mixins/print-course-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    currentUser: Ember.inject.service(),
    titleToken: 'general.coursesAndSessions',
    canViewUnpublished: false,

    // only allow privileged users to view unpublished courses
    async afterModel(course, transition) {
      const currentUser = this.get('currentUser');
      let canViewUnpublished = currentUser.get('performsNonLearnerFunction');
      this.set('canViewUnpublished', canViewUnpublished);

      if (canViewUnpublished || course.get('isPublishedOrScheduled')) {
        return Ember.RSVP.all([course.get('sessions'), course.get('competencies'), course.get('objectives')]);
      }

      transition.abort();
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('canViewUnpublished', this.get('canViewUnpublished'));
    }

  });

  _exports.default = _default;
});