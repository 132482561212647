define("ilios-common/components/my-materials", ["exports", "ilios-common/templates/components/my-materials", "ilios-common/mixins/sortable-table", "ember-concurrency"], function (_exports, _myMaterials, _sortableTable, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEBOUNCE_DELAY = 250;

  var _default = Ember.Component.extend(_sortableTable.default, {
    layout: _myMaterials.default,
    classNames: ['my-materials'],
    courseIdFilter: null,
    filter: null,
    materials: null,
    sortBy: null,

    setCourseIdFilter() {},

    setFilter() {},

    setSortBy() {},

    filteredMaterials: Ember.computed('courseIdFilter', 'filter', 'materials.[]', async function () {
      const courseIdFilter = this.courseIdFilter;
      const filter = this.filter;
      let materials = await this.materials;

      if (Ember.isPresent(courseIdFilter)) {
        materials = materials.filterBy('course', courseIdFilter);
      }

      if (Ember.isPresent(filter)) {
        materials = materials.filter(({
          courseTitle,
          instructors,
          sessionTitle,
          title
        }) => {
          let searchString = "".concat(title, " ").concat(courseTitle, " ").concat(sessionTitle, " ");

          if (Ember.isPresent(instructors)) {
            searchString += instructors.join(' ');
          }

          return searchString.toLowerCase().includes(filter.toLowerCase());
        });
      }

      return materials;
    }),
    courses: Ember.computed('materials.[]', async function () {
      const materials = await this.materials;
      return materials.map(material => {
        return {
          id: material.course,
          title: material.courseTitle
        };
      }).uniqBy('id').sortBy('title');
    }),
    actions: {
      sortString(a, b) {
        return a.localeCompare(b);
      }

    },
    setQuery: (0, _emberConcurrency.task)(function* (query) {
      yield (0, _emberConcurrency.timeout)(DEBOUNCE_DELAY);
      this.setFilter(query);
    }).restartable()
  });

  _exports.default = _default;
});