define("elemental-calendar/models/month", ["exports", "moment", "elemental-calendar/models/week", "elemental-calendar/models/event-list"], function (_exports, _moment, _week, _eventList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let Month = Ember.Object.extend({
    date: null,
    events: null,
    eventList: null,
    _numberOfDaysInPreviousMonth: null,
    _momentDate: Ember.computed('date', function () {
      return (0, _moment.default)(this.date);
    }),
    weeks: Ember.computed('_momentDate', function () {
      let momentDate = this.get('_momentDate'); // Set new moment date to first day of the month

      let dayOfMonthMoment = (0, _moment.default)(momentDate).startOf('month');
      let monthNumber = dayOfMonthMoment.format('M');
      let weeks = Ember.A();
      let firstWeekDate = dayOfMonthMoment.toDate();

      let nextWeek = _week.default.create({
        date: firstWeekDate,
        eventList: this.eventList
      });

      do {
        weeks.pushObject(nextWeek);
        nextWeek = weeks.get('lastObject').next(this.eventList);
      } while (nextWeek.get('startOfWeekMonthNumber') === monthNumber);

      return weeks;
    }),

    init() {
      this._super(...arguments);

      this.eventList = this.eventList || _eventList.default.create({
        events: this.events
      });
    }

  });
  var _default = Month;
  _exports.default = _default;
});