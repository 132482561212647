define("ilios-common/templates/components/api-version-notice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nkhaZ5iB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"api-version-notice \",[28,\"if\",[[24,[\"mismatched\"]],\"mismatch\"],null]]]],[11,\"hidden\",[28,\"not\",[[24,[\"mismatched\"]]],null]],[11,\"role\",[28,\"if\",[[24,[\"mismatched\"]],\"alert\",false],null]],[8],[0,\"\\n  \"],[7,\"h2\",true],[8],[0,\"\\n    \"],[5,\"fa-icon\",[],[[\"@icon\"],[\"exclamation-circle\"]]],[0,\"\\n    \"],[1,[28,\"t\",[\"general.apiVersionMismatch\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"details\"],[8],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"general.apiVersionMismatchDetails\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"updateAvailable\"]]],null,{\"statements\":[[0,\"      \"],[7,\"p\",true],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"general.autoUpdatingSeconds\"],[[\"count\"],[[24,[\"countdownToUpdate\"]]]]],false],[0,\"\\n        \"],[7,\"button\",false],[3,\"action\",[[23,0,[]],[28,\"perform\",[[24,[\"update\"]]],null]]],[8],[0,\"\\n          \"],[1,[28,\"t\",[\"general.updateNow\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"update\",\"isRunning\"]]],null,{\"statements\":[[0,\"      \"],[7,\"p\",true],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"general.updatePending\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"showReloadButton\"]]],null,{\"statements\":[[0,\"      \"],[7,\"p\",true],[8],[0,\"\\n        \"],[7,\"button\",false],[3,\"action\",[[23,0,[]],\"reload\"]],[8],[0,\"\\n          \"],[1,[28,\"t\",[\"general.updateNow\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/api-version-notice.hbs"
    }
  });

  _exports.default = _default;
});