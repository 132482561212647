define("ilios-common/components/collapsed-learnergroups", ["exports", "ilios-common/templates/components/collapsed-learnergroups"], function (_exports, _collapsedLearnergroups) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _collapsedLearnergroups.default,
    subject: null,
    tagName: 'section',
    classNames: ['collapsed-learnergroups'],
    'data-test-collapsed-learnergroups': true,
    cohortSummaries: Ember.computed('subject.learnerGroups.[]', async function () {
      const subject = this.get('subject');

      if (!subject) {
        return [];
      }

      let learnerGroups = await subject.get('learnerGroups');
      let cohorts = await Ember.RSVP.map(learnerGroups.toArray(), group => group.get('cohort'));
      let summaryBlocks = cohorts.reduce((set, cohort) => {
        let key = 'cohort' + cohort.get('id');

        if (!Object.keys(set).includes(key)) {
          set[key] = {
            cohort,
            count: 0
          };
        }

        set[key].count++;
        return set;
      }, {});
      return await Ember.RSVP.map(Object.keys(summaryBlocks), async key => {
        const cohort = summaryBlocks[key].cohort;
        const count = summaryBlocks[key].count;
        const program = await cohort.get('program');
        const title = [program.title, cohort.title].join(' ');
        return {
          title,
          count
        };
      });
    })
  });

  _exports.default = _default;
});