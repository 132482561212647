define("ilios-common/components/offering-manager", ["exports", "ilios-common/templates/components/offering-manager"], function (_exports, _offeringManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    layout: _offeringManager.default,
    classNameBindings: [':offering-manager', 'showRemoveConfirmation'],
    offering: null,
    editable: true,
    isEditing: false,
    showRemoveConfirmation: false,
    'data-test-offering-manager': true,
    userCanDelete: Ember.computed('editable', 'offering.session.course', 'offering.allInstructors.[]', 'currentUser.model.directedCourses.[]', async function () {
      const offering = this.get('offering');

      if (Ember.isEmpty(offering)) {
        return false;
      }

      return this.get('editable');
    }),
    actions: {
      save(startDate, endDate, room, learnerGroups, instructorGroups, instructors) {
        const offering = this.get('offering');
        offering.setProperties({
          startDate,
          endDate,
          room,
          learnerGroups,
          instructorGroups,
          instructors
        });
        return offering.save();
      },

      remove() {
        this.remove(this.get('offering'));
      },

      cancelRemove() {
        this.set('showRemoveConfirmation', false);
      },

      confirmRemove() {
        this.set('showRemoveConfirmation', true);
      }

    }
  });

  _exports.default = _default;
});