define("ilios-common/templates/components/selectable-terms-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "63QUVWBy",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"term\",\"description\",\"length\"]]],null,{\"statements\":[[4,\"attach-tooltip\",null,[[\"lazyRender\",\"animation\"],[true,\"shift\"]],{\"statements\":[[0,\"    \"],[1,[24,[\"term\",\"description\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[1,[24,[\"term\",\"title\"]],false],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"isSelected\"]]],null,{\"statements\":[[0,\"    \"],[5,\"fa-icon\",[],[[\"@icon\"],[\"times\"]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"fa-icon\",[[12,\"class\",\"add\"]],[[\"@icon\"],[\"plus\"]]],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/selectable-terms-list-item.hbs"
    }
  });

  _exports.default = _default;
});