define("ilios-common/components/timed-release-schedule", ["exports", "moment", "ilios-common/templates/components/timed-release-schedule"], function (_exports, _moment, _timedReleaseSchedule) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _timedReleaseSchedule.default,
    tagName: 'span',
    classNames: ['timed-release-schedule'],
    startDate: null,
    endDate: null,
    now: null,
    showNoSchedule: true,
    startDateInTheFuture: Ember.computed('startDate', 'now', function () {
      const startDate = this.get('startDate');
      const now = this.get('now');

      if (!startDate || !now) {
        return false;
      }

      return (0, _moment.default)(startDate).isAfter(now);
    }),
    endDateInTheFuture: Ember.computed('endDate', 'now', function () {
      const endDate = this.get('endDate');
      const now = this.get('now');

      if (!endDate || !now) {
        return false;
      }

      return (0, _moment.default)(endDate).isAfter(now);
    }),

    init() {
      this._super();

      const now = new Date();
      this.set('now', now);
    }

  });

  _exports.default = _default;
});