define("ilios-common/mixins/course-controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    queryParams: ['details', 'courseLeadershipDetails', 'courseObjectiveDetails', 'courseTaxonomyDetails', 'courseCompetencyDetails', 'courseManageLeadership'],
    details: false,
    editable: false,
    courseLeadershipDetails: false,
    courseObjectiveDetails: false,
    courseTaxonomyDetails: false,
    courseCompetencyDetails: false,
    courseManageLeadership: false
  });

  _exports.default = _default;
});