define("ilios-common/components/api-version-notice", ["exports", "ilios-common/templates/components/api-version-notice", "ember-concurrency"], function (_exports, _apiVersionNotice, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    apiVersion: Ember.inject.service(),
    layout: _apiVersionNotice.default,
    tagName: '',
    mismatched: false,
    updateAvailable: false,
    countdownToUpdate: null,
    showReloadButton: false,
    check: (0, _emberConcurrency.task)(function* () {
      const mismatched = yield this.apiVersion.isMismatched;

      if (mismatched && 'serviceWorker' in navigator) {
        yield 2000; //wait to let the new service worker get fetched if it is available

        const reg = yield navigator.serviceWorker.getRegistration();

        if (reg) {
          if (reg.waiting) {
            this.update.perform();
          } else {
            reg.onupdatefound = () => {
              this.countdown.perform();
            };
          }
        } else {
          Ember.set(this, 'showReloadButton', true);
        }
      }

      this.set('mismatched', mismatched);
      return true; //always return true to update data-test-load-finished property
    }).drop().on('didInsertElement'),
    actions: {
      reload() {
        window.location.reload();
      }

    },
    countdown: (0, _emberConcurrency.task)(function* () {
      this.set('updateAvailable', true);

      for (let i = 5; i > 0; i--) {
        this.set('countdownToUpdate', i);
        yield (0, _emberConcurrency.timeout)(1000);
      }

      yield this.update.perform();
    }).drop(),
    update: (0, _emberConcurrency.task)(function* () {
      if ('serviceWorker' in navigator) {
        const reg = yield navigator.serviceWorker.getRegistration();

        if (reg && reg.waiting) {
          reg.waiting.postMessage('skipWaiting');
        }
      }

      yield (0, _emberConcurrency.timeout)(3000);
    }).drop()
  });

  _exports.default = _default;
});