define("ilios-common/templates/components/time-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D/NCjCUx",
    "block": "{\"symbols\":[\"listAmPm\",\"listMinute\",\"listHour\"],\"statements\":[[7,\"select\",true],[11,\"onchange\",[28,\"action\",[[23,0,[]],\"changeHour\"],[[\"value\"],[\"target.value\"]]]],[10,\"class\",\"hour\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"hours\"]]],null,{\"statements\":[[0,\"    \"],[7,\"option\",true],[11,\"value\",[23,3,[]]],[11,\"selected\",[28,\"is-equal\",[[23,3,[]],[24,[\"hour\"]]],null]],[8],[0,\"\\n      \"],[1,[23,3,[]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[3]},null],[9],[0,\"\\n\"],[7,\"select\",true],[11,\"onchange\",[28,\"action\",[[23,0,[]],\"changeMinute\"],[[\"value\"],[\"target.value\"]]]],[10,\"class\",\"minute\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"minutes\"]]],null,{\"statements\":[[0,\"    \"],[7,\"option\",true],[11,\"value\",[23,2,[]]],[11,\"selected\",[28,\"is-equal\",[[23,2,[]],[24,[\"minute\"]]],null]],[8],[0,\"\\n      \"],[1,[23,2,[]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[9],[0,\"\\n\"],[7,\"select\",true],[11,\"onchange\",[28,\"action\",[[23,0,[]],\"changeAmPm\"],[[\"value\"],[\"target.value\"]]]],[10,\"class\",\"ampm\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"ampms\"]]],null,{\"statements\":[[0,\"    \"],[7,\"option\",true],[11,\"value\",[23,1,[]]],[11,\"selected\",[28,\"is-equal\",[[23,1,[]],[24,[\"ampm\"]]],null]],[8],[0,\"\\n      \"],[1,[23,1,[]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/time-picker.hbs"
    }
  });

  _exports.default = _default;
});