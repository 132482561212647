define("elemental-calendar/models/hour", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    events: null,
    datetime: null,
    _momentDateTime: Ember.computed('datetime', function () {
      return (0, _moment.default)(this.datetime);
    }),
    formattedHour: Ember.computed('datetime', function () {
      return this.get('_momentDateTime').format('h:mm a');
    }),

    init() {
      this._super(...arguments);

      this.events = this.eventList.forHour(this);
    }

  });

  _exports.default = _default;
});