define("ilios-common/helpers/has-many-length", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hasManyLength = hasManyLength;
  _exports.default = void 0;

  function hasManyLength([model, property]) {
    if (Ember.isEmpty(model) || typeof model !== 'object') {
      return model;
    }

    if (typeof model.hasMany !== 'function') {
      return model;
    }

    const rel = model.hasMany(property);

    if (typeof rel.ids !== 'function') {
      return model;
    }

    return rel.ids().length;
  }

  var _default = Ember.Helper.helper(hasManyLength);

  _exports.default = _default;
});