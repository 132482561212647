define("ilios-common/components/print-course", ["exports", "ilios-common/mixins/sortable-by-position", "ilios-common/templates/components/print-course"], function (_exports, _sortableByPosition, _printCourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_sortableByPosition.default, {
    store: Ember.inject.service(),
    layout: _printCourse.default,
    course: null,
    includeUnpublishedSessions: false,
    tagName: 'section',
    classNames: ['print-course'],
    sortTitle: null,
    sortDirectorsBy: null,
    sortedDirectors: Ember.computed.sort('course.directors', 'sortDirectorsBy'),
    sortedMeshDescriptors: Ember.computed.sort('course.meshDescriptors', 'sortTitle'),

    /**
     * A list of proxied course sessions, sorted by title.
     * @property sortedSessionProxies
     * @type {Ember.computed}
     * @public
     */
    sortedSessionProxies: Ember.computed('course.sessions.[]', 'includeUnpublishedSessions', function () {
      return new Ember.RSVP.Promise(resolve => {
        const course = this.get('course');

        if (!course) {
          resolve([]);
          return;
        }

        let SessionProxy = Ember.ObjectProxy.extend({
          sortTitle: null,
          sortedMeshDescriptors: Ember.computed.sort('content.meshDescriptors', 'sortTitle'),
          sessionLearningMaterials: Ember.computed('content', function () {
            return new Ember.RSVP.Promise(resolve => {
              let session = this.get('content').get('id');
              this.get('store').query('sessionLearningMaterial', {
                filters: {
                  session
                }
              }).then(learningMaterials => {
                resolve(learningMaterials.toArray().sort(this.get('positionSortingCallback')));
              });
            });
          }),

          init() {
            this._super(...arguments);

            this.set('sortTitle', ['title']);
          }

        });
        course.get('sessions').then(sessions => {
          if (!this.get('includeUnpublishedSessions')) {
            sessions = sessions.filterBy('isPublishedOrScheduled');
          }

          let proxiedSessions = sessions.map(function (session) {
            return SessionProxy.create({
              content: session
            });
          });
          resolve(proxiedSessions.sortBy('title'));
        });
      });
    }),
    courseLearningMaterials: Ember.computed('course', function () {
      return new Ember.RSVP.Promise(resolve => {
        let course = this.get('course').get('id');
        this.get('store').query('courseLearningMaterial', {
          filters: {
            course
          }
        }).then(learningMaterials => {
          resolve(learningMaterials.toArray().sort(this.get('positionSortingCallback')));
        });
      });
    }),

    init() {
      this._super(...arguments);

      this.set('sortTitle', ['title']);
      this.set('sortDirectorsBy', ['lastName', 'firstName']);
    }

  });

  _exports.default = _default;
});