define("ilios-common/models/competency", ["exports", "ember-data", "ember-inflector"], function (_exports, _emberData, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    not
  } = Ember.computed;
  const {
    all
  } = Ember.RSVP;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  _emberInflector.default.inflector.uncountable('aamc-pcrs');

  var _default = Model.extend({
    active: attr('boolean'),
    title: attr('string'),
    school: belongsTo('school', {
      async: true
    }),
    objectives: hasMany('objective', {
      async: true
    }),
    parent: belongsTo('competency', {
      async: true,
      inverse: 'children'
    }),
    children: hasMany('competency', {
      async: true,
      inverse: 'parent'
    }),
    aamcPcrses: hasMany('aamc-pcrs', {
      async: true
    }),
    programYears: hasMany('program-year', {
      async: true
    }),
    isNotDomain: not('isDomain'),
    isDomain: Ember.computed('parent', function () {
      return !this.belongsTo('parent').id();
    }),
    domain: Ember.computed('parent', 'parent.domain', async function () {
      const parent = await this.get('parent');

      if (!parent) {
        return this;
      }

      return await parent.get('domain');
    }),
    treeChildren: Ember.computed('children.[]', async function () {
      let rhett = [];
      const children = await this.get('children');
      rhett.pushObjects(children.toArray());
      const trees = await all(children.mapBy('treeChildren'));
      let competencies = trees.reduce((array, set) => {
        return array.pushObjects(set.toArray());
      }, []);
      rhett.pushObjects(competencies);
      return rhett.uniq().filter(item => {
        return !Ember.isEmpty(item);
      });
    }),
    childCount: Ember.computed('children.[]', function () {
      const childrenIds = this.hasMany('children').ids();
      return childrenIds.length;
    })
  });

  _exports.default = _default;
});