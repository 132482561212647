define("ember-simple-charts/templates/components/simple-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "byt2pwcP",
    "block": "{\"symbols\":[\"&default\",\"@isIcon\",\"@data\"],\"statements\":[[1,[28,\"component\",[[23,0,[\"chartName\"]]],[[\"data\",\"isIcon\",\"hover\",\"click\",\"leave\",\"isClickable\"],[[23,3,[]],[28,\"or\",[[23,2,[]],false],null],[28,\"perform\",[[23,0,[\"handleHover\"]]],null],[28,\"perform\",[[23,0,[\"handleClick\"]]],null],[28,\"perform\",[[23,0,[\"handleLeave\"]]],null],[23,0,[\"isClickable\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"tooltipTarget\"]]],null,{\"statements\":[[0,\"  \"],[14,1,[[28,\"hash\",null,[[\"tooltip\"],[[28,\"component\",[\"simple-chart-tooltip\"],[[\"target\",\"title\"],[[23,0,[\"tooltipTarget\"]],null]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-simple-charts/templates/components/simple-chart.hbs"
    }
  });

  _exports.default = _default;
});