define("ilios-common/mixins/session-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    async afterModel(model) {
      const store = this.get('store');
      const course = await model.get('course');
      await store.query('session', {
        filters: {
          course: course.get('id')
        }
      });
    }

  });

  _exports.default = _default;
});