define("ilios-common/components/learnergroup-tree", ["exports", "ember-concurrency", "ilios-common/templates/components/learnergroup-tree"], function (_exports, _emberConcurrency, _learnergroupTree) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    gt
  } = Ember.computed;

  var _default = Ember.Component.extend({
    layout: _learnergroupTree.default,
    learnerGroup: null,
    selectedGroups: null,
    filter: '',
    tagName: 'li',
    classNameBindings: ['hasChildren:strong:em', 'selectable::disabled'],
    isVisible: false,
    selectable: false,
    'data-test-learnergroup-tree': true,
    hasChildren: gt('learnerGroup.children.length', 0),

    didReceiveAttrs() {
      this._super(...arguments);

      this.get('updateIsVisible').perform();
    },

    /**
     * Recursivly search a group tree to see if there are any children
     * which have not been selected
    **/
    async hasUnSelectedChildren(children) {
      const selectedGroups = this.selectedGroups;
      const unselectedChildren = await Ember.RSVP.filter(children.toArray(), async child => {
        if (Ember.isEmpty(selectedGroups) || !selectedGroups.includes(child)) {
          return true;
        }

        const childChildren = await child.get('children');
        return await this.hasUnSelectedChildren(childChildren);
      });
      return unselectedChildren.length > 0;
    },

    /**
     * Controls visibility of the learner group element
     * If a filter has been applied use that first
     * If there are any unselected children then display
     * If the group is not already picked then display
    **/
    updateIsVisible: (0, _emberConcurrency.task)(function* () {
      const selectedGroups = this.get('selectedGroups');
      const learnerGroup = this.get('learnerGroup');
      const filterString = this.get('filter');
      const exp = new RegExp(filterString, 'gi');
      let children = yield learnerGroup.get('children');
      let hasUnSelectedChildren = yield this.hasUnSelectedChildren(children);
      let filterMatch = true;

      if (filterString && filterString.length > 0) {
        let filterTitle = yield learnerGroup.get('filterTitle');
        filterMatch = filterTitle.match(exp) != null;
      }

      let available = hasUnSelectedChildren || Ember.isEmpty(selectedGroups) || !selectedGroups.includes(learnerGroup);
      this.set('isVisible', filterMatch && available);
      this.set('selectable', available);
    }).restartable()
  });

  _exports.default = _default;
});