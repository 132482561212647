define("ilios-common/templates/components/objective-manage-descriptors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2m+qQwS9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[8],[0,\"\\n  \"],[1,[24,[\"objective\",\"textTitle\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[5,\"mesh-manager\",[],[[\"@add\",\"@remove\",\"@terms\",\"@editable\"],[[28,\"action\",[[23,0,[]],\"add\"],null],[28,\"action\",[[23,0,[]],\"remove\"],null],[22,\"objectiveDescriptors\"],[22,\"editable\"]]]],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/objective-manage-descriptors.hbs"
    }
  });

  _exports.default = _default;
});