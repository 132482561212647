define("ilios-common/templates/components/expand-collapse-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+0Hx+JPI",
    "block": "{\"symbols\":[\"@action\"],\"statements\":[[4,\"if\",[[24,[\"value\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\",true],[10,\"class\",\"collapse-button\"],[11,\"onclick\",[23,1,[]]],[8],[0,\"\\n    \"],[5,\"fa-icon\",[],[[\"@icon\"],[\"minus\"]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"button\",true],[10,\"class\",\"expand-button\"],[11,\"onclick\",[23,1,[]]],[8],[0,\"\\n    \"],[5,\"fa-icon\",[],[[\"@icon\"],[\"plus\"]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/expand-collapse-button.hbs"
    }
  });

  _exports.default = _default;
});