define("ember-froala-editor/components/froala-content", ["exports", "ember-froala-editor/templates/components/froala-content"], function (_exports, _froalaContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FroalaContentComponent = Ember.Component.extend({
    layout: _froalaContent.default,
    classNames: ['fr-view']
  });
  FroalaContentComponent.reopenClass({
    positionalParams: ['content']
  });
  var _default = FroalaContentComponent;
  _exports.default = _default;
});