define("ember-simple-charts/components/simple-chart-donut", ["exports", "d3-transition", "ember-simple-charts/mixins/chart-properties", "d3-selection", "d3-scale", "d3-scale-chromatic", "d3-shape", "d3-ease", "d3-interpolate"], function (_exports, _d3Transition, _chartProperties, _d3Selection, _d3Scale, _d3ScaleChromatic, _d3Shape, _d3Ease, _d3Interpolate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_chartProperties.default, {
    classNames: ['simple-chart-donut'],

    draw(passedHeight, passedWidth) {
      const height = Math.min(passedHeight, passedWidth);
      const width = Math.min(passedHeight, passedWidth);
      const data = Ember.get(this, 'data');
      const dataOrArray = data ? data : [{
        data: 1,
        label: '',
        empty: true
      }];
      const svg = (0, _d3Selection.select)(this.element);
      const radius = Math.min(width, height) / 2;
      const isIcon = Ember.get(this, 'isIcon');
      const hover = Ember.get(this, 'hover');
      const leave = Ember.get(this, 'leave');
      const click = Ember.get(this, 'click');
      const isClickable = Ember.get(this, 'isClickable');
      const values = Ember.A(dataOrArray).mapBy('data');
      const color = (0, _d3Scale.scaleSequential)(_d3ScaleChromatic.interpolateSinebow).domain([0, Math.max(...values)]);
      const donutWidth = width * .2;
      this.element.classList.add('loading');
      let createArc = (0, _d3Shape.arc)().innerRadius(radius - donutWidth).outerRadius(radius);
      let createPie = (0, _d3Shape.pie)().value(d => d.data).sort(null);
      let createLabelArc = (0, _d3Shape.arc)().outerRadius(radius - 32).innerRadius(radius - 32);
      svg.selectAll('.chart').remove();
      const chart = svg.append('g').attr('class', 'chart') //move the donut into the center of the chart
      .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');
      const path = chart.selectAll('.slice').data(createPie(dataOrArray)).enter().append('g').attr('class', 'slice').append('path').attr('class', 'slicepath').attr('d', createArc).attr('stroke', '#FFFFFF').attr('fill', d => color(d.data.data));
      chart.selectAll('path.slicepath').transition().ease(_d3Ease.easeLinear).duration(500).attrTween("d", b => {
        b.innerRadius = 0;
        const i = (0, _d3Interpolate.interpolate)({
          startAngle: 0,
          endAngle: 0
        }, b);
        return p => createArc(i(p));
      }).on('end', () => {
        if (this.element) {
          this.element.classList.replace('loading', 'loaded');
        }
      });

      if (!isIcon) {
        const text = chart.selectAll('.slice').append("text").style("color", d => {
          const rgb = color(d.data.data); //cut up rgb(1, 99, 245) into parts

          const parts = rgb.substr(4).split(')')[0].split(',');
          const r = parseInt(parts[0], 16);
          const g = parseInt(parts[1], 16);
          const b = parseInt(parts[2], 16); //Thanks to https://24ways.org/2010/calculating-color-contrast for this formula

          const yiq = (r * 299 + g * 587 + b * 114) / 1000;
          return yiq >= 256 ? 'rgb(0, 0, 0)' : 'rgb(255, 255, 255)';
        }).style("font-size", ".8rem").attr('transform', d => "translate(" + createLabelArc.centroid(d) + ")").attr("dy", ".40rem").attr("text-anchor", "middle").text(d => d.data.label);

        const handleHover = ({
          data
        }) => {
          const slices = svg.selectAll('.slice');
          const selectedSlice = slices.filter(({
            data: sliceData
          }) => sliceData.label === data.label);
          hover(data, selectedSlice.node());
        };

        path.on('mouseenter', handleHover);
        text.on('mouseenter', handleHover);
        path.on('mouseleave', leave);
        text.on('mouseleave', leave);

        if (isClickable) {
          path.on('click', ({
            data
          }) => {
            click(data);
          });
          path.style("cursor", "pointer");
          text.on('click', ({
            data
          }) => {
            click(data);
          });
          text.style("cursor", "pointer");
        }
      }
    }

  });

  _exports.default = _default;
});