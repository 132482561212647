define("ilios-common/models/learning-material", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo,
    hasMany,
    Model
  } = _emberData.default;

  var _default = Model.extend({
    title: attr('string'),
    description: attr('string'),
    uploadDate: attr('date'),
    originalAuthor: attr('string'),
    citation: attr('string'),
    copyrightPermission: attr('boolean'),
    copyrightRationale: attr('string'),
    filename: attr('string'),
    mimetype: attr('string'),
    filesize: attr('number'),
    link: attr('string'),
    absoluteFileUri: attr('string'),
    userRole: belongsTo('learning-material-user-role', {
      async: true
    }),
    status: belongsTo('learning-material-status', {
      async: true
    }),
    owningUser: belongsTo('user', {
      async: true
    }),
    sessionLearningMaterials: hasMany('session-learning-material', {
      async: true
    }),
    courseLearningMaterials: hasMany('course-learning-material', {
      async: true
    }),
    type: Ember.computed('filename', 'citation', 'link', function () {
      if (this.get('filename')) {
        return 'file';
      }

      if (this.get('citation')) {
        return 'citation';
      }

      if (this.get('link')) {
        return 'link';
      }

      return null;
    }),
    url: Ember.computed('link', 'citation', 'absoluteFileUri', function () {
      if (this.get('type') === 'file') {
        return this.get('absoluteFileUri');
      }

      if (this.get('type') === 'link') {
        return this.get('link');
      }

      return null;
    }),
    isFile: Ember.computed('type', function () {
      return this.get('type') === 'file';
    }),
    isLink: Ember.computed('type', function () {
      return this.get('type') === 'link';
    }),
    isCitation: Ember.computed('type', function () {
      return this.get('type') === 'citation';
    }),
    fileHash: null
  });

  _exports.default = _default;
});