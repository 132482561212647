define("ilios-common/components/session-leadership-expanded", ["exports", "ember-concurrency", "ilios-common/templates/components/session-leadership-expanded"], function (_exports, _emberConcurrency, _sessionLeadershipExpanded) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _sessionLeadershipExpanded.default,
    session: null,
    classNames: ['session-leadership-expanded'],
    administrators: null,
    isManaging: false,
    'data-test-session-leadership-expanded': true,

    didReceiveAttrs() {
      this._super(...arguments);

      const session = this.get('session');

      if (session) {
        session.get('administrators').then(administrators => {
          this.set('administrators', administrators.toArray());
        });
      }
    },

    actions: {
      addAdministrator(user) {
        this.add('administrators', user);
      },

      removeAdministrator(user) {
        this.remove('administrators', user);
      }

    },
    save: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(10);
      const administrators = this.get('administrators');
      let session = this.get('session');
      session.setProperties({
        administrators
      });
      this.get('expand')();
      yield session.save();
      this.get('setIsManaging')(false);
    }),

    add(where, user) {
      let arr = this.get(where).toArray();
      arr.pushObject(user);
      this.set(where, arr);
    },

    remove(where, user) {
      let arr = this.get(where).toArray();
      arr.removeObject(user);
      this.set(where, arr);
    }

  });

  _exports.default = _default;
});