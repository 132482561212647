define("ilios-common/services/preserve-scroll", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    shouldScrollDown: false,
    xPos: null,
    yPos: null
  });

  _exports.default = _default;
});