define("ilios-common/components/session-objective-list", ["exports", "ilios-common/mixins/sortable-objective-list", "ilios-common/templates/components/session-objective-list"], function (_exports, _sortableObjectiveList, _sessionObjectiveList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    alias
  } = Ember.computed;

  var _default = Ember.Component.extend(_sortableObjectiveList.default, {
    layout: _sessionObjectiveList.default,
    classNames: ['session-objective-list'],
    objectivesForRemovalConfirmation: null,
    session: alias('subject'),

    init() {
      this._super(...arguments);

      this.set('objectivesForRemovalConfirmation', []);
    },

    actions: {
      remove(objective) {
        objective.deleteRecord();
        objective.save();
      },

      cancelRemove(objective) {
        this.get('objectivesForRemovalConfirmation').removeObject(objective.get('id'));
      },

      confirmRemoval(objective) {
        this.get('objectivesForRemovalConfirmation').pushObject(objective.get('id'));
      }

    }
  });

  _exports.default = _default;
});