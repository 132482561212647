define('ember-resize-aware/mixins/resize-aware', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const isTesting = Ember.testing;

  exports.default = Ember.Mixin.create({
    unifiedEventHandler: Ember.inject.service(),

    didResize() /*width, height*/{}, // Overridden in subclass
    debounceRate: 200, // Overridden in subclass

    _previousWidth: null,
    _previousHeight: null,

    didInsertElement() {
      this._super(...arguments);
      this._handleResizeEvent = this._handleResizeEvent.bind(this);
      Ember.run.scheduleOnce('afterRender', this, () => Ember.get(this, 'unifiedEventHandler').register('window', 'resize', this._handleResizeEvent));
    },

    willDestroyElement() {
      this._super(...arguments);
      Ember.get(this, 'unifiedEventHandler').unregister('window', 'resize', this._handleResizeEvent);
    },

    _handleResizeEvent() {
      Ember.run.debounce(this, this._debouncedResizeEvent, isTesting ? 0 : Ember.get(this, 'debounceRate'));
    },

    _debouncedResizeEvent() {
      if (this.element) {
        const boundingRect = this.element.getBoundingClientRect();

        const newWidth = Math.floor(boundingRect.width);
        const newHeight = Math.floor(boundingRect.height);

        if (Ember.get(this, '_previousWidth') !== newWidth || Ember.get(this, '_previousHeight') !== newHeight) {
          Ember.run.next(this, () => !Ember.get(this, 'isDestroyed') && Ember.tryInvoke(this, 'didResize', [newWidth, newHeight]));
          Ember.setProperties(this, {
            _previousWidth: newWidth,
            _previousHeight: newHeight
          });
        }
      }
    }
  });
});