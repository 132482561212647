define("ilios-common/components/session-offerings", ["exports", "ilios-common/templates/components/session-offerings"], function (_exports, _sessionOfferings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    alias,
    oneWay
  } = Ember.computed;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    saving: false,
    layout: _sessionOfferings.default,
    tagName: 'section',
    classNames: ['session-offerings'],
    session: null,
    offeringEditorOn: false,
    'data-test-session-offerings': true,
    offerings: oneWay('session.offerings'),
    cohorts: alias('session.course.cohorts')
  });

  _exports.default = _default;
});