define("elemental-calendar/components/el-monthly-calendar", ["exports", "elemental-calendar/models/month", "elemental-calendar/templates/components/el-monthly-calendar", "elemental-calendar/models/event-list"], function (_exports, _month, _elMonthlyCalendar, _eventList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _elMonthlyCalendar.default,
    date: null,
    events: null,
    classNames: ['el-calendar'],
    month: Ember.computed('date', 'events.[]', function () {
      let eventList = _eventList.default.create({
        events: this.events
      });

      return _month.default.create({
        date: this.date,
        eventList: eventList
      });
    })
  });

  _exports.default = _default;
});