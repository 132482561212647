define("ilios-common/templates/components/html-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v29iQ2e+",
    "block": "{\"symbols\":[],\"statements\":[[5,\"froala-editor\",[],[[\"@content\",\"@update\",\"@options\"],[[22,\"content\"],[22,\"update\"],[22,\"options\"]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/html-editor.hbs"
    }
  });

  _exports.default = _default;
});