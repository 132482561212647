define("ilios-common/components/session-offerings-list", ["exports", "ilios-common/templates/components/session-offerings-list", "ilios-common/utils/offering-date-block"], function (_exports, _sessionOfferingsList, _offeringDateBlock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    oneWay
  } = Ember.computed;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    layout: _sessionOfferingsList.default,
    classNames: ['session-offerings-list'],
    session: null,
    editable: true,
    'data-test-session-offerings-list': true,
    offerings: oneWay('session.offerings'),

    /**
     * @property offeringBlocks
     * @type {Ember.computed}
     * @public
     */
    offeringBlocks: Ember.computed('offerings.@each.{startDate,endDate,room,learnerGroups,instructorGroups}', async function () {
      let offerings = await this.get('offerings');

      if (Ember.isEmpty(offerings)) {
        return [];
      }

      let dateBlocks = {};
      offerings.forEach(offering => {
        let key = offering.get('dateKey');

        if (!(key in dateBlocks)) {
          dateBlocks[key] = _offeringDateBlock.default.create({
            dateKey: key
          });
        }

        dateBlocks[key].addOffering(offering);
      }); //convert indexed object to array

      let dateBlockArray = [];
      let key;

      for (key in dateBlocks) {
        dateBlockArray.pushObject(dateBlocks[key]);
      }

      return dateBlockArray.sortBy('dateStamp');
    }),
    actions: {
      removeOffering(offering) {
        let session = this.get('session');
        session.get('offerings').then(offerings => {
          offerings.removeObject(offering);
          offering.deleteRecord();
          offering.save();
        });
      }

    }
  });

  _exports.default = _default;
});