define("ilios-common/helpers/not-in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.notIn = notIn;
  _exports.default = void 0;

  function notIn([values, item]) {
    if (!values) {
      return false;
    }

    if (!item) {
      return false;
    }

    return !values.includes(item);
  }

  var _default = Ember.Helper.extend({
    values: [],

    compute([values, item]) {
      this.set('values', values);
      return notIn([values, item]);
    },

    /* eslint ember/no-observers: 0 */
    recomputeOnArrayChange: Ember.observer('values.[]', function () {
      this.recompute();
    })
  });

  _exports.default = _default;
});