define("ilios-common/templates/components/ilios-calendar-multiday-event", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kD/EVmW6",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"moment-format\",[[24,[\"event\",\"startDate\"]],\"M/D/YY h:mma\"],null],false],[0,\"\\n-\\n\"],[1,[28,\"moment-format\",[[24,[\"event\",\"endDate\"]],\"M/D/YY h:mma\"],null],false],[0,\"\\n\"],[7,\"span\",false],[12,\"class\",[28,\"if\",[[28,\"and\",[[24,[\"clickable\"]],[24,[\"isEventSelectable\"]]],null],\"clickable\"],null]],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],\"selectEvent\",[24,[\"event\"]]]],[8],[0,\"\\n  \"],[1,[24,[\"event\",\"name\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[24,[\"event\",\"location\"]],false],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/ilios-calendar-multiday-event.hbs"
    }
  });

  _exports.default = _default;
});