define("ilios-common/services/search", ["exports", "fetch"], function (_exports, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    iliosConfig: Ember.inject.service(),
    session: Ember.inject.service(),
    authHeaders: Ember.computed('session.isAuthenticated', function () {
      const session = this.session;
      const {
        jwt
      } = session.data.authenticated;
      let headers = {};

      if (jwt) {
        headers['X-JWT-Authorization'] = "Token ".concat(jwt);
      }

      return new Headers(headers);
    }),
    host: Ember.computed('iliosConfig.apiHost', function () {
      return this.iliosConfig.apiHost ? this.iliosConfig.apiHost : window.location.protocol + '//' + window.location.host;
    }),

    /**
     * Find courses
     * @param {string} q
     */
    async forCurriculum(q, onlySuggestEnabled = false) {
      return this.search('curriculum', q, 1000, onlySuggestEnabled);
    },

    /**
     * Find users
     * @param {string} q
     * @param {number} size
     */
    async forUsers(q, size = 100, onlySuggestEnabled = false) {
      const {
        users,
        autocomplete
      } = await this.search('users', q, size, onlySuggestEnabled);
      const mappedUsers = users.map(user => {
        user.fullName = this.getUserFullName(user);
        return user;
      });
      return {
        autocomplete,
        users: mappedUsers
      };
    },

    async search(type, q, size, onlySuggestEnabled) {
      const onlySuggest = onlySuggestEnabled ? '&onlySuggest=true' : '';
      const url = "".concat(this.host, "/api/search/v1/").concat(type, "?q=").concat(q, "&size=").concat(size).concat(onlySuggest);
      const response = await (0, _fetch.default)(url, {
        headers: this.authHeaders
      });
      const {
        results
      } = await response.json();
      return results;
    },

    getUserFullName(user) {
      if (user.displayName) {
        return user.displayName;
      }

      if (!user.firstName || !user.lastName) {
        return '';
      }

      const middleInitial = user.middleName ? user.middleName.charAt(0) : false;

      if (middleInitial) {
        return "".concat(user.firstName, " ").concat(middleInitial, ". ").concat(user.lastName);
      } else {
        return "".concat(user.firstName, " ").concat(user.lastName);
      }
    }

  });

  _exports.default = _default;
});