define('ember-cli-flash/flash/object', ['exports', 'ember-cli-flash/utils/computed'], function (exports, _computed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend(Ember.Evented, {
    exitTimer: null,
    exiting: false,
    isExitable: true,
    initializedTime: null,

    _guid: (0, _computed.guidFor)('message').readOnly(),

    init() {
      this._super(...arguments);

      if (Ember.get(this, 'sticky')) {
        return;
      }
      this.timerTask();
      this._setInitializedTime();
    },

    destroyMessage() {
      this._cancelTimer();
      let exitTaskInstance = Ember.get(this, 'exitTaskInstance');
      if (exitTaskInstance) {
        Ember.run.cancel(exitTaskInstance);
        this._teardown();
      } else {
        this.exitTimerTask();
      }
    },

    exitMessage() {
      if (!Ember.get(this, 'isExitable')) {
        return;
      }
      this.exitTimerTask();
      this.trigger('didExitMessage');
    },

    willDestroy() {
      const onDestroy = Ember.get(this, 'onDestroy');
      if (onDestroy) {
        onDestroy();
      }

      this._cancelTimer();
      this._cancelTimer('exitTaskInstance');
      this._super(...arguments);
    },

    preventExit() {
      Ember.set(this, 'isExitable', false);
    },

    allowExit() {
      Ember.set(this, 'isExitable', true);
      this._checkIfShouldExit();
    },

    timerTask() {
      let timeout = Ember.get(this, 'timeout');
      if (!timeout) {
        return;
      }
      let timerTaskInstance = Ember.run.later(() => {
        this.exitMessage();
      }, timeout);
      Ember.set(this, 'timerTaskInstance', timerTaskInstance);
    },

    exitTimerTask() {
      if (Ember.get(this, 'isDestroyed')) {
        return;
      }
      Ember.set(this, 'exiting', true);
      let extendedTimeout = Ember.get(this, 'extendedTimeout');
      if (extendedTimeout) {
        let exitTaskInstance = Ember.run.later(() => {
          this._teardown();
        }, extendedTimeout);
        Ember.set(this, 'exitTaskInstance', exitTaskInstance);
      } else {
        this._teardown();
      }
    },

    // private
    _setInitializedTime() {
      let currentTime = new Date().getTime();

      Ember.set(this, 'initializedTime', currentTime);
    },

    _getElapsedTime() {
      let currentTime = new Date().getTime();
      let initializedTime = Ember.get(this, 'initializedTime');

      return currentTime - initializedTime;
    },

    _cancelTimer() {
      let taskName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'timerTaskInstance';

      let taskInstance = Ember.get(this, taskName);
      if (taskInstance) {
        Ember.run.cancel(taskInstance);
      }
    },

    _checkIfShouldExit() {
      if (this._getElapsedTime() >= Ember.get(this, 'timeout') && !Ember.get(this, 'sticky')) {
        this._cancelTimer();
        this.exitMessage();
      }
    },

    _teardown() {
      const queue = Ember.get(this, 'flashService.queue');
      if (queue) {
        queue.removeObject(this);
      }
      this.destroy();
      this.trigger('didDestroyMessage');
    }
  });
});