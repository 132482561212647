define("ilios-common/components/ilios-calendar-event", ["exports", "elemental-calendar/components/calendar-event", "ilios-common/templates/components/ilios-calendar-event", "moment", "ilios-common/mixins/tooltip-content", "ilios-common/utils/color-change"], function (_exports, _calendarEvent, _iliosCalendarEvent, _moment, _tooltipContent, _colorChange) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Handlebars
  } = Ember;
  const {
    notEmpty,
    or
  } = Ember.computed;
  const {
    Utils
  } = Handlebars;
  const {
    escapeExpression
  } = Utils;

  var _default = _calendarEvent.default.extend(_tooltipContent.default, {
    intl: Ember.inject.service(),
    layout: _iliosCalendarEvent.default,
    event: null,
    timeFormat: 'h:mma',
    isDay: false,
    classNameBindings: [':event', ':event-pos', ':ilios-calendar-event', 'isDay:day', 'clickable:clickable', 'isIlm'],
    'data-test-ilios-calendar-event': true,
    daysToShowAlert: null,
    isIlm: notEmpty('event.ilmSession'),
    isOffering: notEmpty('event.offering'),
    clickable: or('isIlm', 'isOffering'),
    formattedInstructors: Ember.computed('event.instructors.[]', function () {
      const intl = this.get('intl');
      let instructors = this.get('event.instructors');

      if (!Ember.isArray(instructors) || !instructors.length) {
        return '';
      }

      if (3 > instructors.length) {
        return instructors.join(', ');
      } else {
        return instructors.slice(0, 2).join(', ') + ' ' + intl.t('general.etAl');
      }
    }),
    style: Ember.computed('event', function () {
      const event = this.get('event');

      if (event == null) {
        return new Ember.String.htmlSafe('');
      }

      const darkcolor = (0, _colorChange.default)(event.color, -0.15);
      return new Ember.String.htmlSafe("background-color: ".concat(escapeExpression(event.color), ";\n       border-left: 4px solid ").concat(escapeExpression(darkcolor), ";\n       top: ").concat(escapeExpression(this.calculateTop()), "%;\n       height: ").concat(escapeExpression(this.calculateHeight()), "%;\n       left: ").concat(escapeExpression(this.calculateLeft()), "%;\n       width: ").concat(escapeExpression(this.calculateWidth()), "%;"));
    }),
    recentlyUpdated: Ember.computed('event.lastModified', function () {
      const lastModifiedDate = (0, _moment.default)(this.get('event.lastModified'));
      const today = (0, _moment.default)();
      const daysSinceLastUpdate = today.diff(lastModifiedDate, 'days');
      return daysSinceLastUpdate < 6 ? true : false;
    }),

    click() {
      if (this.get('clickable')) {
        this.get('selectEvent')();
      }
    }

  });

  _exports.default = _default;
});