define("ilios-common/mixins/dashboard-controller", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    queryParams: ['academicYear', 'cohorts', 'courseFilters', 'courseLevels', 'courses', 'date', 'mySchedule', 'school', 'sessionTypes', 'show', 'showFilters', 'terms', 'view'],
    academicYear: null,
    cohorts: '',
    courseFilters: true,
    courseLevels: '',
    courses: '',
    date: null,
    mySchedule: true,
    school: null,
    sessionTypes: '',
    show: 'week',
    showFilters: false,
    terms: '',
    view: 'week',
    selectedCohorts: Ember.computed('cohorts', async function () {
      const cohortIds = this.cohorts;
      return cohortIds ? await Ember.RSVP.all(this.fetchModels(cohortIds, 'cohort')) : [];
    }),
    selectedCourseLevels: Ember.computed('courseLevels', function () {
      const courseLevels = this.courseLevels;
      return courseLevels ? courseLevels.split(',').map(level => parseInt(level, 10)) : [];
    }),
    selectedCourses: Ember.computed('courses', async function () {
      const courseIds = this.courses;
      return courseIds ? await Ember.RSVP.all(this.fetchModels(courseIds, 'course')) : [];
    }),
    selectedSessionTypes: Ember.computed('sessionTypes', async function () {
      const sessionTypeIds = this.sessionTypes;
      return sessionTypeIds ? await Ember.RSVP.all(this.fetchModels(sessionTypeIds, 'session-type')) : [];
    }),
    selectedTerms: Ember.computed('terms', async function () {
      const termIds = this.terms;
      return termIds ? await Ember.RSVP.all(this.fetchModels(termIds, 'term')) : [];
    }),
    selectedAcademicYear: Ember.computed('academicYear', 'model.academicYears.[]', function () {
      const academicYear = this.get('academicYear');
      const {
        academicYears
      } = this.get('model');
      return academicYears.findBy('id', academicYear);
    }),
    selectedSchool: Ember.computed('school', 'model.schools.[]', function () {
      const schoolId = this.get('school');
      const {
        schools
      } = this.get('model');
      return schools.findBy('id', schoolId);
    }),
    selectedDate: Ember.computed('date', function () {
      const date = this.get('date');

      if (date) {
        return (0, _moment.default)(date, 'YYYY-MM-DD').format();
      }

      return (0, _moment.default)().format();
    }),
    selectedView: Ember.computed('view', function () {
      let view = this.get('view');
      let viewOptions = ['month', 'week', 'day'];

      if (viewOptions.indexOf(view) === -1) {
        view = 'week';
      }

      return view;
    }),

    fetchModels(ids, modelName) {
      const store = this.store;
      return ids.split(',').map(id => {
        const model = store.peekRecord(modelName, id);
        return model ? model : store.findRecord(modelName, id);
      });
    },

    actions: {
      changeDate(newDate) {
        this.set('date', (0, _moment.default)(newDate).format('YYYY-MM-DD'));
      },

      selectEvent(event) {
        this.transitionToRoute('events', event.slug);
      },

      toggleMySchedule() {
        if (this.get('mySchedule')) {
          this.setProperties({
            mySchedule: false,
            school: null
          });
        } else {
          this.set('mySchedule', true);
        }
      },

      toggleShowFilters() {
        if (this.get('showFilters')) {
          this.setProperties({
            showFilters: false,
            school: null,
            academicYear: null,
            courseFilters: true
          });
        } else {
          this.set('showFilters', true);
        }
      },

      updateCohorts(cohortId) {
        const cohortIds = this.cohorts;

        if (cohortIds) {
          const idArray = cohortIds.split(',');

          if (idArray.includes(cohortId)) {
            idArray.removeObject(cohortId);
            this.set('cohorts', idArray.join(','));
          } else {
            this.set('cohorts', cohortIds + ",".concat(cohortId));
          }
        } else {
          this.set('cohorts', cohortId);
        }
      },

      updateCourseLevels(level) {
        const id = level.toString();
        const levels = this.courseLevels;

        if (levels) {
          const idArray = levels.split(',');

          if (idArray.includes(id)) {
            idArray.removeObject(id);
            this.set('courseLevels', idArray.join(','));
          } else {
            this.set('courseLevels', levels + ",".concat(id));
          }
        } else {
          this.set('courseLevels', id);
        }
      },

      updateCourses(courseId) {
        const courseIds = this.courses;

        if (courseIds) {
          const idArray = courseIds.split(',');

          if (idArray.includes(courseId)) {
            idArray.removeObject(courseId);
            this.set('courses', idArray.join(','));
          } else {
            this.set('courses', courseIds + ",".concat(courseId));
          }
        } else {
          this.set('courses', courseId);
        }
      },

      updateSessionTypes(sessionTypeId) {
        const sessionTypeIds = this.sessionTypes;

        if (sessionTypeIds) {
          const idArray = sessionTypeIds.split(',');

          if (idArray.includes(sessionTypeId)) {
            idArray.removeObject(sessionTypeId);
            this.set('sessionTypes', idArray.join(','));
          } else {
            this.set('sessionTypes', sessionTypeIds + ",".concat(sessionTypeId));
          }
        } else {
          this.set('sessionTypes', sessionTypeId);
        }
      },

      updateTerms(term) {
        const termId = term.id;
        const termIds = this.terms;

        if (termIds) {
          const idArray = termIds.split(',');

          if (idArray.includes(termId)) {
            idArray.removeObject(termId);
            this.set('terms', idArray.join(','));
          } else {
            this.set('terms', termIds + ",".concat(termId));
          }
        } else {
          this.set('terms', termId);
        }
      },

      clearFilters() {
        this.setProperties({
          cohorts: '',
          courseLevels: '',
          courses: '',
          sessionTypes: '',
          terms: ''
        });
      }

    }
  });

  _exports.default = _default;
});