define("ilios-common/components/publication-status", ["exports", "ilios-common/templates/components/publication-status"], function (_exports, _publicationStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    alias
  } = Ember.computed;

  var _default = Ember.Component.extend({
    layout: _publicationStatus.default,
    publishedLangKey: 'general.published',
    scheduledLangKey: 'general.scheduled',
    notPublishedLangKey: 'general.notPublished',
    showIcon: true,
    showText: true,
    tagName: 'span',
    classNameBindings: [':status', ':publication-status', 'publicationStatus'],
    item: null,
    isPublished: alias('item.isPublished'),
    isScheduled: alias('item.publishedAsTbd'),
    textKey: Ember.computed('publishedLangKey', 'scheduledLangKey', 'notPublishedLangKey', 'isPublished', 'isScheduled', function () {
      const isPublished = this.get('isPublished');
      const isScheduled = this.get('isScheduled');
      const publishedLangKey = this.get('publishedLangKey');
      const scheduledLangKey = this.get('scheduledLangKey');
      const notPublishedLangKey = this.get('notPublishedLangKey');

      if (isScheduled) {
        return scheduledLangKey;
      }

      if (isPublished) {
        return publishedLangKey;
      }

      return notPublishedLangKey;
    }),
    iconKey: Ember.computed('isPublished', 'isScheduled', function () {
      const isPublished = this.get('isPublished');
      const isScheduled = this.get('isScheduled');

      if (isScheduled) {
        return 'clock';
      }

      if (isPublished) {
        return 'star';
      }

      return 'star-half-alt';
    }),
    publicationStatus: Ember.computed('isPublished', 'isScheduled', function () {
      const isPublished = this.get('isPublished');
      const isScheduled = this.get('isScheduled');

      if (isScheduled) {
        return 'scheduled';
      } else if (isPublished) {
        return 'published';
      }

      return 'notpublished';
    })
  });

  _exports.default = _default;
});