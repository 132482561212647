define("ilios-common/components/publish-menu", ["exports", "ilios-common/utils/scroll-to", "ilios-common/templates/components/publish-menu"], function (_exports, _scrollTo, _publishMenu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    layout: _publishMenu.default,
    classNameBindings: ['publicationStatus', ':publish-menu'],
    publicationStatus: 'notpublished',
    icon: 'cloud',
    title: null,
    showAsIs: false,
    showPublish: false,
    showReview: false,
    showTbd: false,
    showUnPublish: false,
    reviewRoute: null,
    reviewObject: null,
    parentObject: null,
    publishTranslation: '',
    unpublishTranslation: '',
    actions: {
      scrollToCoursePublication() {
        this.router.transitionTo(this.reviewRoute, this.reviewObject);
        (0, _scrollTo.default)('.course-publicationcheck');
      },

      scrollToSessionPublication() {
        const reviewRoute = this.reviewRoute;
        const parentObjectId = this.parentObject.id;
        const reviewObject = this.reviewObject;
        this.router.transitionTo(reviewRoute, parentObjectId, reviewObject);
        (0, _scrollTo.default)('.session-publicationcheck-content');
      }

    }
  });

  _exports.default = _default;
});