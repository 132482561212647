define("ilios-common/templates/components/new-offering", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z3xRdL07",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"new-offering-title\"],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"general.newOffering\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"choose-offering-type\"],[8],[0,\"\\n  \"],[5,\"click-choice-buttons\",[],[[\"@buttonContent1\",\"@buttonContent2\",\"@firstChoicePicked\",\"@toggle\"],[[28,\"t\",[\"general.smallGroups\"],null],[28,\"t\",[\"general.offering\"],null],[22,\"smallGroupMode\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"smallGroupMode\"]]],null]],null]]]],[0,\"\\n\"],[9],[0,\"\\n\"],[5,\"offering-form\",[],[[\"@showRoom\",\"@showMakeRecurring\",\"@showInstructors\",\"@cohorts\",\"@courseStartDate\",\"@courseEndDate\",\"@close\",\"@save\",\"@smallGroupMode\",\"@session\"],[[28,\"not\",[[24,[\"smallGroupMode\"]]],null],true,[28,\"not\",[[24,[\"smallGroupMode\"]]],null],[22,\"cohorts\"],[22,\"courseStartDate\"],[22,\"courseEndDate\"],[22,\"close\"],[28,\"action\",[[23,0,[]],\"save\"],null],[22,\"smallGroupMode\"],[22,\"session\"]]]],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/new-offering.hbs"
    }
  });

  _exports.default = _default;
});