define("ilios-common/components/detail-competencies", ["exports", "ilios-common/templates/components/detail-competencies"], function (_exports, _detailCompetencies) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _detailCompetencies.default,
    course: null,
    editable: true,
    tagName: 'section',
    classNameBindings: [':detail-competencies', 'showCollapsible:collapsible'],

    /**
     *
     * @property showCollapsible
     * @type {Ember.computed}
     * @public
     */
    showCollapsible: Ember.computed('course.competencies.[]', async function () {
      const competencies = await this.course.get('competencies');
      return competencies.length;
    }),
    actions: {
      collapse() {
        this.get('course.competencies').then(competencies => {
          const collapse = this.get('collapse');

          if (competencies.length) {
            collapse();
          }
        });
      }

    }
  });

  _exports.default = _default;
});