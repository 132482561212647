define("ilios-common/components/offering-form", ["exports", "moment", "ember-cp-validations", "ilios-common/mixins/validation-error-display", "ember-concurrency", "ilios-common/templates/components/offering-form"], function (_exports, _moment, _emberCpValidations, _validationErrorDisplay, _emberConcurrency, _offeringForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEBOUNCE_DELAY = 600;
  const Validations = (0, _emberCpValidations.buildValidations)({
    room: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 255
    })],
    numberOfWeeks: {
      dependentKeys: ['model.makeRecurring'],
      disabled: Ember.computed.not('model.makeRecurring'),
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true
      }), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true,
        gt: 0
      })]
    },
    durationHours: [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: true,
      gte: 0
    })],
    durationMinutes: [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: true,
      gte: 0,
      lte: 59
    })],
    learnerGroups: {
      dependentKeys: ['model.smallGroupMode'],
      disabled: Ember.computed.not('model.smallGroupMode'),
      validators: [(0, _emberCpValidations.validator)('length', {
        min: 1,
        messageKey: 'general.smallGroupMessage'
      })]
    }
  });

  var _default = Ember.Component.extend(_validationErrorDisplay.default, Validations, {
    currentUser: Ember.inject.service(),
    layout: _offeringForm.default,
    classNames: ['offering-form'],
    startDate: null,
    endDate: null,
    room: 'TBD',
    cohorts: null,
    learnerGroups: null,
    showRoom: false,
    showMakeRecurring: false,
    showInstructors: false,
    showOfferingCalendar: false,
    makeRecurring: false,
    recurringDays: null,
    numberOfWeeks: 1,
    instructors: null,
    instructorGroups: null,
    courseStartDate: null,
    courseEndDate: null,
    smallGroupMode: false,
    offeringsToSave: 0,
    savedOfferings: 0,
    recurringDayOptions: null,
    loaded: false,
    'data-test-offering-form': true,
    associatedSchools: Ember.computed('cohorts.[]', function () {
      return new Ember.RSVP.Promise(resolve => {
        const cohorts = this.get('cohorts');

        if (Ember.isEmpty(cohorts)) {
          resolve([]);
        } else {
          Ember.RSVP.map(cohorts.toArray(), cohort => {
            return cohort.get('school');
          }).then(cohortSchools => {
            let schools = [];
            schools.pushObjects(cohortSchools);
            resolve(schools.uniq().toArray());
          });
        }
      });
    }),
    availableInstructorGroups: Ember.computed('associatedSchools.[]', function () {
      return new Ember.RSVP.Promise(resolve => {
        this.get('associatedSchools').then(associatedSchools => {
          Ember.RSVP.map(associatedSchools, school => {
            return school.get('instructorGroups');
          }).then(allInstructorGropus => {
            let flat = allInstructorGropus.reduce((flattened, obj) => {
              return flattened.pushObjects(obj.toArray());
            }, []);
            resolve(flat);
          });
        });
      });
    }),
    defaultStartDate: Ember.computed('courseStartDate', 'courseEndDate', function () {
      const today = (0, _moment.default)();
      const courseStartDate = this.get('courseStartDate');
      const courseEndDate = this.get('courseEndDate');
      let defaultStartDate = today.clone();

      if (Ember.isPresent(courseStartDate) && today.isBefore(courseStartDate)) {
        defaultStartDate = (0, _moment.default)(courseStartDate);
      }

      if (Ember.isPresent(courseEndDate) && today.isAfter(courseEndDate)) {
        defaultStartDate = (0, _moment.default)(courseEndDate);
      }

      return defaultStartDate.toDate();
    }),
    durationHours: Ember.computed('startDate', 'endDate', function () {
      const startDate = this.get('startDate');
      const endDate = this.get('endDate');

      if (Ember.isEmpty(startDate) || Ember.isEmpty(endDate)) {
        return 1;
      }

      let mStart = (0, _moment.default)(startDate);
      let mEnd = (0, _moment.default)(endDate);
      let diffInHours = mEnd.diff(mStart, 'hours');
      return diffInHours;
    }),
    durationMinutes: Ember.computed('startDate', 'endDate', function () {
      const startDate = this.get('startDate');
      const endDate = this.get('endDate');

      if (Ember.isEmpty(startDate) || Ember.isEmpty(endDate)) {
        return 0;
      }

      let mStart = (0, _moment.default)(startDate);
      let mEnd = (0, _moment.default)(endDate);
      const endHour = mEnd.hour();
      const endMinute = mEnd.minute();
      mStart.hour(endHour);
      const startMinute = mStart.minute();
      let diff = 0;

      if (endMinute > startMinute) {
        diff = endMinute - startMinute;
      } else if (endMinute < startMinute) {
        diff = 60 - startMinute + endMinute;
      }

      return diff;
    }),
    lowestLearnerGroupLeaves: Ember.computed('learnerGroups.[]', function () {
      const learnerGroups = this.get('learnerGroups');
      const ids = learnerGroups.mapBy('id');
      return new Ember.RSVP.Promise(resolve => {
        Ember.RSVP.filter(learnerGroups, group => {
          return new Ember.RSVP.Promise(resolve => {
            group.get('allDescendants').then(children => {
              let selectedChildren = children.filter(child => ids.includes(child.get('id')));
              resolve(selectedChildren.length === 0);
            });
          });
        }).then(lowestLeaves => resolve(lowestLeaves));
      });
    }),

    init() {
      this._super(...arguments);

      this.set('recurringDayOptions', [{
        day: '0',
        t: 'general.sunday'
      }, {
        day: '1',
        t: 'general.monday'
      }, {
        day: '2',
        t: 'general.tuesday'
      }, {
        day: '3',
        t: 'general.wednesday'
      }, {
        day: '4',
        t: 'general.thursday'
      }, {
        day: '5',
        t: 'general.friday'
      }, {
        day: '6',
        t: 'general.saturday'
      }]);
    },

    didReceiveAttrs() {
      this._super(...arguments);

      const offering = this.get('offering');

      if (Ember.isPresent(offering)) {
        this.get('loadAttrsFromOffering').perform(offering);
      } else {
        this.loadDefaultAttrs();
      }
    },

    actions: {
      addLearnerGroup(learnerGroup) {
        let learnerGroups = this.get('learnerGroups').toArray();
        learnerGroups.addObject(learnerGroup);
        learnerGroup.get('allDescendants').then(function (descendants) {
          learnerGroups.addObjects(descendants);
        }); //re-create the object so we trigger downstream didReceiveAttrs

        this.set('learnerGroups', learnerGroups);
      },

      removeLearnerGroup(learnerGroup) {
        let learnerGroups = this.get('learnerGroups').toArray();
        learnerGroups.removeObject(learnerGroup);
        learnerGroup.get('allDescendants').then(function (descendants) {
          learnerGroups.removeObjects(descendants);
        }); //re-create the object so we trigger downstream didReceiveAttrs

        this.set('learnerGroups', learnerGroups);
      },

      toggleRecurringDay(day) {
        let recurringDays = this.get('recurringDays');

        if (recurringDays.includes(day)) {
          recurringDays.removeObject(day);
        } else {
          recurringDays.pushObject(day);
        }
      },

      addInstructor(user) {
        let instructors = this.get('instructors').toArray();
        instructors.addObject(user); //re-create the object so we trigger downstream didReceiveAttrs

        this.set('instructors', instructors);
      },

      addInstructorGroup(group) {
        let instructorGroups = this.get('instructorGroups').toArray();
        instructorGroups.addObject(group); //re-create the object so we trigger downstream didReceiveAttrs

        this.set('instructorGroups', instructorGroups);
      },

      removeInstructor(user) {
        let instructors = this.get('instructors').toArray();
        instructors.removeObject(user); //re-create the object so we trigger downstream didReceiveAttrs

        this.set('instructors', instructors);
      },

      removeInstructorGroup(group) {
        let instructorGroups = this.get('instructorGroups').toArray();
        instructorGroups.removeObject(group); //re-create the object so we trigger downstream didReceiveAttrs

        this.set('instructorGroups', instructorGroups);
      },

      updateStartTime(value, type) {
        let startDate = (0, _moment.default)(this.get('startDate'));

        if (type === 'hour') {
          startDate.hour(value);
        } else {
          startDate.minute(value);
        }

        const minutes = this.get('durationMinutes');
        const hours = this.get('durationHours');
        let endDate = startDate.clone().add(hours, 'hours').add(minutes, 'minutes');
        this.set('startDate', startDate.toDate());
        this.set('endDate', endDate.toDate());
      },

      updateStartDate(date) {
        const minutes = this.get('durationMinutes');
        const hours = this.get('durationHours');
        const currentStartDate = (0, _moment.default)(this.get('startDate'));
        let startDate = (0, _moment.default)(date).hour(currentStartDate.hour()).minute(currentStartDate.minute()).toDate();
        let endDate = (0, _moment.default)(startDate).add(hours, 'hours').add(minutes, 'minutes').toDate();
        this.setProperties({
          startDate,
          endDate
        });
      }

    },
    makeRecurringOfferingObjects: (0, _emberConcurrency.task)(function* () {
      const {
        startDate,
        endDate,
        room,
        instructorGroups,
        instructors,
        numberOfWeeks,
        recurringDays
      } = this.getProperties('startDate', 'endDate', 'room', 'instructorGroups', 'instructors', 'numberOfWeeks', 'recurringDays');
      const makeRecurring = this.get('makeRecurring');
      const learnerGroups = yield this.get('lowestLearnerGroupLeaves');
      let offerings = [];
      offerings.push({
        startDate,
        endDate,
        room,
        learnerGroups,
        instructorGroups,
        instructors
      });

      if (!makeRecurring) {
        return offerings;
      }

      const userPickedDay = (0, _moment.default)(startDate).day(); //convert strings to numbers use parseFloat because parseInt takes a second
      //argument and gets thrown off by map sending that argument as the counter

      const recurringDayInts = recurringDays.map(parseFloat).sort(); // Add offerings for the rest of first week
      //only days AFTER the initial day are considered

      recurringDayInts.forEach(day => {
        if (day > userPickedDay) {
          let obj = {
            room,
            learnerGroups,
            instructorGroups,
            instructors
          };
          obj.startDate = (0, _moment.default)(startDate).day(day).toDate();
          obj.endDate = (0, _moment.default)(endDate).day(day).toDate();
          offerings.push(obj);
        }
      });
      recurringDayInts.pushObject(userPickedDay);
      recurringDayInts.sort();

      for (let i = 1; i < numberOfWeeks; i++) {
        recurringDayInts.forEach(day => {
          let obj = {
            room,
            learnerGroups,
            instructorGroups,
            instructors
          };
          obj.startDate = (0, _moment.default)(startDate).day(day).add(i, 'weeks').toDate();
          obj.endDate = (0, _moment.default)(endDate).day(day).add(i, 'weeks').toDate();
          offerings.push(obj);
        });
      }

      return offerings;
    }),
    makeSmallGroupOfferingObjects: (0, _emberConcurrency.task)(function* (offerings) {
      const smallGroupMode = this.get('smallGroupMode');

      if (!smallGroupMode) {
        return offerings;
      }

      let smallGroupOfferings = [];

      for (let i = 0; i < offerings.length; i++) {
        let {
          startDate,
          endDate,
          room,
          learnerGroups
        } = offerings[i];

        for (let j = 0; j < learnerGroups.length; j++) {
          let learnerGroup = learnerGroups[j];
          let defaultLocation = learnerGroup.get('location');

          if (Ember.isPresent(defaultLocation)) {
            room = defaultLocation;
          }

          let instructors = yield learnerGroup.get('instructors');
          let instructorGroups = yield learnerGroup.get('instructorGroups');
          let offering = {
            startDate,
            endDate,
            room,
            instructorGroups,
            instructors
          };
          offering.learnerGroups = [learnerGroup];
          smallGroupOfferings.pushObject(offering);
        }
      }

      return smallGroupOfferings;
    }),

    loadDefaultAttrs() {
      let loaded = this.get('loaded');

      if (loaded) {
        return;
      }

      let startDate = (0, _moment.default)(this.get('defaultStartDate')).hour(8).minute(0).second(0).toDate();
      let endDate = (0, _moment.default)(this.get('defaultStartDate')).hour(9).minute(0).second(0).toDate();
      const room = 'TBD';
      const learnerGroups = [];
      const recurringDays = [];
      const instructors = [];
      const instructorGroups = [];
      loaded = true;
      this.setProperties({
        startDate,
        endDate,
        room,
        learnerGroups,
        recurringDays,
        instructors,
        instructorGroups,
        loaded
      });
    },

    loadAttrsFromOffering: (0, _emberConcurrency.task)(function* (offering) {
      let loaded = this.get('loaded');

      if (loaded) {
        return;
      }

      const startDate = offering.get('startDate');
      const endDate = offering.get('endDate');
      const room = offering.get('room');
      const recurringDays = [];
      let obj = yield Ember.RSVP.hash({
        learnerGroups: offering.get('learnerGroups'),
        instructors: offering.get('instructors'),
        instructorGroups: offering.get('instructorGroups')
      });
      const learnerGroups = obj.learnerGroups.toArray();
      const instructors = obj.instructors.toArray();
      const instructorGroups = obj.instructorGroups.toArray();
      loaded = true;
      this.setProperties({
        startDate,
        endDate,
        room,
        learnerGroups,
        recurringDays,
        instructors,
        instructorGroups,
        loaded
      });
    }).drop(),
    saveOffering: (0, _emberConcurrency.task)(function* () {
      this.set('offeringsToSave', 0);
      this.set('savedOfferings', 0);
      this.send('addErrorDisplaysFor', ['room', 'numberOfWeeks', 'durationHours', 'durationMinutes', 'learnerGroups']);
      yield (0, _emberConcurrency.timeout)(10);
      let {
        validations
      } = yield this.validate();

      if (validations.get('isInvalid')) {
        return;
      }

      let offerings = yield this.get('makeRecurringOfferingObjects').perform();
      offerings = yield this.get('makeSmallGroupOfferingObjects').perform(offerings);
      this.set('offeringsToSave', offerings.length); //save offerings in sets of 5

      let parts;

      while (offerings.length > 0) {
        parts = offerings.splice(0, 5);
        yield Ember.RSVP.map(parts, ({
          startDate,
          endDate,
          room,
          learnerGroups,
          instructorGroups,
          instructors
        }) => {
          return this.get('save')(startDate, endDate, room, learnerGroups, instructorGroups, instructors);
        });
        this.set('savedOfferings', this.get('savedOfferings') + parts.length);
      }

      this.send('clearErrorDisplay');
      this.get('close')();
    }),
    validateThenSaveOffering: (0, _emberConcurrency.task)(function* () {
      this.send('addErrorDisplaysFor', ['room', 'numberOfWeeks', 'durationHours', 'durationMinutes', 'learnerGroups']);
      let {
        validations
      } = yield this.validate();

      if (validations.get('isInvalid')) {
        return;
      }

      yield this.get('saveOffering').perform();
    }),
    updateDurationHours: (0, _emberConcurrency.task)(function* (hours) {
      yield (0, _emberConcurrency.timeout)(DEBOUNCE_DELAY);
      this.send('addErrorDisplayFor', 'durationHours');
      const minutes = this.durationMinutes;
      const endDate = (0, _moment.default)(this.startDate).clone().add(hours, 'hours').add(minutes, 'minutes').toDate();
      this.set('endDate', endDate);
    }).restartable(),
    updateDurationMinutes: (0, _emberConcurrency.task)(function* (minutes) {
      yield (0, _emberConcurrency.timeout)(DEBOUNCE_DELAY);
      this.send('addErrorDisplayFor', 'durationMinutes');
      const hours = this.durationHours;
      const endDate = (0, _moment.default)(this.startDate).clone().add(hours, 'hours').add(minutes, 'minutes').toDate();
      this.set('endDate', endDate);
    }).restartable()
  });

  _exports.default = _default;
});