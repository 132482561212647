define('ember-cli-server-variables/services/server-variables', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    unknownProperty: function unknownProperty(serverVar) {
      var ENV = this.get('env');
      var prefix = ENV.serverVariables.tagPrefix || ENV.modulePrefix;
      var dasherizedVar = Ember.String.dasherize(serverVar);

      // ensure we don't die in fastboot by checking if document exists
      var tag = document ? document.querySelector(`head meta[name=${prefix}-${dasherizedVar}]`) : null;
      var content = tag ? tag.content : null;

      if (!Ember.isBlank(content)) {
        try {
          return JSON.parse(content);
        } catch (e) {
          // content was not JSON
          return content;
        }
      }
    }
  });
});