define("ilios-common/models/curriculum-inventory-report", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo,
    hasMany,
    Model
  } = _emberData.default;
  const {
    all
  } = Ember.RSVP;

  var _default = Model.extend({
    name: attr('string'),
    description: attr('string'),
    year: attr('string'),
    startDate: attr('date'),
    endDate: attr('date'),
    absoluteFileUri: attr('string'),
    export: belongsTo('curriculum-inventory-export', {
      async: true
    }),
    sequence: belongsTo('curriculum-inventory-sequence', {
      async: true
    }),
    sequenceBlocks: hasMany('curriculum-inventory-sequence-block', {
      async: true
    }),
    program: belongsTo('program', {
      async: true
    }),
    academicLevels: hasMany('curriculum-inventory-academic-level', {
      async: true
    }),
    administrators: hasMany('user', {
      async: true,
      inverse: 'administeredCurriculumInventoryReports'
    }),

    /**
     * A list of top-level sequence blocks owned by this report.
     * Returns a promise that resolves to an array of sequence block objects.
     * @property topLevelSequenceBlocks
     * @type {Ember.computed}
     * @public
     */
    topLevelSequenceBlocks: Ember.computed('sequenceBlocks.[]', async function () {
      const sequenceBlocks = await this.get('sequenceBlocks');
      return sequenceBlocks.filter(block => {
        return !block.belongsTo('parent').id();
      });
    }),

    /**
     * Whether this report has been finalized, or not. Returns a boolean.
     * @property isFinalized
     * @type {Ember.computed}
     * @public
     */
    isFinalized: Ember.computed('export', function () {
      return !!this.belongsTo('export').id();
    }),

    /**
     * A label corresponding to this report's academic year. Returns a string.
     * @property yearLabel
     * @type {Ember.computed}
     * @public
     */
    yearLabel: Ember.computed('year', function () {
      const year = this.get('year');
      return year + ' - ' + (parseInt(year, 10) + 1);
    }),

    /**
     * A list of courses that area linked to sequence blocks in this report.
     * Returns a promise that resolves to an array of course objects.
     * @property linkedCourses
     * @type {Ember.computed}
     * @public
     */
    linkedCourses: Ember.computed('sequenceBlocks.@each.course', async function () {
      const sequenceBlocks = await this.get('sequenceBlocks');
      const courses = await all(sequenceBlocks.toArray().mapBy('course'));
      return courses.filter(course => {
        return !Ember.isEmpty(course);
      });
    }),

    /**
     * Whether this report has any courses linked to it via its sequence blocks, or not. Returns a boolean.
     * @property hasLinkedCourses
     * @type {Ember.computed}
     * @public
     */
    hasLinkedCourses: Ember.computed('linkedCourses.[]', async function () {
      const linkedCourses = await this.get('linkedCourses');
      return !Ember.isEmpty(linkedCourses);
    })
  });

  _exports.default = _default;
});