define("ilios-common/mixins/tooltip-content", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    tooltipContent: Ember.computed('event', function () {
      const intl = this.get('intl');

      if (this.get('event') == null) {
        return '';
      }

      let addLocationToContents = function addLocationToContents(contents, eventLocation) {
        if (!Ember.isBlank(eventLocation)) {
          contents = contents + "".concat(eventLocation, "<br />");
        }

        return contents;
      };

      let addInstructorsToContents = function addInstructorsToContents(contents, instructors, etAlPhrase) {
        if (!instructors.length) {
          return contents;
        }

        if (3 > instructors.length) {
          contents = contents + '<br /> ' + intl.t('general.taughtBy', {
            instructors: instructors.join(', ')
          });
        } else {
          contents = contents + '<br /> ' + intl.t('general.taughtBy', {
            instructors: instructors.slice(0, 2).join(', ')
          }) + " ".concat(etAlPhrase);
        }

        return contents;
      };

      let addCourseTitleToContents = function addCourseTitleToContents(contents, courseTitle, courseTitlePhrase) {
        if (courseTitle) {
          contents = contents + "<br />".concat(courseTitlePhrase, ": ").concat(courseTitle);
        }

        return contents;
      };

      const eventLocation = this.get('event.location') || '';
      const name = this.get('event.name');
      const startTime = (0, _moment.default)(this.get('event.startDate')).format(this.get('timeFormat'));
      const endTime = (0, _moment.default)(this.get('event.endDate')).format(this.get('timeFormat'));
      const dueThisDay = intl.t('general.dueThisDay');
      const instructors = this.get('event.instructors') || [];
      const courseTitle = this.get('event.courseTitle');
      const isMulti = this.get('event.isMulti');
      const multiplePhrase = intl.t('general.multiple');
      const courseTitlePhrase = intl.t('general.course');
      const etAlPhrase = intl.t('general.etAl');
      let contents = '';

      if (this.get('isIlm')) {
        if (!isMulti) {
          contents = addLocationToContents(contents, eventLocation);
        }

        contents = contents + "ILM - ".concat(dueThisDay, "<br />").concat(name);

        if (!isMulti) {
          contents = addInstructorsToContents(contents, instructors, etAlPhrase);
        }

        contents = addCourseTitleToContents(contents, courseTitle, courseTitlePhrase);

        if (isMulti) {
          contents = contents + "<br />, ".concat(multiplePhrase);
        }
      } else if (this.get('isOffering')) {
        if (!isMulti) {
          contents = addLocationToContents(contents, eventLocation);
        }

        contents = contents + "".concat(startTime, " - ").concat(endTime, "<br />").concat(name);

        if (!isMulti) {
          contents = addInstructorsToContents(contents, instructors, etAlPhrase);
        }

        contents = addCourseTitleToContents(contents, courseTitle, courseTitlePhrase);

        if (isMulti) {
          contents = contents + "<br />, ".concat(multiplePhrase);
        }
      } else {
        // 'TBD' event
        contents = "TBD<br />".concat(startTime, " - ").concat(endTime, "<br />").concat(name);
      }

      return contents;
    })
  });

  _exports.default = _default;
});