define("ilios-common/templates/components/collapsed-competencies", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LcWroNqX",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"div\",false],[12,\"class\",\"title\"],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],[24,[\"expand\"]]],null]]],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"general.competencies\"],null],false],[0,\" (\"],[1,[28,\"get\",[[28,\"await\",[[24,[\"subject\",\"competencies\"]]],null],\"length\"],null],false],[0,\")\\n\"],[9],[0,\"\\n\"],[4,\"unless\",[[28,\"is-fulfilled\",[[24,[\"summary\"]]],null]],null,{\"statements\":[[0,\"  \"],[5,\"loading-spinner\",[],[[\"@tagName\"],[\"h3\"]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n    \"],[7,\"table\",true],[8],[0,\"\\n      \"],[7,\"thead\",true],[8],[0,\"\\n        \"],[7,\"tr\",true],[8],[0,\"\\n          \"],[7,\"th\",true],[10,\"class\",\"text-left\"],[8],[0,\"\\n            \"],[1,[28,\"t\",[\"general.school\"],null],false],[0,\"\\n          \"],[9],[0,\"\\n          \"],[7,\"th\",true],[10,\"class\",\"text-center\"],[8],[0,\"\\n            \"],[1,[28,\"t\",[\"general.competencies\"],null],false],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[28,\"await\",[[24,[\"summary\"]]],null]],null,{\"statements\":[[0,\"          \"],[7,\"tr\",true],[8],[0,\"\\n            \"],[7,\"td\",true],[8],[0,\"\\n              \"],[1,[23,1,[\"school\",\"title\"]],false],[0,\"\\n            \"],[9],[0,\"\\n            \"],[7,\"td\",true],[10,\"class\",\"text-center\"],[8],[0,\"\\n              \"],[1,[23,1,[\"competencies\",\"length\"]],false],[0,\"\\n            \"],[9],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/collapsed-competencies.hbs"
    }
  });

  _exports.default = _default;
});