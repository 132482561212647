define("ilios-common/components/sessions-grid-header", ["exports", "ember-concurrency", "ilios-common/templates/components/sessions-grid-header"], function (_exports, _emberConcurrency, _sessionsGridHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _sessionsGridHeader.default,
    classNames: ['sessions-grid-header'],
    isExpanding: false,
    'data-test-sessions-grid-header': true,
    sortedAscending: Ember.computed('sortBy', function () {
      const sortBy = this.get('sortBy');
      return sortBy.search(/desc/) === -1;
    }),
    actions: {
      setSortBy(what) {
        const sortBy = this.get('sortBy');

        if (sortBy === what) {
          what += ':desc';
        }

        this.setSortBy(what);
      }

    },
    expandAll: (0, _emberConcurrency.task)(function* () {
      this.set('isExpanding', true);
      yield (0, _emberConcurrency.timeout)(100);
      this.toggleExpandAll(); // we need to wait for the browser to hand back
      //control and then swap the icon back

      yield Ember.run.next(() => {
        this.set('isExpanding', false);
      });
    }).drop()
  });

  _exports.default = _default;
});