define("ilios-common/components/toggle-icons", ["exports", "ilios-common/templates/components/toggle-icons"], function (_exports, _toggleIcons) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _toggleIcons.default,
    classNames: ['toggle-icons'],
    firstOptionSelected: true,
    firstLabel: null,
    secondLabel: null,
    firstIcon: null,
    secondIcon: null,
    actions: {
      firstChoice() {
        const firstOptionSelected = this.get('firstOptionSelected');
        const toggle = this.get('toggle');

        if (!firstOptionSelected) {
          toggle(true);
        }
      },

      secondChoice() {
        const firstOptionSelected = this.get('firstOptionSelected');
        const toggle = this.get('toggle');

        if (firstOptionSelected) {
          toggle(false);
        }
      }

    }
  });

  _exports.default = _default;
});