define("ilios-common/templates/components/publication-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OzinyV/6",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showIcon\"]]],null,{\"statements\":[[0,\"  \"],[5,\"fa-icon\",[],[[\"@icon\",\"@title\"],[[22,\"iconKey\"],[28,\"t\",[[24,[\"textKey\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"showText\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[8],[0,\"\\n    \"],[1,[28,\"t\",[[24,[\"textKey\"]]],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/publication-status.hbs"
    }
  });

  _exports.default = _default;
});