define("ilios-common/models/curriculum-inventory-academic-level", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo,
    hasMany,
    Model
  } = _emberData.default;

  var _default = Model.extend({
    name: attr('string'),
    description: attr('string'),
    level: attr('number'),
    report: belongsTo('curriculum-inventory-report', {
      async: true
    }),
    sequenceBlocks: hasMany('curriculum-inventory-sequence-block', {
      async: true
    })
  });

  _exports.default = _default;
});