define("ilios-common/components/learningmaterial-search", ["exports", "ember-concurrency", "ilios-common/templates/components/learningmaterial-search"], function (_exports, _emberConcurrency, _learningmaterialSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    layout: _learningmaterialSearch.default,
    classNames: ['learningmaterial-search'],
    currentMaterials: null,
    query: '',
    searchResults: null,
    searchPage: 0,
    searchResultsPerPage: 50,
    hasMoreSearchResults: false,
    targetItemTitle: '',
    searching: false,
    searchReturned: false,
    'data-test-learningmaterial-search': true,

    init() {
      this._super(...arguments);

      this.set('searchResults', []);
    },

    actions: {
      search(query) {
        if (query.trim() === '') {
          this.set('searchReturned', false);
          this.set('searching', false);
          this.set('searchPage', 1);
          this.set('hasMoreSearchResults', false);
          this.set('searchResults', []);
          return;
        }

        this.set('searchReturned', false);
        this.set('searching', true);
        this.set('query', query);
        this.get('store').query('learningMaterial', {
          q: query,
          limit: this.get('searchResultsPerPage') + 1,
          'order_by[title]': 'ASC'
        }).then(results => {
          let lms = results.map(lm => {
            return lm;
          });
          this.set('searchReturned', true);
          this.set('searching', false);
          this.set('searchPage', 1);
          this.set('hasMoreSearchResults', lms.length > this.get('searchResultsPerPage'));

          if (this.get('hasMoreSearchResults')) {
            lms.pop();
          }

          this.set('searchResults', lms);
        });
      },

      clear() {
        this.set('searchResults', []);
        this.set('searchReturned', false);
        this.set('searching', false);
        this.set('searchPage', 0);
        this.set('hasMoreSearchResults', false);
        this.set('query', '');
      }

    },
    searchMore: (0, _emberConcurrency.task)(function* () {
      const query = this.get('query');
      const results = yield this.get('store').query('learningMaterial', {
        q: query,
        limit: this.get('searchResultsPerPage') + 1,
        offset: this.get('searchPage') * this.get('searchResultsPerPage'),
        'order_by[title]': 'ASC'
      });
      let lms = results.map(lm => {
        return lm;
      });
      this.set('searchPage', this.get('searchPage') + 1);
      this.set('hasMoreSearchResults', lms.length > this.get('searchResultsPerPage'));

      if (this.get('hasMoreSearchResults')) {
        lms.pop();
      }

      this.get('searchResults').pushObjects(lms);
    }).drop(),
    addLearningMaterial: (0, _emberConcurrency.task)(function* (lm) {
      yield this.add(lm);
    }).enqueue()
  });

  _exports.default = _default;
});