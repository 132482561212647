define("ilios-common/components/collapsed-taxonomies", ["exports", "ilios-common/templates/components/collapsed-taxonomies"], function (_exports, _collapsedTaxonomies) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _collapsedTaxonomies.default,
    tagName: 'section',
    classNames: ['collapsed-taxonomies'],
    subject: null,
    'data-test-collapsed-taxonomies': true
  });

  _exports.default = _default;
});