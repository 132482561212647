define("ilios-common/components/ilios-calendar-multiday-event", ["exports", "ilios-common/templates/components/ilios-calendar-multiday-event"], function (_exports, _iliosCalendarMultidayEvent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _iliosCalendarMultidayEvent.default,
    tagName: 'li',
    event: null,
    isEventSelectable: true,
    isIlm: Ember.computed.notEmpty('event.ilmSession'),
    isOffering: Ember.computed.notEmpty('event.offering'),
    clickable: Ember.computed.or('isIlm', 'isOffering'),
    actions: {
      selectEvent(selectedEvent) {
        const clickable = this.get('clickable');
        const isEventSelectable = this.get('isEventSelectable');
        const selectEvent = this.get('selectEvent');

        if (clickable && isEventSelectable) {
          selectEvent(selectedEvent);
        }
      }

    }
  });

  _exports.default = _default;
});