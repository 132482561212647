define("ilios-common/components/ics-feed", ["exports", "ilios-common/templates/components/ics-feed", "ember-concurrency"], function (_exports, _icsFeed, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _icsFeed.default,
    classNames: ['ilios-calendar-ics-feed'],
    url: null,
    instructions: null,
    showCopySuccessMessage: false,
    actions: {
      refresh() {
        this.refresh();
      }

    },
    textCopied: (0, _emberConcurrency.task)(function* () {
      this.set('showCopySuccessMessage', true);
      yield (0, _emberConcurrency.timeout)(3000);
      this.set('showCopySuccessMessage', false);
    }).restartable()
  });

  _exports.default = _default;
});