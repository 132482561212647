define("elemental-calendar/models/week", ["exports", "moment", "elemental-calendar/models/month", "elemental-calendar/models/day", "elemental-calendar/models/event-list"], function (_exports, _moment, _month, _day, _eventList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let Week = Ember.Object.extend({
    date: null,
    events: null,
    eventList: null,
    _momentDate: Ember.computed('date', function () {
      return (0, _moment.default)(this.date);
    }),
    _startOfWeekMoment: Ember.computed('_momentDate', function () {
      return (0, _moment.default)(this.get('_momentDate')).startOf('week');
    }),
    days: Ember.computed('_momentDate', function () {
      let days = Ember.A();
      let startOfWeekMoment = this.get('_startOfWeekMoment');

      for (let i = 0; i < 7; i++) {
        let date = (0, _moment.default)(startOfWeekMoment).toDate();

        let day = _day.default.create({
          date: date,
          eventList: this.eventList
        });

        startOfWeekMoment.add(1, 'days');
        days.pushObject(day);
      }

      return days;
    }),
    startOfWeekMonthNumber: Ember.computed('_momentDate', function () {
      let startOfWeekMoment = this.get('_startOfWeekMoment');
      return startOfWeekMoment.format('M');
    }),

    init() {
      this._super(...arguments);

      this.eventList = this.eventList || _eventList.default.create({
        events: this.events
      });
    },

    previous() {
      let previousWeek = (0, _moment.default)(this.get('_momentDate')).subtract(1, 'weeks');
      return Week.create({
        date: previousWeek.toDate(),
        eventList: this.eventList
      });
    },

    next() {
      let nextWeek = (0, _moment.default)(this.get('_momentDate')).add(1, 'weeks');
      return Week.create({
        date: nextWeek.toDate(),
        eventList: this.eventList
      });
    },

    month(events) {
      return _month.default.create({
        date: this.date,
        events: events
      });
    }

  });
  var _default = Week;
  _exports.default = _default;
});