define("ilios-common/templates/components/big-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1hRRVVnD",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[11,\"class\",[29,[\"clickable \",[28,\"if\",[[24,[\"renderHtml\"]],\"list-reset\"],null],\" editable\"]]],[10,\"role\",\"button\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[24,[\"onEdit\"]]],null]],[8],[0,\"\\n  \"],[1,[22,\"displayText\"],false],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"showIcons\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",false],[12,\"class\",\"clickable\"],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"expanded\"]]],null],true]],[8],[0,\"\\n    \"],[5,\"fa-icon\",[],[[\"@icon\",\"@classNames\"],[[22,\"ellipsis\"],\"text-bottom\"]]],[0,\"\\n    \"],[5,\"fa-icon\",[],[[\"@icon\"],[[22,\"expandIcon\"]]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/big-text.hbs"
    }
  });

  _exports.default = _default;
});