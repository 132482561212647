define("ilios-common/templates/components/ilios-calendar-pre-work-event", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K0t9r17d",
    "block": "{\"symbols\":[\"@event\",\"@areEventsSelectable\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ilios-calendar-pre-work-event\"],[8],[0,\"\\n  \"],[7,\"h4\",true],[8],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"title\"],[8],[0,\"\\n\"],[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"        \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"events\",[23,1,[\"slug\"]]]],{\"statements\":[[0,\"\\n          \"],[1,[23,1,[\"name\"]],false],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[23,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"date\"],[8],[0,\"\\n\"],[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"t\",[\"general.dueBeforeLink\"],[[\"name\",\"date\",\"link\",\"htmlSafe\"],[[23,1,[\"postrequisiteName\"]],[28,\"format-date\",[[23,1,[\"startDate\"]]],null],[24,[\"postrequisiteLink\"]],true]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[28,\"t\",[\"general.dueBefore\"],[[\"name\",\"date\"],[[23,1,[\"postrequisiteName\"]],[28,\"format-date\",[[23,1,[\"startDate\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/ilios-calendar-pre-work-event.hbs"
    }
  });

  _exports.default = _default;
});