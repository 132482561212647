define("ilios-common/components/sessions-grid-offering-table", ["exports", "ilios-common/utils/offering-date-block", "ilios-common/templates/components/sessions-grid-offering-table"], function (_exports, _offeringDateBlock, _sessionsGridOfferingTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _sessionsGridOfferingTable.default,
    tagName: 'table',
    classNames: ['sessions-grid-offering-table'],
    'data-test-sessions-grid-offering-table': true,
    offeringBlocks: Ember.computed('offerings.@each.{startDate,endDate,room,learnerGroups,instructorGroups}', function () {
      let offerings = this.get('offerings');

      if (!offerings) {
        return [];
      }

      let dateBlocks = {};
      offerings.forEach(offering => {
        let key = offering.get('dateKey');

        if (!(key in dateBlocks)) {
          dateBlocks[key] = _offeringDateBlock.default.create({
            dateKey: key
          });
        }

        dateBlocks[key].addOffering(offering);
      }); //convert indexed object to array

      let dateBlockArray = [];
      let key;

      for (key in dateBlocks) {
        dateBlockArray.pushObject(dateBlocks[key]);
      }

      return dateBlockArray.sortBy('dateStamp');
    })
  });

  _exports.default = _default;
});