define("ilios-common/components/objective-manage-competency", ["exports", "ilios-common/templates/components/objective-manage-competency"], function (_exports, _objectiveManageCompetency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _objectiveManageCompetency.default,
    objective: null,
    classNames: ['objective-manager', 'objective-manage-competency'],
    schoolCompetencies: Ember.computed('programYear.program.school.competencies.[]', function () {
      return new Ember.RSVP.Promise(resolve => {
        this.get('programYear').then(programYear => {
          programYear.get('program').then(program => {
            program.get('school').then(school => {
              school.get('competencies').then(competencies => {
                resolve(competencies);
              });
            });
          });
        });
      });
    }),
    programYear: Ember.computed('objective.programYears.[]', function () {
      return new Ember.RSVP.Promise(resolve => {
        const objective = this.get('objective');
        objective.get('programYears').then(programYears => {
          if (programYears.length) {
            let programYear = programYears.get('firstObject');
            resolve(programYear);
          } else {
            resolve(null);
          }
        });
      });
    }),
    competencies: Ember.computed('programYear.competencies.[]', function () {
      return new Ember.RSVP.Promise(resolve => {
        this.get('programYear').then(programYear => {
          programYear.get('competencies').then(competencies => {
            resolve(competencies);
          });
        });
      });
    }),
    competenciesWithSelectedChildren: Ember.computed('schoolCompetencies.[]', 'objective.competency', function () {
      return new Ember.RSVP.Promise(resolve => {
        const objective = this.get('objective');
        objective.get('competency').then(selectedCompetency => {
          if (selectedCompetency) {
            this.get('schoolCompetencies').then(competencies => {
              Ember.RSVP.filter(competencies.toArray(), competency => {
                return new Ember.RSVP.Promise(resolve => {
                  competency.get('treeChildren').then(children => {
                    const selectedCompetencyId = selectedCompetency.get('id');
                    const selectedChildren = children.filterBy('id', selectedCompetencyId);
                    resolve(selectedChildren.length > 0);
                  });
                });
              }).then(competenciesWithSelectedChildren => {
                resolve(competenciesWithSelectedChildren);
              });
            });
          } else {
            resolve([]);
          }
        });
      });
    }),
    domains: Ember.computed('competencies.[]', function () {
      return new Ember.RSVP.Promise(resolve => {
        this.get('competencies').then(competencies => {
          Ember.RSVP.all(competencies.mapBy('domain')).then(domains => {
            resolve(domains.uniq());
          });
        });
      });
    }),
    domainsWithNoChildren: Ember.computed('domains.[]', function () {
      return new Ember.RSVP.Promise(resolve => {
        this.get('competencies').then(competencies => {
          this.get('domains').then(domains => {
            Ember.RSVP.filter(domains.toArray(), domain => {
              return new Ember.RSVP.Promise(resolve => {
                domain.get('children').then(children => {
                  let availableChildren = children.filter(child => competencies.includes(child));
                  resolve(availableChildren.length === 0);
                });
              });
            }).then(domainsWithNoChildren => {
              resolve(domainsWithNoChildren);
            });
          });
        });
      });
    }),
    actions: {
      changeCompetency(competency) {
        this.get('objective').set('competency', competency);
      },

      removeCurrentCompetency() {
        this.get('objective').set('competency', null);
      }

    }
  });

  _exports.default = _default;
});