define("ilios-common/templates/components/detail-competencies", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "op41Cipw",
    "block": "{\"symbols\":[\"domain\",\"competency\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[\"title \",[28,\"if\",[[28,\"await\",[[24,[\"showCollapsible\"]]],null],\"clickable collapsible\"],null]]]],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],\"collapse\"]],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"general.competencies\"],null],false],[0,\" (\"],[1,[28,\"get\",[[28,\"await\",[[24,[\"course\",\"competencies\"]]],null],\"length\"],null],false],[0,\")\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[28,\"get\",[[28,\"await\",[[24,[\"course\",\"competencies\"]]],null],\"length\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"detail-competencies-content\"],[8],[0,\"\\n    \"],[7,\"ul\",true],[10,\"class\",\"static-list\"],[8],[0,\"\\n\"],[4,\"each\",[[28,\"await\",[[24,[\"course\",\"domains\"]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"li\",true],[8],[0,\"\\n          \"],[1,[23,1,[\"title\"]],false],[0,\"\\n          \"],[7,\"ul\",true],[8],[0,\"\\n\"],[4,\"each\",[[28,\"await\",[[23,1,[\"subCompetencies\"]]],null]],null,{\"statements\":[[0,\"              \"],[7,\"li\",true],[8],[0,\"\\n                \"],[1,[23,2,[\"title\"]],false],[0,\"\\n              \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios-common/templates/components/detail-competencies.hbs"
    }
  });

  _exports.default = _default;
});