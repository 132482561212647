define("ilios-common/components/course-loading", ["exports", "ilios-common/templates/components/course-loading"], function (_exports, _courseLoading) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _courseLoading.default,
    classNames: ['course-loading']
  });

  _exports.default = _default;
});