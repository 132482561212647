define("ilios-common/models/cohort", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo,
    hasMany,
    Model
  } = _emberData.default;

  var _default = Model.extend({
    title: attr('string'),
    programYear: belongsTo('program-year', {
      async: true
    }),
    courses: hasMany('course', {
      async: true
    }),
    learnerGroups: hasMany('learner-group', {
      async: true
    }),
    users: hasMany('user', {
      async: true
    }),
    competencies: Ember.computed('programYear.competencies.[]', async function () {
      const programYear = await this.get('programYear');
      return await programYear.get('competencies');
    }),
    objectives: Ember.computed('programYear.objectives.[]', async function () {
      const programYear = await this.get('programYear');
      return await programYear.get('objectives');
    }),

    /**
     * All top-level learner groups associated with this cohort.
     *
     * @property rootLevelLearnerGroups
     * @type {Ember.computed}
     * @public
     */
    rootLevelLearnerGroups: Ember.computed('learnerGroups.[]', async function () {
      let learnerGroups = await this.get('learnerGroups');
      return learnerGroups.filter(learnerGroup => learnerGroup.belongsTo('parent').value() === null);
    }),
    program: Ember.computed('programYear.program', async function () {
      const programYear = await this.get('programYear');
      return await programYear.get('program');
    }),
    school: Ember.computed('program.school', async function () {
      const program = await this.get('program');
      return await program.get('school');
    }),
    sortedObjectives: Ember.computed('programYear.sortedObjectives.[]', async function () {
      const programYear = await this.get('programYear');
      return await programYear.get('sortedObjectives');
    }),
    classOfYear: Ember.computed('programYear.classOfYear', async function () {
      const programYear = await this.get('programYear');
      return await programYear.get('classOfYear');
    })
  });

  _exports.default = _default;
});