define("ilios-common/components/publish-all-sessions", ["exports", "ilios-common/templates/components/publish-all-sessions"], function (_exports, _publishAllSessions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    layout: _publishAllSessions.default,
    isSaving: false,
    classNames: ['publish-all-sessions'],
    sessions: null,
    sessionsToOverride: null,
    publishableCollapsed: true,
    unPublishableCollapsed: true,
    totalSessionsToSave: null,
    currentSessionsSaved: null,
    noSessionsAsIs: Ember.computed.equal('sessionsToOverride.length', 0),

    /**
     * @property allSessionsAsIs
     * @type {Ember.computed}
     * @public
     */
    allSessionsAsIs: Ember.computed('sessionsToOverride.[]', 'overridableSessions.[]', async function () {
      const overridableSessions = await this.get('overridableSessions');
      return this.get('sessionsToOverride').get('length') === overridableSessions.length;
    }),

    /**
     * @property publishableSessions
     * @type {Ember.computed}
     * @public
     */
    publishableSessions: Ember.computed('sessions.@each.allPublicationIssuesLength', async function () {
      const sessions = await this.get('sessions');
      return sessions.filter(session => {
        return session.get('allPublicationIssuesLength') === 0;
      });
    }),

    /**
     * @property unPublishableSessions
     * @type {Ember.computed}
     * @public
     */
    unPublishableSessions: Ember.computed('sessions.@each.requiredPublicationIssues', async function () {
      const sessions = await this.get('sessions');
      return sessions.filter(session => {
        return session.get('requiredPublicationIssues').get('length') > 0;
      });
    }),

    /**
     * @property overridableSessions
     * @type {Ember.computed}
     * @public
     */
    overridableSessions: Ember.computed('sessions.@each.{requiredPublicationIssues,optionalPublicationIssues}', function () {
      return new Ember.RSVP.Promise(resolve => {
        this.get('sessions').then(sessions => {
          let filteredSessions = sessions.filter(session => {
            return session.get('requiredPublicationIssues').get('length') === 0 && session.get('optionalPublicationIssues').get('length') > 0;
          });
          resolve(filteredSessions);
        });
      });
    }),

    /**
     * @property publishCount
     * @type {Ember.computed}
     * @public
     */
    publishCount: Ember.computed('publishableSessions.[]', 'sessionsToOverride.length', function () {
      return new Ember.RSVP.Promise(resolve => {
        this.get('publishableSessions').then(publishableSessions => {
          resolve(publishableSessions.length + parseInt(this.get('sessionsToOverride.length'), 10));
        });
      });
    }),

    /**
     * @property scheduleCount
     * @type {Ember.computed}
     * @public
     */
    scheduleCount: Ember.computed('overridableSessions.[]', 'sessionsToOverride.length', function () {
      return new Ember.RSVP.Promise(resolve => {
        this.get('overridableSessions').then(overridableSessions => {
          resolve(overridableSessions.length - parseInt(this.get('sessionsToOverride.length'), 10));
        });
      });
    }),

    /**
     * @property ignoreCount
     * @type {Ember.computed}
     * @public
     */
    ignoreCount: Ember.computed('unPublishableSessions.[]', function () {
      return new Ember.RSVP.Promise(resolve => {
        this.get('unPublishableSessions').then(unPublishableSessions => {
          resolve(unPublishableSessions.length);
        });
      });
    }),
    course: Ember.computed('sessions', async function () {
      return await this.sessions.firstObject.course;
    }),
    showWarning: Ember.computed('course', async function () {
      const course = await this.course;
      const objectives = await course.objectives;
      return objectives.any(objective => Ember.isEmpty(objective.parents));
    }),

    init() {
      this._super(...arguments);

      this.set('sessionsToOverride', []);
    },

    actions: {
      toggleSession(session) {
        if (this.get('sessionsToOverride').includes(session)) {
          this.get('sessionsToOverride').removeObject(session);
        } else {
          this.get('sessionsToOverride').pushObject(session);
        }
      },

      publishAllAsIs() {
        this.get('overridableSessions').then(overridableSessions => {
          overridableSessions.forEach(session => {
            if (!this.get('sessionsToOverride').includes(session)) {
              this.get('sessionsToOverride').pushObject(session);
            }
          });
        });
      },

      publishNoneAsIs() {
        this.get('overridableSessions').then(overridableSessions => {
          overridableSessions.forEach(session => {
            if (this.get('sessionsToOverride').includes(session)) {
              this.get('sessionsToOverride').removeObject(session);
            }
          });
        });
      },

      save() {
        this.set('isSaving', true);
        let asIsSessions = this.get('sessionsToOverride');
        let sessionsToSave = [];
        let promises = [];
        promises.pushObject(new Ember.RSVP.Promise(resolve => {
          this.get('overridableSessions').then(overridableSessions => {
            overridableSessions.forEach(session => {
              session.set('publishedAsTbd', !asIsSessions.includes(session));
              session.set('published', true);
              sessionsToSave.pushObject(session);
            });
            resolve();
          });
        }));
        promises.pushObject(new Ember.RSVP.Promise(resolve => {
          this.get('publishableSessions').then(publishableSessions => {
            publishableSessions.forEach(session => {
              session.set('published', true);
              sessionsToSave.pushObject(session);
            });
            resolve();
          });
        }));
        Ember.RSVP.all(promises).then(() => {
          this.set('totalSessionsToSave', sessionsToSave.length);
          this.set('currentSessionsSaved', 0);

          let saveSomeSessions = sessions => {
            let chunk = sessions.splice(0, 6);
            Ember.RSVP.all(chunk.invoke('save')).then(() => {
              if (sessions.length) {
                this.set('currentSessionsSaved', this.get('currentSessionsSaved') + chunk.length);
                saveSomeSessions(sessions);
              } else {
                this.set('isSaving', false);
                this.saved();
                this.get('flashMessages').success('general.savedSuccessfully');
              }
            });
          };

          saveSomeSessions(sessionsToSave);
        });
      },

      togglePublishableCollapsed() {
        this.set('publishableCollapsed', !this.get('publishableCollapsed'));
      },

      toggleUnPublishableCollapsed() {
        this.set('unPublishableCollapsed', !this.get('unPublishableCollapsed'));
      },

      async transitionToCourse() {
        const course = await this.course;
        const queryParams = {
          courseObjectiveDetails: true,
          details: true
        };
        this.router.transitionTo('course', course, {
          queryParams
        });
      },

      async transitionToVisualizeObjectives() {
        const course = await this.course;
        this.router.transitionTo('course-visualize-objectives', course);
      },

      transitionToSession(session) {
        const queryParams = {
          sessionObjectiveDetails: true
        };
        this.router.transitionTo('session', session, {
          queryParams
        });
      }

    }
  });

  _exports.default = _default;
});