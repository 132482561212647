define("ilios-common/components/leadership-search", ["exports", "ilios-common/templates/components/leadership-search", "ember-concurrency", "ilios-common/utils/query-utils"], function (_exports, _leadershipSearch, _emberConcurrency, _queryUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    mapBy
  } = Ember.computed;
  const DEBOUNCE_MS = 250;
  const MIN_INPUT = 3;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    layout: _leadershipSearch.default,
    classNames: ['leadership-search'],
    existingUsers: null,
    searchValue: null,
    'data-test-leadership-search': true,
    existingUserIds: mapBy('existingUsers', 'id'),
    searchForUsers: (0, _emberConcurrency.task)(function* (query) {
      const intl = this.get('intl');
      const store = this.get('store');
      this.set('searchValue', query);
      let q = (0, _queryUtils.cleanQuery)(query);

      if (Ember.isBlank(q)) {
        yield (0, _emberConcurrency.timeout)(1);
        return [];
      }

      if (q.length < MIN_INPUT) {
        return [{
          type: 'text',
          text: intl.t('general.moreInputRequiredPrompt')
        }];
      }

      yield (0, _emberConcurrency.timeout)(DEBOUNCE_MS);
      let searchResults = yield store.query('user', {
        q,
        'order_by[lastName]': 'ASC',
        'order_by[firstName]': 'ASC',
        limit: 100
      });

      if (searchResults.length === 0) {
        return [{
          type: 'text',
          text: intl.t('general.noSearchResultsPrompt')
        }];
      }

      let mappedResults = searchResults.map(user => {
        return {
          type: 'user',
          user
        };
      });
      let results = [{
        type: 'summary',
        text: intl.t('general.resultsCount', {
          count: mappedResults.length
        })
      }];
      results.pushObjects(mappedResults);
      return results;
    }).restartable(),
    clickUser: (0, _emberConcurrency.task)(function* (user) {
      const existingUserIds = this.get('existingUserIds');

      if (existingUserIds.includes(user.get('id'))) {
        return;
      }

      this.set('searchValue', null);
      yield this.get('searchForUsers').perform(null);
      this.get('selectUser')(user);
    }).drop()
  });

  _exports.default = _default;
});