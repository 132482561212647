define("ilios-common/mixins/publishable-model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    alias,
    oneWay,
    not
  } = Ember.computed;
  const {
    attr
  } = _emberData.default;

  var _default = Ember.Mixin.create({
    publishedAsTbd: attr('boolean'),
    published: attr('boolean'),
    isPublished: alias('published'),
    isNotPublished: not('isPublished'),
    isScheduled: oneWay('publishedAsTbd'),
    isPublishedOrScheduled: Ember.computed('publishTarget.isPublished', 'publishTarget.isScheduled', function () {
      return this.get('publishedAsTbd') || this.get('isPublished');
    }),
    allPublicationIssuesCollection: Ember.computed.collect('requiredPublicationIssues.length', 'optionalPublicationIssues.length'),
    allPublicationIssuesLength: Ember.computed.sum('allPublicationIssuesCollection'),
    requiredPublicationSetFields: [],
    requiredPublicationLengthFields: [],
    optionalPublicationSetFields: [],
    optionalPublicationLengthFields: [],

    getRequiredPublicationIssues() {
      var issues = [];
      this.get('requiredPublicationSetFields').forEach(val => {
        if (!this.get(val)) {
          issues.push(val);
        }
      });
      this.get('requiredPublicationLengthFields').forEach(val => {
        if (this.get(val + '.length') === 0) {
          issues.push(val);
        }
      });
      return issues;
    },

    getOptionalPublicationIssues() {
      var issues = [];
      this.get('optionalPublicationSetFields').forEach(val => {
        if (!this.get(val)) {
          issues.push(val);
        }
      });
      this.get('optionalPublicationLengthFields').forEach(val => {
        if (this.get(val + '.length') === 0) {
          issues.push(val);
        }
      });
      return issues;
    }

  });

  _exports.default = _default;
});