define("ilios-common/components/search-box", ["exports", "ember-concurrency", "ilios-common/templates/components/search-box"], function (_exports, _emberConcurrency, _searchBox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEBOUNCE_TIMEOUT = 250;

  var _default = Ember.Component.extend({
    layout: _searchBox.default,
    classNames: ['search-box'],
    value: '',
    liveSearch: true,
    'data-test-search-box': true,
    actions: {
      update(value) {
        const liveSearch = this.get('liveSearch');
        this.set('value', value);

        if (liveSearch) {
          this.get('searchTask').perform();
        }
      },

      clear() {
        this.set('value', '');
        const clear = this.get('clear');

        if (Ember.isPresent(clear)) {
          clear();
        }
      },

      focus() {
        //place focus into the search box when search icon is clicked
        this.element.querySelector('input[type="search"]').focus();
      }

    },
    searchTask: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(DEBOUNCE_TIMEOUT);
      const value = this.get('value');
      yield this.get('search')(value);
    }).restartable(),

    keyUp(event) {
      const keyCode = event.keyCode;
      const target = event.target;

      if ('search' !== target.type) {
        return;
      }

      if (13 === keyCode) {
        this.get('searchTask').perform();
        return;
      }

      if (27 === keyCode) {
        if (this.clear) {
          this.clear();
        }
      }
    }

  });

  _exports.default = _default;
});