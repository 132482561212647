define("ilios-common/mixins/validation-error-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    didReceiveAttrs() {
      this._super(...arguments);

      this.set('showErrorsFor', []);
    },

    showErrorsFor: [],
    actions: {
      addErrorDisplayFor(field) {
        this.get('showErrorsFor').pushObject(field);
      },

      addErrorDisplaysFor(fields) {
        this.get('showErrorsFor').pushObjects(fields);
      },

      removeErrorDisplayFor(field) {
        this.get('showErrorsFor').removeObject(field);
      },

      clearErrorDisplay() {
        this.set('showErrorsFor', []);
      }

    }
  });

  _exports.default = _default;
});